import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`;
export const Container = styled.div`
    /* padding: 0px 6%;
    @media screen and (min-width: 880px) {
        padding: 0px 4%;
    } */
    width: 100%;
`;
export const StatsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    flex-direction: column;

    @media screen and (min-width: 880px) {
        flex-direction: row;
        /* gap: 4%; */
    }
`;

export const MainRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (min-width: 1080px) {
        flex-direction: row;
    }
`;

export const SitesWrapper = styled.div`
    flex: 1;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    margin: 20px 0px;
    padding: 20px 20px;
    border-radius: 4px;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        h5 {
            color: ${props => props.theme.textColor};
            font-size: 14px;
            font-weight: 600;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.darkTextColor};
            border: none;
            width: 90px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.whiteTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.whiteTextColor};
                font-size: 15px;
            }
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;

export const SideBar = styled.div`
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    margin: 20px 0px;
    padding: 20px 20px;
    border-radius: 4px;
    min-height: 400px;
    width: 100%;
    
    @media screen and (min-width: 1080px) {
        width: 30%;
        min-width: 320px;
    }
`;

export const SiteBuilds = styled.div<{status: string}>`
    display: flex;
    align-items: center;
    padding: 15px 0px;
    gap: 20px;
    cursor: pointer;

    .img-wrapper {
        /* width: 120px; */
        /* height: 60px; */
        /* background-color: #ccc; */

        svg {
            width: 50px;
            height: 50px;
            color: ${props => props.theme.darkTextColor};
        }
    }
    .col {
        flex: 1;
        h4 {
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 4px;
            color: ${props => props.theme.darkTextColor};
            text-transform: capitalize;
        }
        span {
            columns: ${props => props.theme.textColor};
            font-size: 12px;
            font-weight: 400;
            color: ${props => props.theme.darkTextColor};
            font-style: italic;
        }
    }

    .meta {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap:  10px;
        align-self: flex-start;
        span {
            display: flex;
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 4px;
            background-color: ${props => props.status == "completed" ? "rgb(194, 253, 194)" : props.status == "failed" ? "rgb(245, 187, 187)" : props.status == "queued" ? props.theme.lightPrimaryColor : "rgb(255, 228, 182)"};
            color: ${props => props.status == "completed" ? "#090" : props.status == "failed" ? "#900" : props.status == "queued" ? props.theme.primaryColor : "#ff6633"};
            border: 0.5px solid ${props => props.status == "completed" ? "#090" : props.status == "failed" ? "#900" : props.status == "queued" ? props.theme.primaryColor : "#ff6633"};
            
        }
    }
`;

export const AddSiteModal = styled.div`
    
`;



export const EmptyContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
    gap: 40px;
    /* background-color: #ceceec66; */
    border-radius: 8px;

    button {
        background-color: ${props => props.theme.primaryButtonColor};
        color: ${props => props.theme.primaryButtonTextColor};
        border: none;
        border-radius: 4px;
        height: 45px;
        width: 150px;
        text-transform: capitalize;
        cursor: pointer;
    }

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 8px;

        svg {
            width: 30px;
            height: 30px;
            color: ${props => props.theme.darkTextColor};
        }

        span {
            color: ${props => props.theme.darkTextColor};
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }

        p {
            color: ${props => props.theme.darkTextColor};
            font-size: 16px;
            font-weight: 400;
            text-align: center;
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;

export const LogModal = styled.div`
    background-color: #ceceec66;
    padding: 20px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 23px;
    white-space: nowrap;
    overflow: auto;
`;