import { Container, EmptyContent, LogModal, MainRow, SideBar, SiteBuilds, SitesWrapper, Wrapper } from './styles'
import { AiOutlineDeploymentUnit } from 'react-icons/ai'
import Loader from '../../../components/Loader/Loader'
import { useEffect, useState } from 'react'
import AxiosCall from '../../../../utils/axios'
import Message from '../../../components/message/Message'
import dayjs from 'dayjs'
import PopupModal from '../../../components/popupModal/PopupModal'

const BuildsScreen: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [builds, setBuilds] = useState<any[]>([])
    const [showLogModal, setShowLogModal] = useState(false)
    const [selectedBuild, setSelectedBuild] = useState<any>(null)

    const getBuilds = async () => {
        try {
            setIsLoading(true)
            
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/apps/builds/fetch"
            });

            setIsLoading(false)
            if (res.status == 1) {
                setBuilds(res.data);
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    useEffect(() => {
        getBuilds()
    }, [])

    return (
        <Wrapper>
            <Container>
                <MainRow>
                    <SitesWrapper>
                        <div className="head">
                            <h5>Recent builds</h5>
                        </div>

                        <div className="site-list">
                            {isLoading ? <Loader styleTwo center topPadding="40px" /> : !builds.length ? <EmptyContent>
                                <div className="text-content">
                                    <AiOutlineDeploymentUnit />
                                    <span>You have no builds yet</span>
                                    <p>Updates to your application appear here and can be tracked using their status.</p>
                                </div>
                            </EmptyContent> : builds.map((item: any, idx) => {
                                return <SiteBuilds status={item.status} key={"apps-"+idx} onClick={() => {setSelectedBuild(item); setShowLogModal(true)}}>
                                    <div className="img-wrapper">
                                        <AiOutlineDeploymentUnit />
                                    </div>
                                    <div className="col">
                                        <h4>{item?.app_slug}</h4>
                                        <span>{dayjs(item?.date_created).format("MMM DD,  YYYY")} &nbsp;  - &nbsp;   {dayjs(item?.date_created).format("hh:ma")}</span>
                                    </div>
                                    <div className="meta">
                                        <span>{item?.status}</span>
                                        {/* <span>Deployed: July 3rd 2023</span> */}
                                    </div>
                                </SiteBuilds>
                                })}
                        </div>
                    </SitesWrapper>

                    <SideBar>
                        
                    </SideBar>
                </MainRow>
            </Container>

            <PopupModal maxWidth="640px" showPopup={showLogModal} closeModal={() => setShowLogModal(false)}>
                <h4>Deploy logs</h4>
                <LogModal dangerouslySetInnerHTML={{__html: selectedBuild?.log}} />
            </PopupModal>
        </Wrapper>
    )
}

export default BuildsScreen