import styled from 'styled-components'

export const Wrapper = styled.div`
    
`;
export const Container = styled.div`
    /* padding-top: 90px; */
`;
export const MainSection = styled.main`
    background-color: ${props => props.theme.containerBackground};
    .sec-title {
        font-size: 25px;
        font-weight: 600;
        color: ${props => props.theme.textPrimaryColor};
        margin-bottom: 5px;
    }
    .sec-sub-title {
        font-size: 14px;
        font-weight: 400;
        color: #5C5C5C;
    }
`;
export const Section = styled.section<{background?: string, boxshadow?: string, borderBottomLeftRadius?: string, borderBottomRightRadius?: string, borderTopLeftRadius?: string, borderTopRightRadius?: string}>`
    background-color: ${props => props.background ? props.theme.sectionBackground : props.theme.sectionBackgroundAlt};
    border-bottom-right-radius: ${props => props.borderBottomRightRadius ? props.borderBottomRightRadius : "0px"};
    border-bottom-left-radius: ${props => props.borderBottomLeftRadius ? props.borderBottomLeftRadius : "0px"};
    border-top-right-radius: ${props => props.borderTopRightRadius ? props.borderTopRightRadius : "0px"};
    border-top-left-radius: ${props => props.borderTopLeftRadius ? props.borderTopLeftRadius : "0px"};
    box-shadow: ${props => props.boxshadow ? props.boxshadow : "none"};
    padding: 0px 4%;

    @media screen and (min-width: 880px) {
        padding: 0px 6%;
    }
`;
export const SectionContainer = styled.div`
    /* max-width: 1280px; */
    margin: 0px auto;
    padding: 100px 20px;
    .sec-row {
        display: flex;
        justify-content: space-between;

        a {
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;
            margin-left: 10px;
        }
        margin-bottom: 20px;
    }
`;

export const EasyDeployWrapper = styled.div`
    padding: 100px 0px;
    .sec-title {
        font-size: 35px;
        margin-bottom: 10px;
        text-align: center;
        color: ${props => props.theme.darkTextColor};
    }
    .sec-sub-title {
        text-align: center;
        margin-bottom: 80px;
    }
`;
export const EasyDeployContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4%;
    width: 100%;

    .img-wrapper {
        width: 100%;
        /* max-width: 320px; */
        margin-bottom: 40px;
        background-image: url("/assets/img/tech-pattern-img.png");
        background-repeat: repeat;
        background: -webkit-linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
        border-radius: 20px;
    }

    .text-content {
        .label {
            display: inline-block;
            background-color: rgb(249, 224, 255);
            font-size: 14px;
            font-weight: 600;
            color: ${props => props.theme.textPrimaryColor};
            padding: 10px 20px;
            border-radius: 4px;
            margin-bottom: 20px;
        }
        h3 {
            color: ${props => props.theme.textPrimaryColor};
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        p {
            color: ${props => props.theme.textColor};
            font-size: 17px;
            font-weight: 400;
            line-height: 28px;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;
            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                padding: 10px 0px;
                span {
                    color: ${props => props.theme.textColor};
                    font-size: 14px;
                    font-weight: 600;
                }
                svg {
                    font-size: 13px;
                    color: ${props => props.theme.textPrimaryColor};
                }
            }
        }

        .action {
            margin-top: 30px;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid ${props => props.theme.primaryColor};
                background-color: ${props => props.theme.primaryButtonColor};
                width: 200px;
                height: 50px;
                border-radius: 4px;
                gap: 10px;

                span {
                    font-size: 13px;
                    font-weight: 400;
                    color: ${props => props.theme.primaryButtonTextColor};
                    text-transform: uppercase;
                }

                svg {
                    font-size: 18px;
                    color: ${props => props.theme.primaryButtonTextColor};
                }
            }
        }
    }
    
    @media screen and (min-width: 880px) {
        flex-direction: row;
        .img-wrapper {
            max-width: 640px;
            margin-bottom: 0px;
        }
        .text-content {
            max-width: 640px;
        }
    }
`;



export const DeveloperProductivity = styled.div`

`;

export const CodeEditorWrapper = styled.div`
    border-radius: 10px;
    overflow: hidden;
`;
export const CodeEditorHeader = styled.div`
    display: flex;
    height: 40px;
    background-color: ${props => props.theme.backgroudColor};
    padding: 6px 10px;

    span {
        display: flex;
        align-items: center;
        padding: 0px 15px;
        height: 100%;
        font-size: 14px;
        font-weight: 500;
        align-items: center;
        color: ${props => props.theme.textColor};
        cursor: pointer;
    }

    .active {
        background-color: ${props => props.theme.containerBackground};
        color: ${props => props.theme.darkTextColor};
        border-radius: 3px;
    }
`;

export const CodeEditorContainer = styled.div`
    /* display: flex;
    align-items: center; */
    background-color: #1e1e1e;

    height: 300px;

    img {
        max-width: unset;
        height: 100%;
    }

    @media screen and (min-width: 880px) { 
        height: 400px;
    }
`;


export const DeveloperProductivityContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: 4%;
    width: 100%;

    .img-wrapper {
        width: 100%;
        margin-bottom: 40px;
        border-radius: 20px;

        @media screen and (min-width: 880px) {
            margin-top: 0px;
        }
    }

    .text-content {
        .label {
            display: inline-block;
            background-color: rgb(249, 224, 255);
            font-size: 14px;
            font-weight: 600;
            color: ${props => props.theme.textPrimaryColor};
            padding: 10px 20px;
            border-radius: 4px;
            margin-bottom: 20px;
        }
        h3 {
            color: ${props => props.theme.textPrimaryColor};
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        p {
            color: ${props => props.theme.textColor};
            font-size: 17px;
            font-weight: 400;
            line-height: 28px;
        }

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            gap: 20px;
            margin-top: 40px;
            li {
                display: flex;
                width: 100%;
                max-width: calc(50% - 10px);
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                padding: 20px 20px;
                background-color: ${props => props.theme.sectionBackground};
                border-radius: 8px;
                box-shadow: 0px 0px 20px -10px ${props => props.theme.boxShadow};

                h4 {
                    color: ${props => props.theme.darkTextColor};
                }

                span {
                    color: ${props => props.theme.textColor};
                    font-size: 14px;
                    font-weight: 400;
                    max-width: 150px;
                    text-align: center;
                }
                svg {
                    font-size: 30px;
                    color: ${props => props.theme.textPrimaryColor};
                }

                @media screen and (min-width: 720px) {
                    width: unset;
                    max-width: unset;
                }
            }
        }
    }
    
    @media screen and (min-width: 880px) {
        flex-direction: row;
        .img-wrapper {
            max-width: 640px;
            margin-bottom: 0px;
        }
        .text-content {
            max-width: 640px;
        }
    }
`;



export const TrustSection = styled.section`
    background-color: ${props => props.theme.sectionBackgroundAlt};
    padding: 100px 0px;

    @media screen and (min-width: 880px) {
        padding: 100px 6%;
    }
   
`;
export const TrustSectionContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    background: rgb(41,17,39);
    background: ${props => props.theme.landingPageSectionGradient}; 
    /* background: rgb(44, 25, 52); */
    margin: 0px auto;
    padding: 80px 6%;
    
    .text-content {
        flex: 1;
        h3 {
            font-size: 30px;
            font-weight: 800;
            color: #fff;
            text-align: left;
            margin-bottom: 15px;
        }
        h4 {
            color: #f7f7f7;
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            max-width: 480px;
            line-height: 25px;
        }

        .action {
            display: flex;
            justify-content: flex-start;
            margin-top: 50px;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid ${props => props.theme.lightBorderColor};
                width: 200px;
                height: 55px;
                border-radius: 4px;
                gap: 20px;
                background-color: ${props => props.theme.containerBackground};

                span {
                    font-size: 13px;
                    font-weight: 400;
                    color: ${props => props.theme.darkTextColor};
                }

                svg {
                    font-size: 18px;
                    color: ${props => props.theme.darkTextColor};
                }
            }
        }
    }


    .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        position: relative;
        width: 400px;
        height: 280px;
        overflow-y: hidden;
        margin-bottom: 60px;
    }

    @media screen and (min-width: 720px) {
        h3 {
            font-size: 30px;
        }
    }

    @media screen and (min-width: 880px) {
        .img-wrapper {
            margin-bottom: 0px;
            align-self: unset;
        }

        border-radius: 10px;
        flex-direction: row;
        align-items: center;
    }
`;


export const SuportSection = styled.section`
    background-color: ${props => props.theme.containerBackground};
`;
export const SuportSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1280px;
    margin: 0px auto;
    padding: 100px 20px;

    .sec-title {
        font-size: 30px;
        margin-bottom: 20px;
        text-align: center;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        color: ${props => props.theme.darkTextColor};
        text-align: center;
        line-height: 24px;
    }

 
    .action {
        margin-top: 30px;
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${props => props.theme.primaryButtonColor};
            background-color: ${props => props.theme.primaryButtonColor};
            width: 200px;
            height: 55px;
            border-radius: 4px;
            gap: 10px;

            span {
                font-size: 13px;
                font-weight: 400;
                color: ${props => props.theme.primaryButtonTextColor};
            }

            svg {
                font-size: 18px;
                color: ${props => props.theme.primaryButtonTextColor};
            }
        }
    }
        

`;