import styled from 'styled-components'

export const HeaderWrapper = styled.header`
    display: block;
    position: fixed;
    width: 100%;
    top: 0px;
    right: 0px;
    height: 70px;
    z-index: 9999;
    padding: 0px 6%;
    background-color: ${props => props.theme.backgroudColor};

    @media screen and (min-width: 880px) {
        padding: 0px 4%;
        width: calc(100% - 240px);
    }
`;
export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 20px;

    .toggle-menu {
        display: block;
        font-size: 23px;
        color: ${props => props.theme.darkTextColor};
        cursor: pointer;
    }

    .left-col {
        display: flex;
        align-items: center;
        gap: 20px;
        flex: 1;

        .search-bar {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 20px 15px;
            background-color: ${props => props.theme.containerBackground};
            border: 1px solid ${props => props.theme.lightBorderColor};
            height: 38px;
            border-radius: 4px;
            max-width: 180px;
            flex: 1;

            @media screen and (min-width: 480px) {
                max-width: 250px;
            }

            @media screen and (min-width: 880px) {
                max-width: 400px;
            }

            svg {
                color: ${props => props.theme.lightTextColor};
            }

            input {
                min-width: 10px;
                border: none;
                flex: 1;
                background-color: transparent;
                color: ${props => props.theme.textColor};
            }
        }
    }

    .right-col {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        flex: 1;

        .create-btn {
            display: flex;
            align-items: center;
            height: 36px;
            gap: 4px;
            border: none;
            background-color: ${props => props.theme.primaryButtonColor};
            color: ${props => props.theme.primaryButtonTextColor};
            padding: 0px 12px;
            border-radius: 2px;
            cursor: pointer;
            
            svg {
                font-size: 17px;
                margin-top: 2px;
            }
        }
    }

    @media screen and (min-width: 880px) {
        .toggle-menu {
            display: none;
        }
    }
`;
export const Title = styled.h5`
    display: none;
    font-size: 14px;
    font-weight: 600;
    color: #0E141C;

    @media screen and (min-width: 880px) {
        display: none;
        font-size: 17px;
    }
`;
export const HeadMeta = styled.div`
    position: relative;

    .meta-content {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        cursor: pointer;

        svg {
            font-size: 16px;
            color: ${props => props.theme.darkTextColor};
        }

        .acct-info {
            display: none;
            flex-direction: column;
            align-items: flex-start;

            span {
                color: ${props => props.theme.darkTextColor};
                font-size: 13px;
                font-weight: 600;
                text-transform: capitalize;

                :last-child {
                    color: ${props => props.theme.lightTextColor};
                    font-size: 11px;
                    font-weight: 400;
                }

            }

            @media screen and (min-width: 640px) {
                display: flex;
            }
        }
        
        .notification-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border: 1px solid ${props => props.theme.lightPrimaryColor};
            background-color: ${props => props.theme.containerBackground};
            overflow: hidden;

            svg {
                font-size: 22px;
                color: ${props => props.theme.textPrimaryColor};
                font-size: 30px;
            }

            img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }


    .head-dropdown {
        display: block;
        background-color: ${props => props.theme.containerBackground};
        position: absolute;
        right: 0px;
        top: 50px;
        border-radius: 4px;
        width: 180px;
        border: 1px solid ${props => props.theme.lightBorderColor};
        box-shadow: 0px 0px 20px -5px ${props => props.theme.boxShadow};

        .profile {
            display: flex;
            align-items: center;
            padding: 10px 10px;
            gap: 4px;

            .img-wrapper {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .profile-info {
                display: flex;
                flex-direction: column;

                span {
                    color: ${props => props.theme.darkTextColor};
                    font-size: 13px;
                    font-weight: 400;
                    text-transform: capitalize;

                    :last-child {
                        color: ${props => props.theme.lightTextColor};
                        font-size: 11px;
                    }
                }
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            li {
                a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 15px;
                    color: ${props => props.theme.darkTextColor};
                    font-size: 13px;

                    svg {
                        font-size: 16px;
                    }
                }

                :nth-child(2) {
                    /* border-top: 1px solid ${props => props.theme.lightBorderColor}; */
                    /* border-bottom: 1px solid ${props => props.theme.lightBorderColor}; */
                }
            }
        }
    }

    .team-panel {
        display: block;
        background-color: ${props => props.theme.containerBackground};
        position: absolute;
        right: 0px;
        top: 50px;
        border-radius: 4px;
        width: 180px;
        border: 1px solid ${props => props.theme.lightBorderColor};
        box-shadow: 0px 0px 20px -5px ${props => props.theme.boxShadow};

        .back-btn {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 15px;
            cursor: pointer;

            svg {
                font-size: 18px;
                color: ${props => props.theme.textColor};
            }

            span {
                font-size: 14px;
                color: ${props => props.theme.textColor};
            }
        }

        ul {
            display: flex;
            flex-direction: column;

            li {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 10px 15px;
                cursor: pointer;

                .icon-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: ${props => props.theme.backgroudColor};
                    border: 1px solid ${props => props.theme.lightBorderColor};
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;

                    span {
                        font-size: 10px;
                        text-transform: uppercase;
                        color: ${props => props.theme.darkTextColor};
                    }
                }

                h4 {
                    font-size: 13px;
                    text-transform: capitalize;
                    font-weight: 400;
                    color: ${props => props.theme.darkTextColor};
                    flex: 1;
                }

                svg {   
                    color: ${props => props.theme.containerBackground};
                }
            }

            li.selected {
                background-color: ${props => props.theme.lightBorderColor};
                svg {
                    color: ${props => props.theme.textColor};
                }
            }
        }
    }
`;
