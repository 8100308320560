import Header from '../../components/header/Header'
import Hero from '../../components/hero/Hero'
import { BsArrowRight, BsCheckCircleFill } from 'react-icons/bs'
import { Container, EasyDeployContainer, EasyDeployWrapper, SuportSection, SuportSectionContainer, MainSection, Section, SectionContainer, TrustSection, TrustSectionContainer, Wrapper, DeveloperProductivity, DeveloperProductivityContainer, CodeEditorWrapper, CodeEditorHeader, CodeEditorContainer } from './styles'
import Footer from '../../components/footer/Footer'
import { Player } from '@lottiefiles/react-lottie-player';
import { FaCode, FaCodeBranch } from 'react-icons/fa'
import { AiOutlineAppstoreAdd, AiOutlineSync } from 'react-icons/ai'
import { TbLivePhoto } from 'react-icons/tb'
import { useEffect, useRef, useState } from 'react'
import PopupModal from '../../components/popupModal/PopupModal'
import Button from '../../components/button/Button'
import AxiosCall from '../../../utils/axios'
import Message from '../../components/message/Message'
import { Link } from 'react-router-dom'


const HomeScreen: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [showContactModal, setShowContactModal] = useState(false)
    const [selectedStack, setSelectedStack] = useState(1)
    const stackIntervalRef = useRef<any>();

    const fullNameRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)
    const bodyRef = useRef<HTMLTextAreaElement>(null)


  
    const rotateStack = () => {
        let _currentStack = 1;

        stackIntervalRef.current = setInterval(() => {
            if (_currentStack < 3) {
                _currentStack = _currentStack+1;
                setSelectedStack(_currentStack)
            } else {
                _currentStack = 1;
                setSelectedStack(1)
            }
        }, 3000)
    }

    useEffect(() => {
        rotateStack()

        return () => {
            clearInterval(stackIntervalRef.current);
        }
    }, [])

    const submitFeedback = async () => {
        try {
            if (!fullNameRef.current?.value || !emailRef.current?.value || !bodyRef.current?.value) {
               return Message.error("Fields can't be empty");
            }
            setIsLoading(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/feedback/add",
                data: {
                    full_name: fullNameRef.current?.value,
                    email: emailRef.current?.value,
                    body: bodyRef.current?.value,
                }
            });

            setIsLoading(false)
            if (res.status == 1) {
                setShowContactModal(false);
                Message.success("Feedback submitted");
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    return (
        <Wrapper>
            <Header />
            <Container>
                <Hero />
                <MainSection>
                    <Section background="#fff">
                        <SectionContainer>
                            <EasyDeployWrapper>
                                <EasyDeployContainer>
                                    <div className="img-wrapper">
                                        <Player
                                            src='/assets/docs/cloud.json'
                                            className="player"
                                            loop={true}
                                            autoplay
                                        />
                                    </div>
                                    <div className="text-content">
                                        <span className="label">Simplified Deployment</span>
                                        <h3>Easily deploy and scale your applications to users in minutes</h3>
                                        <p>Developing a great product is a lot of work already. Deploying your product should not be an hastly. Shipping your application to customers can be difficult but we got you coverd.</p>
                                        <p></p>
                                        <ul>
                                            <li><BsCheckCircleFill /><span>Easy To Use</span></li>
                                            <li><BsCheckCircleFill /><span>Secure</span></li>
                                            <li><BsCheckCircleFill /><span>Continuous integration and deployment</span></li>
                                            <li><BsCheckCircleFill /><span>Felxible</span></li>
                                        </ul>
                                        <div className="action">
                                            <Link to="/signup"><span>Create an account </span><BsArrowRight /></Link>
                                        </div>
                                    </div>
                                </EasyDeployContainer>
                            </EasyDeployWrapper>

                            
                        </SectionContainer>
                    </Section>


                    <Section id="how-it-works">
                        <SectionContainer>
                            <DeveloperProductivity>
                                <DeveloperProductivityContainer>
                                    <div className="text-content">
                                        <span className="label">Productivity</span>
                                        <h3>Built with developers in mind</h3>
                                        <p>Focus on building great products while we do the heavy lifting by providing a platform for you to easily push putdates to your users in seconds. Develop your application in any language of choice and leave the rest to us.</p>
                                        
                                        <ul>
                                            <li><FaCode /><h4>Step 1</h4><span>Build your application.</span></li>
                                            <li><AiOutlineAppstoreAdd /><h4>Step 2</h4><span>Create your app on coralscale</span></li>
                                            <li><FaCodeBranch /><h4>Step 3</h4><span>Connect the app to github</span></li>
                                            <li><TbLivePhoto /><h4>Live</h4><span>Your product is live</span></li>
                                            <li><AiOutlineSync /><h4>CICD</h4><span>Application updates on merge</span></li>
                                        </ul>
                                    </div>
                                    <div className="img-wrapper">
                                        <CodeEditorWrapper>
                                            <CodeEditorHeader>
                                                <span className={selectedStack == 1 ? "active" : ""}>React</span>
                                                <span className={selectedStack == 2 ? "active" : ""}>Vue</span>
                                                <span className={selectedStack == 3 ? "active" : ""}>PHP</span>
                                            </CodeEditorHeader>
                                            <CodeEditorContainer>
                                                {selectedStack == 1 && <img src="/assets/img/react-code-img.png" />}
                                                {selectedStack == 2 && <img src="/assets/img/vue-code-img.png" />}
                                                {selectedStack == 3 && <img src="/assets/img/php-code-img.png" />}
                                            </CodeEditorContainer>
                                        </CodeEditorWrapper>
                                    </div>
                                </DeveloperProductivityContainer>
                            </DeveloperProductivity>
                        </SectionContainer>
                    </Section>
                    <TrustSection>
                        <TrustSectionContainer>
                            <div className="text-content">
                                <h3>Secure your application</h3>
                                <h4>Build secure applications following industy standards. We provide you with the necessary tools to scale your platfrom from startup to entreprise level</h4>
                                <div className="action">
                                    <Link to="/signup"><span>JOIN NOW </span><BsArrowRight /></Link>
                                </div>
                            </div>

                            <div className="img-wrapper">
                                <Player
                                    src='/assets/docs/security.json'
                                    className="player"
                                    loop={true}
                                    autoplay
                                    style={{height: '100%', width: '100%'}}
                                />
                            </div>
                        </TrustSectionContainer>
                    </TrustSection>
                    <SuportSection id="contact">
                        <SuportSectionContainer>
                            <h3 className="sec-title">Got any questions or enquiries?</h3>
                            <p>We always happy to help you out in the best way we polibly can. Kindly reach out.</p>
                            
                            <div className="action">
                                <a href="#" onClick={(e) => {e.preventDefault(); setShowContactModal(true)}}><span>Contact us </span><BsArrowRight /></a>
                            </div>
                        </SuportSectionContainer>
                    </SuportSection>
                </MainSection>
            </Container>
            <Footer />

            <PopupModal isDashboard={false} showPopup={showContactModal} closeModal={() => setShowContactModal(false)}>
                <h4>Contact Us</h4>

                <input ref={fullNameRef} type="text" placeholder='Enter full name' />
                <input ref={emailRef}  type="text" placeholder='Enter email address' />
                <textarea ref={bodyRef} name="" id="" placeholder="Tell us your feedback"></textarea>
                
                <Button onClick={() => {submitFeedback()}} isLoading={isLoading}>Submit</Button>
            </PopupModal>
        </Wrapper>
    )
}

export default HomeScreen