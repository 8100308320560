import styled from 'styled-components'

export const Wrapper = styled.div`
    
`;
export const Container = styled.div`
    /* padding-top: 90px; */
`;
export const HeroWrapper = styled.div`
    background: ${props => props.theme.heroBackgroundGradient};
    box-shadow: 0px 0px 20px -14px #ccc;
    /* padding: 90px 0px 50px 0px; */
    padding: 90px 8% 50px 8%;
    padding-top: 90px;
    
`;

export const HeroContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px;
    

    h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 600;
        color: ${props => props.theme.whiteTextColor};
        background-color: rgba(255, 217, 252, 0.22);
        border: 0.5px solid #fff;
        padding: 0px 40px;
        height: 40px;
        border-radius: 20px;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 38px;
        font-weight: 800;
        text-align: center;
        color: ${props => props.theme.whiteTextColor};
        margin-bottom: 20px;
    }

    p {
        font-size: 15px;
        font-weight: 400;
        text-align: center;
        color: ${props => props.theme.whiteTextColor};
        line-height: 24px;
        max-width: 480px;
    }

    @media screen and (min-width: 880px) {
        h2 {
            font-size: 48px;
        }
    }
`;

export const MainSection = styled.main`
    background-color: ${props => props.theme.containerBackground};
    padding: 100px 8% 100px 8%;
`;



export const SubscriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar, ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
        display: none;
    }

    h4 {
        color: #282828;
    }

    @media screen and (min-width: 880px) {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        align-items: unset;
    }
`;

export const SubscriptionCard = styled.div<{isSelected: boolean}>`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.containerBackground};
    border: 0.5px solid ${props => props.isSelected ? props.theme.primaryColor : "#ccc"};
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 480px;

    h3 {
        margin-bottom: 20px;
    }

    .billing {
        display: flex;
        align-items: flex-end;
        gap: 5px;

        span.price {
            font-size: 40px;
            font-weight: 600;
            color: ${props => props.theme.darkTextColor};
        }

        span.interval {
            font-size: 14px;
            font-weight: 400;
            color: ${props => props.theme.darkTextColor};
            margin: 5px;
        }
    }


    p.notice {
        font-size: 13px;
        color: #282828;
        margin-top: 7px;
        margin-bottom: 20px;
    }

    ul {
        margin-bottom: 20px;
        li {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 20px;

            span.check-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid #282828;
                color: #282828;
                font-size: 13px;
            }

            span.title {
                font-size: 13px;
                font-weight: 500;
                color: #282828;
            }
        }
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 45px;
        padding: 0px 20px;
        border-radius: 8px;
        background-color: ${props => props.isSelected ? props.theme.primaryColor : "transparent"};
        color: ${props => props.isSelected ? "#fff" : "#282828"};
        border: 1px solid ${props => props.isSelected ? props.theme.primaryColor : "#ccc"};
        cursor: pointer;

        span {
            font-weight: 400;
        }

        svg {
            font-size: 18px;
        }
    }

    @media screen and (min-width: 880px) {
        width: calc(50% - 10px);
        max-width: unset;
    }

    @media screen and (min-width: 1080px) {
        width: calc(33.33% - 20px);
        max-width: 380px;
    }
`;

