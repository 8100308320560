import { AiFillInfoCircle, AiOutlineDeploymentUnit } from 'react-icons/ai'
import Button from '../../../../../components/button/Button'
import { AddSiteModal, Container, DomainInfoModal, EmptyContent, SiteDomain, SitesWrapper, Wrapper } from './styles'
import { MdAddCircleOutline } from 'react-icons/md'
import { useEffect, useRef, useState } from 'react'
import PopupModal from '../../../../../components/popupModal/PopupModal'
import Message from '../../../../../components/message/Message'
import AxiosCall from '../../../../../../utils/axios'
import Loader from '../../../../../components/Loader/Loader'
import dayjs from 'dayjs'
import { FiLink } from 'react-icons/fi'
import { FaLink } from 'react-icons/fa'
import { AppSliceInterface } from '../../../../../../slices/appSlice'
import { useAppSelector } from '../../../../../../hooks/hooks'
import { HiExternalLink } from 'react-icons/hi'

const DomainsTab: React.FC = () => {
    const appSlice: AppSliceInterface  = useAppSelector(state => state.apps.state);
    const teamSlice = useAppSelector(state => state.teams.state);
    const [addDomainModal, setAddDomainModal] = useState(false)
    const [domainInfoModal, setDomainInfoModal] = useState(false)
    const [isFetchingDomains, setIsFetchingDomains] = useState(true)
    const [selectedDomain, setSelectedDomain] = useState<{domain_name: string, status: string}>()
    const [domainList, setDomainList] = useState<any[]>([])
    const [isAddingDomain, setIsAddingDomain] = useState(false)
    const [isVerifyingDomain, setIsVerifyingDomain] = useState(false)
    const [selectedApp, setSelectedApp] = useState<any>()
    const [isFetchingApp, setIsFetchingApp] = useState(false)

    const domainNameRef = useRef<HTMLInputElement>(null)
    const appSlugRef = useRef<HTMLSelectElement>(null)

    const getFetchApp = async (appSlig: string) => {
        try {
            setIsFetchingApp(true)
            
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/app/fetch/"+appSlig
            });

            setIsFetchingApp(false)
            if (res.status == 1) {
                setSelectedApp(res.data.app)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingApp(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    const fetchDomains = async () => {
        try {
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/domains/fetch?team_id=" + teamSlice.defaultTeam!.id
            });

            setIsFetchingDomains(false)

            if (res.status == 1) {
                setDomainList(res.data ?? [])
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingDomains(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching domains")
        }
    }

    const addDomain = async () => {
        try {

            setIsAddingDomain(true)

            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/domain/add",
                data: {
                    domain_name: domainNameRef?.current?.value,
                    app_slug: appSlugRef?.current?.value,
                }
            });

            if (res.status == 1) {
                setSelectedDomain({
                    domain_name: domainNameRef?.current!.value,
                    status: "pending"
                })
                await getFetchApp(appSlugRef?.current!.value);
                setIsAddingDomain(false)
                setAddDomainModal(false)
                setDomainInfoModal(true)
                fetchDomains()
                Message.success("Domain added successfully")
            } else {
                setIsAddingDomain(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsAddingDomain(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    const verifyDomain = async () => {
        try {
            setIsVerifyingDomain(true)

            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/domain/verify",
                data: {
                    domain_name: selectedDomain?.domain_name,
                }
            });

            if (res.status == 1) {
                setIsVerifyingDomain(false)
                setSelectedDomain({...selectedDomain!, status: "active"})
                
                const filteredDomain = domainList.filter((item: any) => item.domain_name == selectedDomain?.domain_name)
                const domainIndex = domainList.indexOf(filteredDomain[0])
                const newDomainList = [...domainList]
                newDomainList[domainIndex]!.status = "active";
                setDomainList([...newDomainList!])

                Message.success("Domain verified successfully")
            } else {
                setIsVerifyingDomain(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            console.log(err);
            
            setIsVerifyingDomain(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    useEffect(() => {
        fetchDomains()
    }, [])

    return (
        <Wrapper>
            <Container>
                <div className="head">
                    <h5>Recent Domains</h5>

                    <button className="add-site" onClick={() => setAddDomainModal(true)}>
                        <MdAddCircleOutline />
                        <span>add domain</span>
                    </button>
                </div>
                <SitesWrapper>
                    <div className="site-list">
                        {isFetchingDomains ? <Loader styleTwo center topPadding="40px" /> : !domainList.length ? <EmptyContent>
                        <div className="text-content">
                            <FaLink />
                            <span>You've not added any domains</span>
                            <p>Domain is a unique url that users can use to access your web aplication on the internet</p>
                        </div>
                        <button onClick={() => setAddDomainModal(true)}>add donain</button>
                    </EmptyContent> : domainList.map((item: any, idx) => {
                            return <SiteDomain status={item.status} key={"apps-"+idx} onClick={() => {
                                setSelectedDomain({
                                    domain_name: item?.domain_name,
                                    status: item.status
                                })
                                setDomainInfoModal(true)
                                getFetchApp(item.app_slug);
                            }}>
                            <div className="img-wrapper">
                                <FiLink />
                            </div>
                            <div className="col">
                                <a href={"https://" + item?.domain_name} target='_blank'><h4>{item?.domain_name} <HiExternalLink /></h4></a>
                                <span>~ {dayjs(item?.date_created).format("MMM DD,  YYYY")}</span>
                            </div>
                            <div className="meta">
                                <span>{item?.status}</span>
                            </div>
                        </SiteDomain>
                        })}
                    </div>
                </SitesWrapper>
            </Container>

            <PopupModal showPopup={addDomainModal} closeModal={() => setAddDomainModal(false)}>
                <AddSiteModal>
                    <h4>Add New Domain</h4>

                    <input ref={domainNameRef} type="text" placeholder="Enter domain name" />
                    <select ref={appSlugRef} name="application-type" id="">
                        <option value="none"> -- select app -- </option>
                        {appSlice.apps.map((item, idx) => {
                            return  <option key={idx} value={item.slug}>{item.name} - {item.stack}</option>
                        })}
                    </select>
                    <Button onClick={() => addDomain()} isLoading={isAddingDomain}>CREATE</Button>
                </AddSiteModal>
            </PopupModal>

            <PopupModal closeModal={() => setDomainInfoModal(false)}  showPopup={domainInfoModal}>
                {isFetchingApp ? <Loader styleTwo /> : <DomainInfoModal>
                    <h4>Domain Infomation</h4>

                    <ul className="info">
                        <li>
                            <span>Domain Name: </span>
                            <span>{selectedDomain?.domain_name}</span>
                        </li>
                        <li>
                            <span>App: </span>
                            <span>{selectedApp?.name} - {selectedApp?.stack}</span>
                        </li>
                        <li>
                            <span>Status: </span>
                            <span>{selectedDomain?.status}</span>
                        </li>
                    </ul>

                    <div className="installation">
                        <div className="guide">
                            <b>Verification: </b>
                            <span>Create an A record and add this IP Address, {selectedApp?.server_address} as the value</span>
                        </div>

                        <div className="row">
                            <ul>
                                <li>Record</li>
                                <li>Value</li>
                            </ul>

                            <ul>
                                <li>A</li>
                                <li>{selectedApp?.server_address}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="button-row">
                        <button onClick={() => {setDomainInfoModal(false)}}>cancel</button>
                        <Button isDisabled={selectedDomain?.status == "active"} onClick={() => verifyDomain()} isLoading={isVerifyingDomain}>Verify</Button>
                    </div>
                </DomainInfoModal>}
            </PopupModal>
        </Wrapper>
    )
}

export default DomainsTab