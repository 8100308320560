import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
`;


export const HeadRow = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 20px;

    ::-webkit-scrollbar, ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
        display: none;
    }

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.darkTextColor};
        padding: 14px 20px;
        position: relative;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 0.5px solid ${props => props.theme.lightBorderColor};

        svg {
            font-size: 18px;
            color: ${props => props.theme.lightTextColor};
        }
    }

    :first-child {
        a {
            svg {
                font-size: 15px;
            }
        }
    }

    :nth-child(2) {
        a {
            svg {
                font-size: 15px;
            }
        }
    }

    a.active {
        color: ${props => props.theme.textPrimaryColor};
        border-bottom: 2px solid ${props => props.theme.primaryColor};

        svg {
            color: ${props => props.theme.textPrimaryColor};
        }
    }

    ::before {
        content: "";
        position: absolute;
        width: 100%;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};
        bottom: 0px;
    }

    @media screen and (min-width: 1080px) {

    }
`;

export const MainRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (min-width: 1080px) {
        flex-direction: row;
    }
`;

export const SitesInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    margin: 20px 0px;
`;

export const SitesWrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
    /* border: 1px solid ${props => props.theme.lightBorderColor}; */
    border: 1px solid ${props => props.theme.lightBorderColor};
    margin: 0px 0px;
    padding: 30px 30px;
    flex: 1;
    border-radius: 4px;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        .col {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;
            h5 {
                color: ${props => props.theme.darkTextColor};
                font-size: 16px;
                font-weight: 600;
                text-transform: capitalize;
            }
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.primaryButtonColor};
            border: none;
            width: 90px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.primaryButtonTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.primaryButtonTextColor};
                font-size: 15px;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 0px 0px;

        h2 {
            color: rgb(145, 145, 145);
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 25px;
        }

        .col {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 20px;
            width: 100%;

            @media screen and (min-width: 720px) {
                flex-direction: row;
            }

            label {
                font-size: 13px;
                font-weight: 600;
                width: 100%;
                max-width: 140px;
                color: ${props => props.theme.textColor};

                @media screen and (min-width: 880px) {
                    max-width: 240px;
                }
            }
    
            input {
                border: 1px solid rgb(231, 231, 231);
                height: 45px;
                padding: 0px 10px;
                font-size: 13px;
                border-radius: 4px;
                width: 100%;
                min-width: 20px;


                :disabled {
                    background-color: #eee;
                    border: 1px solid #ccc;
                }
            }

            .row {
                display: flex;
                gap: 20px;
                width: 100%;
                flex: 1;

                input {
                    flex: 1;
                    width: 100%;
                }

                .dropdown {
                    span {
                        font-size: 12px !important;
                    }
                }
            }
        }


        button {
            width: 100px;
            border-radius: 4px;
            color: ${props => props.theme.primaryButtonTextColor};
            font-size: 13px;
            font-weight: 500;
            border: none;
            height: 38px;
            margin-left: auto !important;
            background-color: ${props => props.theme.primaryButtonColor};
        }
        

    }

    @media screen and (min-width: 880px) {
        
    }
`;


export const AuthSettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    padding: 30px 30px;
    flex: 1;
    border-radius: 4px;

    h2 {
        color: rgb(145, 145, 145);
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25px;
    }

    .col {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        width: 100%;

        @media screen and (min-width: 720px) {
            flex-direction: row;
        }

        label {
            font-size: 13px;
            font-weight: 600;
            width: 100%;
            max-width: 140px;
            color: ${props => props.theme.textColor};

            @media screen and (min-width: 880px) {
                max-width: 240px;
            }
        }

    }


    button {
        width: 140px;
        border-radius: 4px;
        color: ${props => props.theme.textColor};
        font-size: 13px;
        font-weight: 500;
        border: 1px solid rgba(0, 0, 0, 0.05);
        height: 38px;
        background-color: rgb(250, 250, 250);
        cursor: pointer;
    }

    @media screen and (min-width: 880px) {
        
    }
`;

export const SideBar = styled.div`
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    margin: 20px 0px;
    padding: 20px 20px;
    border-radius: 4px;
    min-height: 400px;
    width: 100%;
    
    @media screen and (min-width: 1080px) {
        width: 30%;
        min-width: 320px;
    }
`;
