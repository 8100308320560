import Axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

// const host = "https://api.coralscale.com";

const AxiosCall = async (requestObj) => {
  const { path, method, data, contentType = "application/json", version = "", custormBaseURL = null} = requestObj;

  const token = localStorage.getItem("authToken");

  const headers = {
    "Content-Type": contentType,
    "Authorization": "Bearer "+ token
  };
 

  const url = version ? `${baseURL}${version}/${path}` : `${baseURL}${path}`;
  try {
    const response = await Axios({ method, url, data, headers, json: true });
    const result = response && response.data

    return result;
  } catch (error) {
    // console.log(error);
    if (error.response.status === 401) {
      localStorage.setItem("authToken", "");
      const currentPath = window.location.href.replace(window.location.host, '').replace(window.location.protocol + '//', '')
      const urlEncoded = encodeURIComponent(currentPath)
      window.location.href = `/signin?redirect=${urlEncoded}`;
    }
    throw error;
  }
};

export default AxiosCall;