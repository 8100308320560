import { Container, EmptyContent, MainRow, SideBar, SiteItem, SitesWrapper, Wrapper } from './styles'
import { MdAddCircleOutline } from 'react-icons/md'
import { useEffect, useState } from 'react'
import Loader from '../../../../../components/Loader/Loader'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks'
import { getApps } from '../../../../../../actions/appActions'
import { IoMdApps } from 'react-icons/io'
import { AppSliceInterface, Pebble } from '../../../../../../slices/appSlice'
import { useNavigate, useParams } from 'react-router'
import AxiosCall from '../../../../../../utils/axios'
import Message from '../../../../../components/message/Message'

const PebblesTab: React.FC = () => {
    const { projectId } = useParams()
    const teamsSlice  = useAppSelector(state => state.teams.state);
    const [isLoading, setIsLoading] = useState(false)
    const [pebbles, setPebbles] = useState<Pebble[]>([])
    const dispatch = useAppDispatch()

    const navigate = useNavigate()
    
    const fetchPebbles = async () => {
        try {
            if (!teamsSlice.defaultTeam?.id) return
            const saved_team_settings = localStorage.getItem("default_team")
            setIsLoading(true)
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/project/apps/fetch?team_id=" + teamsSlice.defaultTeam.id + "&project_id=" + projectId
            });

            setIsLoading(false)
            if (res.status == 1) {
                setPebbles(res.data.appList)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching pebbles")
        }
    }


    useEffect(() => {
        fetchPebbles()
    }, [teamsSlice.defaultTeam?.id])

    return (
        <Wrapper>
            <Container>
                <div className="head">
                    <h5>Recent Pebbles</h5>
                    
                    <button className="add-site" onClick={() => navigate("/dashboard?coral-action=create-app")}>
                        <MdAddCircleOutline />
                        <span>add pebble</span>
                    </button>
                </div>
                <MainRow>
                    <SitesWrapper>
                        <div className="site-list">
                            {isLoading ? <Loader styleTwo center topPadding="30px" bottomPadding="30px" /> : !pebbles.length ? <EmptyContent>
                                <div className="text-content">
                                    <IoMdApps />
                                    <span>You've not added any pebble</span>
                                    <p>A pebble is a stand alone server which is used to host a frontend or backend product that you've build</p>
                                </div>
                                <button onClick={() => navigate("/dashboard?coral-action=create-app")}>add app</button>
                            </EmptyContent> :  pebbles.map((item: any, idx) => {
                                return <SiteItem state={item} to={"/dashboard/pebbles/"+item.slug} key={"apps-"+idx}>
                                <div className="img-wrapper">
                                    <img src={"/assets/img/colored/"+item?.stack+".png"} alt="" />
                                </div>
                                <div className="col">
                                    <h4>{item.name}</h4>
                                    <span>{item.stack}</span>
                                </div>
                                <div className="meta">
                                    <span>active</span>
                                    <span>Deployed: July 3rd 2023</span>
                                </div>
                            </SiteItem>
                            })}
                        </div>
                    </SitesWrapper>
                </MainRow>
            </Container>
        </Wrapper>
    )
}

export default PebblesTab