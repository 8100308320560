import { Container, EmptyContent, MainRow, SideBar, SiteItem, SitesWrapper, Wrapper } from './styles'
import { MdAddCircleOutline } from 'react-icons/md'
import { useEffect, useState } from 'react'
import Loader from '../../../components/Loader/Loader'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { getApps } from '../../../../actions/appActions'
import { IoMdApps } from 'react-icons/io'
import { AppSliceInterface } from '../../../../slices/appSlice'
import { useNavigate } from 'react-router'

const SitesScreen: React.FC = () => {
    const appSlice: AppSliceInterface  = useAppSelector(state => state.apps.state);
    const [addSiteModal, setAddSiteModal] = useState(false)
    const dispatch = useAppDispatch()

    const navigate = useNavigate()


    useEffect(() => {
        if (!appSlice.fetchedSites) {
            getApps(dispatch)
        }
    }, [])

    return (
        <Wrapper>
            <Container>
                <div className="head">
                    <h5>Recent Pebbles</h5>
                    
                    <button className="add-site" onClick={() => navigate("/dashboard?coral-action=create-app")}>
                        <MdAddCircleOutline />
                        <span>add pebble</span>
                    </button>
                </div>
                <MainRow>
                    <SitesWrapper>

                        <div className="site-list">
                            {appSlice.isLoading ? <Loader styleTwo center topPadding="40px" /> : !appSlice.apps.length ? <EmptyContent>
                                <div className="text-content">
                                    <IoMdApps />
                                    <span>You've not added any pebble</span>
                                    <p>A pebble is a stand alone server which is used to host a frontend or backend product that you've build</p>
                                </div>
                                <button onClick={() => navigate("/dashboard?coral-action=create-app")}>add app</button>
                            </EmptyContent> :  appSlice.apps.map((item: any, idx) => {
                                return <SiteItem state={item} to={"/dashboard/pebbles/"+item.slug} key={"apps-"+idx}>
                                <div className="img-wrapper">
                                    <img src={"/assets/img/colored/"+item?.stack+".png"} alt="" />
                                </div>
                                <div className="col">
                                    <h4>{item.name}</h4>
                                    <span>{item.stack}</span>
                                </div>
                                <div className="meta">
                                    <span>active</span>
                                    <span>Deployed: July 3rd 2023</span>
                                </div>
                            </SiteItem>
                            })}
                        </div>
                    </SitesWrapper>
                </MainRow>
            </Container>
        </Wrapper>
    )
}

export default SitesScreen