import styled from 'styled-components'

export const DropdownSelect = styled.div<{selected?: boolean, top?: string | number, left?: string | number, width?:  string | number}>`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    position: relative;
    border: 1px solid ${props => props.theme.lightBorderColor};
    padding: 13px 10px;
    border-radius: 4px;

    span {
        /* width: 50px; */
        white-space: nowrap;
        overflow: hidden;
        color: ${props => props.theme.textColor};
        flex: 1;
        font-size: 14px;
    }

    svg {
        font-size: 15px;
        color: #ccc;
    }

    .options-wrapper {
        display: ${props => props.selected ? "block" : " none"};
        max-height: 200px;
        overflow-y: auto;
        flex-direction: column;
        position: absolute;
        top: ${props => props.top ? props.top+"px" : "calc(100% + 10px)"};
        left: ${props => props.left ? props.left +"px" : "0px"};
        width: 100%;
        max-width: ${props => props.width ? props.width + "px" : "unset"};
        background-color: ${props => props.theme.containerBackground};
        padding: 0px 0px;
        border-radius: 4px;
        border: 1px solid ${props => props.theme.lightBorderColor};
        box-shadow: 0px 10px 24px ${props => props.theme.boxShadow};
        z-index: 999;

        .options {
            display: flex;
            align-items: center;
            color: ${props => props.theme.lightTextColor};
            font-size: 14px;
            padding: 6px 10px;
            height: 40px;
            cursor: pointer;


            :hover {
                background-color: ${props => props.theme.backgroudColor};
            }
        }

        .active-option {
            background-color: ${props => props.theme.backgroudColor};
        }

        .search-wrapper {
            padding: 4px 4px;

            input {
                height: 30px;
            }
        }
    }
`;
