import React, { useState } from 'react';
import { Content, HeaderWrapper, HeadMeta, Title } from './styles';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { IoChevronDown, IoMenuOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { setNavigation } from '../../../slices/navigationSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { HiOutlineCheck } from 'react-icons/hi';
import { setIsSwitchingTeam, Team } from '../../../slices/teamSlice';
import Loader from '../Loader/Loader';
import { getInitials } from '../../../utils';
import { updateDefaultTeam } from '../../../actions/indexActions';
import { Link } from 'react-router-dom';

const  DashboardHeader: React.FC<{title: String}> = ({title}) => {
    const userProfile = useAppSelector(state => state.profile.state);
    const teamSlice = useAppSelector(state => state.teams.state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentHeadMetaMenu, setCurrentHeadMetaMenu] = useState("")

    const logout = () => {
        localStorage.removeItem("authToken")
        navigate("/signin")
    }

    const openMetaPanel = () => {
        if (currentHeadMetaMenu != "") {
            setCurrentHeadMetaMenu("")
        } else {
            setCurrentHeadMetaMenu("MAIN_PANEL")
        }
    }

    const switchTeam = (team: Team) => {
        if (team.id == teamSlice.defaultTeam?.id) return
        dispatch(setIsSwitchingTeam(true))
        updateDefaultTeam(team)
        setTimeout(() => {
            window.location.href = "/dashboard"
        }, 1000);
    }

    return (
        <HeaderWrapper>
            <Content>
                <div className="left-col">
                    <div className="toggle-menu" onClick={() => dispatch(setNavigation({dashboard: true}))}>
                        <IoMenuOutline />
                    </div>
                    <div className="search-bar">
                        <FiSearch />
                        <input type="search" placeholder="Search for resource" />
                    </div>
                </div>
                <Title>
                    {title}
                </Title>

                <div className="right-col">
                    <button className="create-btn" onClick={() => navigate("/dashboard/projects/new")}>
                        <span>Create</span>
                        <IoChevronDown />
                    </button>
                    <HeadMeta>
                        <div className="meta-content" onClick={() => openMetaPanel()}>
                            <div className="notification-wrapper">
                                <img src="/assets/img/avatar-alt.png" alt="avatar" />
                            </div>
                            <div className="acct-info">
                                <span>{userProfile.firstName}</span>
                                <span>{teamSlice.defaultTeam?.name}</span>
                            </div>
                            <IoChevronDown />
                        </div>

                        {currentHeadMetaMenu == "MAIN_PANEL" ? <div className="head-dropdown">
                            <div className="profile">
                                <div className="img-wrapper">
                                    <img src="/assets/img/avatar-alt.png" alt="avatar" />
                                </div>
                                <div className="profile-info">
                                    <span>{userProfile.firstName} {userProfile.lastName}</span>
                                    <span>({teamSlice.defaultTeam?.role?.toLowerCase()})</span>
                                </div>
                            </div>

                            <ul>
                                <li>
                                    <Link to="/dashboard/settings">My Account</Link>
                                </li>
                                <li>
                                    <a href="#" onClick={() => {
                                        setCurrentHeadMetaMenu("TEAM_PANEL")
                                    }}>
                                        Switch Team
                                        <RiArrowRightSLine />
                                    </a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => logout()}>Sign Out</a>
                                </li>
                            </ul>
                        </div> : <></>}
                        
                        {currentHeadMetaMenu == "TEAM_PANEL" && <div className="team-panel">
                            <div className="back-btn"onClick={() => {
                                        setCurrentHeadMetaMenu("MAIN_PANEL")
                                    }}>
                                <RiArrowLeftSLine />
                                <span>Back</span>
                            </div>

                            <ul>
                                {teamSlice.isLoading ? <Loader styleTwo center bottomPadding="10px" /> : teamSlice?.teams!.map((item, idx) => {
                                    return <li key={idx} className={`${item.id == teamSlice.defaultTeam?.id ? "selected" : ""}`} onClick={() => switchTeam(item)}>
                                        <div className="icon-box">
                                            <span>{getInitials(item.name!)}</span>
                                        </div>
                                        <h4>{item.name}</h4>

                                        <HiOutlineCheck />
                                    </li>
                                })}
                            </ul>
                        </div>}
                    </HeadMeta>
                </div>
            </Content>
        </HeaderWrapper>
    )
}

export default DashboardHeader