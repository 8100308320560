import { Container, Sidebar, Wrapper } from './styles'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import AxiosCall from '../../../../utils/axios'
import Message from '../../../components/message/Message'
import Loader from '../../../components/Loader/Loader'
import useQuery from '../../../../hooks/useQuery'
import { HiOutlineHome } from 'react-icons/hi'
import { useAppDispatch } from '../../../../hooks/hooks'
import { setTeams } from '../../../../slices/teamSlice'
import { setProfile } from '../../../../slices/profileSlice'


const AuthScreen: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [error, setError] = useState("")
    const dispatch = useAppDispatch()


    const query = useQuery()
    const authenticate = async () => {

        const auth_code = query.get('code');

        try {
            setIsLoading(true)
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/social/signup?code=" + auth_code
            });

            if (res.status == 1) {
                setIsLoading(false)
                localStorage.setItem("authToken", res.data.token)

                localStorage.setItem("default_team", JSON.stringify({
                    id: res.data?.default_team?.id,
                    name: res.data?.default_team?.name,
                    role: res.data?.default_team?.role
                }))

                dispatch(setTeams({defaultTeam: {
                    id: res.data?.default_team?.id,
                    name: res.data?.default_team?.name,
                    role: res.data?.default_team?.role
                }}))

                dispatch(setProfile({
                    email: res.data.email,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    balance: res.data.balance,
                    verified_email: res.data.verified_email,
                    verified_phone: res.data.verified_phone,
                    fetchedProfile: true,
                }))

                return navigate("/dashboard");
            } else {
                setIsLoading(false)
                setError(res.message)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            setError(err?.response?.data?.message ?? "An error occurred")
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    useEffect(() => {
        authenticate()
    }, [])

    return (
        <Wrapper>
            <Container>
                <Sidebar>
                    <div className="content">
                        {error == "" || isLoading ? <div className="text-content">
                            <img src="/assets/img/logo.png" alt="site logo"/>
                            <h4>Authorizing ...</h4>
                        </div> : <div className="error">
                            <h4>Error: {error}</h4>

                            <Link to="/"><HiOutlineHome /> home</Link>
                        </div>}
                        {isLoading && <Loader />}
                    </div>
                </Sidebar>
            </Container>
        </Wrapper>
    )
}

export default AuthScreen