import styled from 'styled-components'

export const Wrapper = styled.div`
    
`;
export const Container = styled.div`
    /* padding-top: 90px; */
`;
export const HeroWrapper = styled.div`
    background: ${props => props.theme.heroBackgroundGradient};
    box-shadow: 0px 0px 20px -14px #ccc;
    /* padding: 90px 0px 50px 0px; */
    padding: 90px 4% 50px 4%;
    padding-top: 90px;
    
`;

export const HeroContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px;
    

    h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 600;
        color: ${props => props.theme.whiteTextColor};
        background-color: rgba(255, 217, 252, 0.22);
        border: 0.5px solid #fff;
        padding: 0px 40px;
        height: 40px;
        border-radius: 20px;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 38px;
        font-weight: 800;
        text-align: center;
        color: ${props => props.theme.whiteTextColor};
        margin-bottom: 20px;
    }

    p {
        font-size: 15px;
        font-weight: 400;
        text-align: center;
        color: ${props => props.theme.whiteTextColor};
        line-height: 24px;
        max-width: 480px;
    }

    @media screen and (min-width: 880px) {
        h2 {
            font-size: 48px;
        }
    }
`;

export const MainSection = styled.main`
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: ${props => props.theme.containerBackground};
    padding: 100px 8% 100px 8%;

    @media screen and (min-width: 880px) {
        flex-direction: row;
        justify-content: space-between;
    }

    .info-sec {
        display: flex;
        flex-direction: column;
        gap: 50px;

        @media screen and (min-width: 880px) {
            max-width: 640px;
        }

        .sec-1 {
            h3 {
                font-size: 38px;
                font-weight: 600;
                color: #1E1E1E;
                margin-bottom: 20px;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: #888888;
                margin-bottom: 20px;
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 10px;

                li {
                    a {
                        font-size: 14px;
                        color: #888888;
                    }
                }
            }
        }

        .sec-2 {
            ul {
                display: flex;
                flex-direction: column;
                gap: 20px;

                @media screen and (min-width: 480px) {
                    flex-direction: row;
                }

                li {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    flex: 1;

                    h5 {
                        font-size: 16px;
                        font-weight: 600;
                        color: #282828;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #888888;
                    }
                }
            }
        }
    }

    .form-sec {
        background-color: #FAFAFA;
        border: 1px solid #DFE1E5;
        padding: 20px;
        border-radius: 10px;

        @media screen and (min-width: 880px) {
            max-width: 640px;
        }

        h3 {
            font-size: 28px;
            font-weight: 600;
            color: #1E1E1E;
            margin-bottom: 20px;
        }

        .row {
            display: flex;
            gap: 20px;
            max-width: 100%;
        }

        .input-col {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 30px;
            flex: 1;
            /* overflow: hidden; */
            max-width: 100%;
            min-width: 100px;

            label {
                font-size: 14px;
                font-weight: 500;
                color: #101928;
            }

            input, textarea {
                height: 45px;
                border: none;
                padding: 0px 20px;
                color: #282828;
                border-radius: 8px;
                font-size: 13px;
                min-width: 20px;
                max-width: 100%;
                border: 1px solid ${props => props.theme.lightBorderColor};
            }

            textarea {
                resize: none;
                height: 200px;
                padding: 20px 20px;
            }
        }

        button {
            background-color: ${props => props.theme.primaryButtonColor};
            border: none;
            height: 45px;
            color: ${props => props.theme.primaryButtonTextColor};
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            border-radius: 4px;
            cursor: pointer;
        }
    }
`;

