import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`;
export const Container = styled.div`
    /* padding: 0px 6%;
    @media screen and (min-width: 880px) {
        padding: 0px 4%;
    } */
    width: 100%;
`;
export const StatsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    flex-direction: column;

    @media screen and (min-width: 880px) {
        flex-direction: row;
        /* gap: 4%; */
    }
`;

export const MainRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (min-width: 1080px) {
        flex-direction: row;
    }
`;

export const SitesWrapper = styled.div`
    flex: 1;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    margin: 20px 0px;
    border-radius: 4px;

    .head {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};
        padding: 20px 20px;

        h5 {
            color: ${props => props.theme.textColor};
            font-size: 14px;
            font-weight: 600;
            text-align: center;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.darkTextColor};
            border: none;
            width: 90px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.whiteTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.whiteTextColor};
                font-size: 15px;
            }
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;

export const ContentWrapper = styled.form`
    padding: 20px;
`;



export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 480px;
    margin: 40px auto;

    .img-wraper {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0px auto 40px auto;
        /* border: 1px solid ${props => props.theme.lightPrimaryColor}; */
        background-color: ${props => props.theme.lightPrimaryColor};

        img {
            width: 80px;
            height: 80px;
            object-fit: cover;
        }
    }


    label {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: ${props => props.theme.labelColor};
        margin-bottom: 8px;
    }

    input, select, textarea {
        display: block;
        background-color: ${props => props.theme.containerBackground};
        height: 50px;
        font-size: 13px;
        font-weight: 300;
        color: ${props => props.theme.textColor};
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 20px;
        border: 1px solid rgba(145, 145, 145, 0.3);

        :disabled {
            background-color: ${props => props.theme.disabledInputColor};
        }
    }

    textarea {
        height: 200px;
        resize: none;
    }
`;

export const InputSec = styled.div`

`;