import { PaymentModal } from './styles'
import Loader from '../../../components/Loader/Loader'
import { useEffect, useState } from 'react'
import PopupModal from '../../../components/popupModal/PopupModal'
import { FaRegCreditCard } from 'react-icons/fa'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { updateWalletState } from '../../../../slices/walletSlice'
import PaystackModal from '../payments/paystack_modal/PaystackModal'
import StripeModal from '../payments/stripe_modal/StripeModal'
import useWalletPaymentModel from '../payments/useWalletPaymentModel'

const WalletPaymentModal: React.FC = () => {
    const debitCardState = useAppSelector(state => state.debitCard.state);
    const walletState = useAppSelector(state => state.wallet.state);
    const [amount, setAmount] = useState("")

    const walletPaymentModel = useWalletPaymentModel()
    const [serverCost, setServerCost] = useState(0)
    const dispatch = useAppDispatch()

    const handlePayment = async () => {
        if (walletPaymentModel.isInitTransaction) return
        const _amount = parseFloat(amount)
        if (walletState.selectedPaymentMethod == "debit_card") {
            await walletPaymentModel.cardFundWallet({paymentMethod: walletState.selectedPaymentMethod, cardId: walletState.selectedCardId, amount: _amount});
        } else {

            await walletPaymentModel.getPaymentReference({paymentMethod: walletState.selectedPaymentMethod, amount: _amount});
        }
        
    }

    useEffect(() => {
        if (walletPaymentModel.initData != null) {
            if (walletState.selectedPaymentMethod == "paystack") {
                setServerCost(walletPaymentModel.initData.amount)
                dispatch(updateWalletState({...walletState, showPaystackModal: true, paymentReference: walletPaymentModel?.initData?.reference}))
            } else if (walletState.selectedPaymentMethod == "stripe") {
                console.log("stripe payment intent generated: ", walletPaymentModel?.initData?.client_secret);
                
                dispatch(updateWalletState({...walletState, showStripeModal: true, stripeClientSecret: walletPaymentModel?.initData?.client_secret, paymentReference: walletPaymentModel?.initData?.payment_id}))
            }
        }
    }, [walletPaymentModel.initData])

    return (
        <>
            <PopupModal showPopup={walletState.showPaymentMethodModal} closeModal={() => {dispatch(updateWalletState({...walletState, showPaymentMethodModal: false}));}}>
                <PaymentModal>
                    <h4>Make Payment</h4>

                    <label htmlFor="wallet-input-amount">Amount</label>
                    <input id='wallet-input-amount' type="text" placeholder="Enter amount" inputMode="numeric" onChange={(e) => {setAmount(e.target.value)}} />

                    <div className="payment-gateways">
                        <ul>
                            <li className={walletState.selectedPaymentMethod == "paystack" ? "selected" : ""} onClick={() => { dispatch(updateWalletState({...walletState, selectedPaymentMethod: "paystack"})); }}>
                                <div className="img-box">
                                    <img src="/assets/img/paystack-icon.jpg" alt="" />
                                </div>
                                <span>Paystack</span>
                                <div className="select-box"></div>
                            </li>
                            <li className={walletState.selectedPaymentMethod == "stripe" ? "selected" : "stripe"}>
                                <div className="img-box">
                                    <img src="/assets/img/stripe-icon.png" alt="" />
                                </div>
                                <span>Stripe</span>
                                <div className="select-box"></div>
                            </li>
                        </ul>
                    </div>

                    <div className="payment-cards">
                        <ul>
                            {debitCardState.cards.map((item, idx) => {
                                return <li className={walletState.selectedPaymentMethod == "debit_card" && walletState.selectedCardId == item.id  ? "selected" : "stripe"} key={idx} onClick={() => {
                                    dispatch(updateWalletState({...walletState, selectedPaymentMethod: "debit_card", selectedCardId: item.id!}))}
                                    }>
                                    <div className="main">
                                        <div className="icon-box">
                                            <FaRegCreditCard />
                                        </div>
                                        <h4>{item.type} ****{item.card_number}</h4>
                                    </div>
                                    <div className="select-box"></div>
                                </li>
                            })}
                        </ul>
                    </div>

                    <button disabled={walletState.selectedPaymentMethod == "none" || amount == "" || walletPaymentModel.isInitTransaction} onClick={handlePayment}>{walletPaymentModel.isInitTransaction || walletPaymentModel.isFundingWallet ? <Loader /> : "Continue"}</button>
                </PaymentModal>
            </PopupModal>
            {walletState.showPaystackModal && <PaystackModal transType="wallet_topup" amount={serverCost} reference={walletState.paymentReference} />}
            {walletState.showStripeModal && <StripeModal transType="wallet_topup" clientSecret={walletState.stripeClientSecret} />}
        </>
    )
}

export default WalletPaymentModal