import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { usePaystackPayment } from "react-paystack";
import Message from "../../../components/message/Message";
import AxiosCall from "../../../../utils/axios";
import { InitializePayment } from "react-paystack/dist/types";
import { updateCreatAppState } from "../../../../slices/createAppSlice";
import { updateWalletState } from "../../../../slices/walletSlice";
import { fetchProfileAction } from "../../../../actions/profileAction";
import { setDebitCards } from "../../../../slices/debitCardSlice";

const useWalletPaymentModel = () => {
    const userProfile = useAppSelector(state => state.profile.state);
    const teamSlice = useAppSelector(state => state.teams.state);
    const debitCardState = useAppSelector(state => state.debitCard.state);
    const wallet = useAppSelector(state => state.wallet.state);
    const [initData, setInitData] = useState<any>(null)
    const [isInitTransaction, setIsInitTransaction] = useState(false)
    const dispatch = useAppDispatch()
    const [showAddCardModal, setShowAddCardModal] = useState(false)

    const handlePaystackPaymet = (initializePayment: InitializePayment) => {
        const onSuccess = (reference: string) => {
            verifyTransaction({})

            console.log("payment successful")
        };
      
        const onClose = () => {
          console.log('paustack closed')
        }

        initializePayment({onSuccess, onClose})
    }



    const getPaymentReference = async ({paymentMethod, planId, amount} : {paymentMethod: string, planId?: string, amount?: number}) => {
        try {
            setIsInitTransaction(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/transaction/init?trans_type=wallet_topup",
                data: {
                    payment_method: paymentMethod,
                    amount: amount ?? 0
                }
            });

            // console.log("response:",res);
            if (res.status == 1) {
                setIsInitTransaction(false)

                setInitData(res.data)
            } else {
                setIsInitTransaction(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsInitTransaction(false)
            Message.error(err?.response.data.message)
        }
    }

    const [isFundingWallet, setIsFundingWallet] = useState(false)

    const cardFundWallet = async ({cardId, paymentMethod, planId, amount} : {paymentMethod: string, planId?: string, cardId: number | null, amount?: number}) => {
        try {
            setIsFundingWallet(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/user/wallet/fund/card",
                data: {
                    card_id: cardId,
                    amount: amount ?? 0
                }
            });

            if (res.status == 1) {
                setIsFundingWallet(false)
                Message.success("Wallet funded successfully")

                dispatch(updateWalletState({...wallet, showPaymentMethodModal: false, showStripeModal: false, isVerifyingTransaction: false}))
                fetchProfileAction(dispatch)
            } else {
                setIsFundingWallet(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFundingWallet(false)
            Message.error(err?.response.data.message)
        }
    }

    const verifyTransaction = async ({paymentMethod, paymentReference} : {paymentMethod?: string, paymentReference?:  string}) => {
        try {
            dispatch(updateWalletState({...wallet, isVerifyingTransaction: true, showPaymentMethodModal: false, showStripeModal: false, showPaystackModal: false}))
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/transaction/verify?trans_type=wallet_topup&team_id" + teamSlice.defaultTeam?.id,
                data: {
                    payment_method: paymentMethod ?? wallet.selectedPaymentMethod,
                    reference: paymentReference ??wallet.paymentReference,
                }
            });

            // setIsVerifyingTransaction(false)
            if (res.status == 1) {
                dispatch(updateWalletState({...wallet, showPaymentMethodModal: false, showStripeModal: false, isVerifyingTransaction: false}))
                fetchProfileAction(dispatch)
                Message.success("Wallet topup successfully")
            } else {
                dispatch(updateWalletState({...wallet, isVerifyingTransaction: false}))
                Message.error(res.message)
            }
        } catch (err: any) {
            dispatch(updateWalletState({...wallet, isVerifyingTransaction: false}))
            Message.error(err?.response.data.message)
        }
    }

    const [transactions, setTransactions] = useState<any[]>([])
    const [isFetchingTransactions, setIsFetchingTransactions] = useState(false)

    const fetchTransactions = async () => {
        try {
            setIsFetchingTransactions(true)
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/transactions/fetch",
            });
            setIsFetchingTransactions(false)
            if (res.status == 1) {
                setTransactions(res.data)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingTransactions(false)
            Message.error(err?.response.data.message)
        }
    }

    const config = {
        reference: (new Date()).getTime().toString(),
        email: userProfile.email,
        amount: 10000,
        publicKey: process.env.REACT_APP_PAYSTACK_PK_KEY!,
    }
    const initializePayment = usePaystackPayment(config);

    const initAddCardPayment = () => {
        const onSuccess = (response: any) => {
            addCard(response.reference)
        };

        const onClose = () => {
        }

        initializePayment({onSuccess, onClose})
    }

    const [isAddingCard, setIsAddingCard] = useState(false)
    const addCard = async (reference: string) => {
        try {
            setIsAddingCard(true)
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/user/cards/add",
                data: {
                    reference: reference,
                }
            });

            setIsAddingCard(false)
            if (res.status == 1) {
                setShowAddCardModal(false)
                fetchCards()
                Message.success("Card added successfully")
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsAddingCard(false)
            Message.error(err?.response.data.message)
        }
    }

    const fetchCards = async () => {
        try {

            dispatch(setDebitCards({ ...debitCardState, isLoading: true}))
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/user/cards/fetch"
            });

            if (res.status == 1) {
                dispatch(setDebitCards({ ...debitCardState, isLoading: false, cards: res.data}))
            } else {
                dispatch(setDebitCards({ ...debitCardState, isLoading: false}))
                Message.error(res.message)
            }
        } catch (err: any) {
            dispatch(setDebitCards({ ...debitCardState, isLoading: false}))
            Message.error(err?.response.data.message)
        }
    }

    return {
        isInitTransaction,
        handlePaystackPaymet,
        getPaymentReference,
        initData,
        transactions,
        isFetchingTransactions,
        fetchTransactions,
        initAddCardPayment,
        addCard,
        isAddingCard,
        fetchCards,
        isFundingWallet,
        cardFundWallet,
        showAddCardModal,
        setShowAddCardModal,
        verifyTransaction
    }
}

export default useWalletPaymentModel