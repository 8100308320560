import { Container, EmptyContent, ProjectCard, SitesInfoWrapper, SitesWrapper, Wrapper } from './styles'
import { MdAddCircleOutline } from 'react-icons/md'
import Loader from '../../../components/Loader/Loader'
import dayjs from 'dayjs'
import { FaLink } from 'react-icons/fa'
import { useAppSelector } from '../../../../hooks/hooks'
import { useNavigate } from 'react-router'
import { RiArchiveDrawerFill } from 'react-icons/ri'
import { AiOutlineRight } from 'react-icons/ai'
import { Project } from '../../../../slices/projectSlice'

const ProjectsScreen: React.FC = () => {
    const projectsSlice = useAppSelector(state => state.projects.state)
    const navigate = useNavigate()


    return (
        <Wrapper>
            <Container>
                <SitesInfoWrapper>
                    <div className="head">
                        <h5>Recent Projects</h5>

                        <button className="add-site" onClick={() => navigate("/dashboard/projects/new")}>
                            <MdAddCircleOutline />
                            <span>Create project</span>
                        </button>
                    </div>
                    <SitesWrapper>
                        <div className="site-list">
                            {projectsSlice.isLoading ? <Loader styleTwo center topPadding="30px" bottomPadding="30px" /> : !projectsSlice!.projects!.length ? <EmptyContent>
                                <div className="text-content">
                                    <FaLink />
                                    <span>You've not created any projects</span>
                                    <p>Project is a space that organises different Apps, domains and volumes for easy managment.</p>
                                </div>
                                <button onClick={() => navigate("/dashboard/projects/new")}>Create Project</button>
                            </EmptyContent> : projectsSlice!.projects!.map((item, idx) => {
                                return <ProjectCard to={"/dashboard/projects/"+item.id} status={"active"} key={"apps-"+idx}>
                                    <div className="img-wrapper">
                                        <RiArchiveDrawerFill />
                                    </div>
                                    <div className="col">
                                        <h4>{item?.name}</h4>
                                        <span>~ {dayjs(item?.date_created).format("MMM DD,  YYYY")}</span>
                                    </div>
                                    <div className="meta">
                                        <AiOutlineRight />
                                    </div>
                                </ProjectCard>
                            })}
                        </div>
                    </SitesWrapper>
                </SitesInfoWrapper>
            </Container>
        </Wrapper>
    )
}

export default ProjectsScreen