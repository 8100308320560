import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState: {state: ProfileSliceInterface} = {
  state: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    builds: "",
    requests: "",
    domains: "",
    sites: "",
    coutry: "",
    city: "",
    userType: "",
    profileImg: "",
    verified_email: null,
    verified_phone: null,
    balance: "0",
    savings: 0,
    totalBankTransfers: 0,
    totalWalletTransfers: 0,
    bankAmountTransfered: 0,
    walletAmountTransfered: 0,
    fetchedProfile: false,
    showVerifyModal: false,
  }
};

export const setProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<ProfileSliceInterface>) => {
      state.state = {...state.state, ...action.payload};
    },
  },
});

export const {setProfile} = setProfileSlice.actions;

export interface ProfileSliceInterface {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  builds?: string
  requests?: string
  domains?: string
  sites?: string
  coutry?: string
  city?: string
  verified_email?: number | null
  verified_phone?: number | null
  balance?: string
  savings?: number
  profileImg?: string,
  userType?: string,
  totalBankTransfers?: number
  totalWalletTransfers?: number
  bankAmountTransfered?: number
  walletAmountTransfered?: number
  fetchedProfile?: boolean,
  showVerifyModal?: boolean
}

export default setProfileSlice.reducer;
