import React, { useEffect, useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './views/styles/theme';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from './config/store';
import HomeScreen from './views/screens/home/HomeScreen';
import SigninScreen from './views/screens/auth/signin/SigninScreen';
import SignupScreen from './views/screens/auth/signup/SignupScreen';
import DashboardScreen from './views/screens/dashboard/DashboardScreen';
import MainLayout from './views/components/mainLayout/MainLayout';
import SitesScreen from './views/screens/dashboard/sites/SitesScreen';
import SiteInfoScreen from './views/screens/dashboard/sites/site_info/SiteInfoScreen';
import SettingsScreen from './views/screens/dashboard/settings/SettingsScreen';
import BuildsScreen from './views/screens/dashboard/builds/BuildsScreen';
import DomainsScreen from './views/screens/dashboard/domains/DomainsScreen';
import AuthScreen from './views/screens/auth/auth/AuthScreen';
import BillingScreen from './views/screens/dashboard/billing/BillingScreen';
import AppsScreen from './views/screens/dashboard/apps/AppsScreen';
import VolumesScreen from './views/screens/dashboard/volumes/VolumesScreen';
import PricingScreen from './views/screens/pricing/PricingScreen';
import ContactScreen from './views/screens/contact/ContactScreen';
import ProjectsScreen from './views/screens/dashboard/projects/ProjectsScreen';
import NewProjectsScreen from './views/screens/dashboard/projects/new_project/NewProjectsScreen';
import ProjectScreen from './views/screens/dashboard/projects/project/ProjectScreen';
import { useAppDispatch, useAppSelector } from './hooks/hooks';
import { setAppTheme } from './slices/themeSlice';

function App() {
  const appTheme = useAppSelector(state => state.theme.state);
  const dispatch = useAppDispatch()

  useEffect(() => {
    const app_theme = localStorage.getItem("app_theme")
    if (app_theme == "dark") {
        dispatch(setAppTheme("dark"))
    } else {
        dispatch(setAppTheme("light"))
    }
  }, [])

  return (
    
    <Router>
      <ThemeProvider theme={appTheme.mode == "dark" ? darkTheme : lightTheme}>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/pricing" element={<PricingScreen />} />
            <Route path="/contact-us" element={<ContactScreen />} />
            <Route path="/signin" element={<SigninScreen />} />
            <Route path="/signup" element={<SignupScreen />} />
            <Route path="/auth" element={<AuthScreen />} />
            <Route path="/dashboard" element={<MainLayout title="Overview"><DashboardScreen /></MainLayout>} />
            <Route path="/dashboard/projects" element={<MainLayout title="Projects"><ProjectsScreen /></MainLayout>} />
            <Route path="/dashboard/projects/:projectId" element={<MainLayout title="Project"><ProjectScreen /></MainLayout>} />
            <Route path="/dashboard/projects/new" element={<MainLayout title="Projects"><NewProjectsScreen /></MainLayout>} />
            <Route path="/dashboard/pebbles" element={<MainLayout title="Pebbles"><SitesScreen /></MainLayout>} />
            <Route path="/dashboard/apps" element={<MainLayout title="Apps"><AppsScreen /></MainLayout>} />
            <Route path="/dashboard/pebbles/:appId" element={<MainLayout title="App"><SiteInfoScreen /></MainLayout>} />
            <Route path="/dashboard/settings" element={<MainLayout title="Settings"><SettingsScreen /></MainLayout>} />
            <Route path="/dashboard/builds" element={<MainLayout title="Builds"><BuildsScreen /></MainLayout>} />
            <Route path="/dashboard/domains" element={<MainLayout title="Domains"><DomainsScreen /></MainLayout>} />
            <Route path="/dashboard/volumes" element={<MainLayout title="Volumes"><VolumesScreen /></MainLayout>} />
            <Route path="/dashboard/billing" element={<MainLayout title="Billing"><BillingScreen /></MainLayout>} />
          </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
