import Header from '../../components/header/Header'
import { Container, HeroContent, HeroWrapper, MainSection, Wrapper } from './styles'
import Footer from '../../components/footer/Footer'
import AxiosCall from '../../../utils/axios'
import { useState, useRef } from 'react'
import Message from '../../components/message/Message'
import Button from '../../components/button/Button'


const ContactScreen: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)

    const firstNameRef = useRef<HTMLInputElement>(null)
    const lastNameRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)
    const bodyRef = useRef<HTMLTextAreaElement>(null)

    const submitFeedback = async () => {
        try {
            if (!firstNameRef.current?.value || lastNameRef.current?.value|| !emailRef.current?.value || !bodyRef.current?.value) {
               return Message.error("Fields can't be empty");
            }
            setIsLoading(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/feedback/add",
                data: {
                    full_name: firstNameRef.current?.value + " " + lastNameRef.current?.value,
                    email: emailRef.current?.value,
                    body: bodyRef.current?.value,
                }
            });

            setIsLoading(false)
            if (res.status == 1) {
                Message.success("Feedback submitted");
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }


    return (
        <Wrapper>
            <Header />
            <HeroWrapper>
                <HeroContent>
                    <h6>Contact</h6>
                    <h2>Got any questions or enquiries?</h2>
                    <p>We always happy to help you out in the best way we polibly can. Kindly reach out.</p>
                </HeroContent>
            </HeroWrapper>
            <Container>
                <MainSection>
                    <div className="info-sec">
                        <div className="sec-1">
                            <h3>Contact us</h3>
                            <p>Email, call ,or complete the form to learn how coralscale can solve your  problem </p>

                            <ul>
                                <li><a href="#">info@coralscale.io</a></li>
                                <li><a href="#">+23484849393</a></li>
                            </ul>
                        </div>
                        <div className="sec-2">
                            <ul>
                                <li>
                                    <h5>Customer support </h5>
                                    <p>Our support team is avalable around the clock to address any corncens or queries you may have </p>
                                </li>
                                <li>
                                    <h5>Feedback & suggestions </h5>
                                    <p>We value your feedback and we are continuously working to improve coralscale. your input is crucial in shaping the future of coral scale</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <form className="form-sec" onSubmit={submitFeedback}>
                        <h3>Get in touch</h3>

                        <div className="row">
                            <div className="input-col">
                                <label htmlFor="">First Name</label>
                                <input ref={firstNameRef} type="text" placeholder="Enter first name" />
                            </div>

                            <div className="input-col">
                                <label htmlFor="">Last Name</label>
                                <input ref={lastNameRef} type="text" placeholder="Enter last name" />
                            </div>
                        </div>

                        <div className="input-col">
                            <label htmlFor="">Email</label>
                            <input ref={emailRef.current?.value} type="text" placeholder="Enter email" />
                        </div>
                        <div className="input-col">
                            <label htmlFor="">Phone Number</label>
                            <input type="text" placeholder="Enter phone number" />
                        </div>
                        <div className="input-col">
                            <label htmlFor="">Message</label>
                            <textarea ref={bodyRef} name="" id="" placeholder="Enter text"></textarea>
                        </div>

                        <Button onClick={() => {submitFeedback()}} isLoading={isLoading}>Submit</Button>
                    </form>
                </MainSection>
            </Container>
            <Footer />
        </Wrapper>
    )
}

export default ContactScreen