import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    padding-bottom: 20px;
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`;
export const MainRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (min-width: 1080px) {
        flex-direction: row;
    }
`;

export const SitesInfoWrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};
        padding: 10px 20px;

        h5 {
            color: ${props => props.theme.textColor};
            font-size: 14px;
            font-weight: 600;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.darkTextColor};
            border: none;
            width: 90px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.whiteTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.whiteTextColor};
                font-size: 15px;
            }
        }
    }
`;

export const SitesWrapper = styled.div`
    margin: 0px 0px;
    padding: 20px 20px;
    flex: 1;
    border-radius: 4px;

    .col {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        width: 100%;
        max-width: 320px;

        label {
            font-size: 13px;
            font-weight: 600;
            color: ${props => props.theme.textColor};
        }

        input {
            border: 1px solid #5d5b5b;
            height: 45px;
            padding: 0px 10px;
            font-size: 13px;
            border-radius: 4px;
            width: 100px;
            width: 100%;

            :disabled {
                background-color: #eee;
                border: 1px solid #ccc;
            }
        }

        span {
            padding: 4px 3px;
            border-radius: 4px;
            color: ${props => props.theme.whiteTextColor};
            font-size: 10px;
            font-weight: 500;
            border: none;
            color: ${props => props.theme.secondaryColor};
        }
    }
`;


export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 480px;
    margin: 0px 0px;

    .img-wraper {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0px auto 40px auto;
        /* border: 1px solid ${props => props.theme.lightPrimaryColor}; */
        background-color: ${props => props.theme.lightPrimaryColor};

        img {
            width: 80px;
            height: 80px;
            object-fit: cover;
        }
    }


    label {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: ${props => props.theme.labelColor};
        margin-bottom: 8px;
    }

    input, select, textarea {
        display: block;
        background-color: ${props => props.theme.containerBackground};
        height: 50px;
        font-size: 13px;
        font-weight: 300;
        color: ${props => props.theme.textColor};
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 20px;
        border: 1px solid rgba(145, 145, 145, 0.3);

        :disabled {
            background-color: ${props => props.theme.disabledInputColor};
        }
    }

    textarea {
        height: 200px;
        resize: none;
    }
`;

export const InputSec = styled.div`

`;
export const MembersList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Members = styled.div`
    display: flex;

    .status-indicator {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 15px;
        background-color: ${props => props.theme.lightBorderColor};
        align-self: center;

        svg {
            margin: auto;
            font-size: 18px;
            color: ${props => props.theme.darkTextColor};
        }
    }

    .info {
        flex: 1;
        h3 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 3px;
            color: ${props => props.theme.darkTextColor};
        }

        span {
            font-size: 12px;
            font-weight: 400;
            color: ${props => props.theme.textColor};
        }
    }

    button {
        padding: 0px 10px;
        height: 28px;
        background-color: transparent;
        border: 0.5px solid ${props => props.theme.textColor};
        border-radius: 4px;
        font-size: 11px;
        color: ${props => props.theme.textColor};
        cursor: pointer;
    }
`;


export const InviteMemberModal = styled.div`
    .head {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        .img-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px auto;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: ${props => props.theme.lightPrimaryColor};

            svg {
                font-size: 30px;
                color: ${props => props.theme.textPrimaryColor};
            }
        }

        h4 {
            color: ${props => props.theme.darkTextColor};
            font-size: 16px;
            margin-bottom: 0px;
        }
    }

    .search-member {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;

        .app-loader {
            position: absolute;
            align-self: center;
            top: 12px;
            right: 10px;
        }
    }

    .dropdown {
        margin-bottom: 20px;

        input {
            margin-bottom: 0px !important;
        }
    }

    .selected-member {
        display: flex;
        align-items: center;
        border: 1px solid ${props => props.theme.lightBorderColor};
        background-color: ${props => props.theme.backgroudColor};
        margin-bottom: 20px;
        border-radius: 4px;
        padding: 10px 15px;
        min-height: 45px;

        .info {
            flex: 1;
            h3 {
                font-size: 13px;
                color: ${props => props.theme.darkTextColor};
            }

            span {
                font-size: 11px;
                color: ${props => props.theme.textColor};
            }
        }

        svg {
            font-size: 17px;
            cursor: pointer;
            color: ${props => props.theme.textColor};
        }
    }

    .search-list {
        display: flex;
        flex-direction: column;
        border: 1px solid ${props => props.theme.lightBorderColor};
        margin-bottom: 20px;
        border-radius: 4px;

        li {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            border-bottom: 1px solid ${props => props.theme.lightBorderColor};
            cursor: pointer;
            :last-child {
                border-bottom: none;
            }

            .info {
                flex: 1;
                h3 {
                    font-size: 13px;
                    color: ${props => props.theme.darkTextColor};
                }

                span {
                    font-size: 11px;
                    color: ${props => props.theme.textColor};
                }
            }

            b {
                font-size: 11px;
                font-weight: 400;
                color: ${props => props.theme.textColor};
            }
        }
    }
`;

export const MemberInfoModal = styled.div`
    h4 {
        color: ${props => props.theme.darkTextColor};
        font-size: 16px;
        margin-bottom: 30px;
    }

    ul.info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        li {
            display: flex;
            justify-content: space-between;

            span {
                color: ${props => props.theme.textColor};
                font-size: 14px;
                font-weight: 400;

                :first-child {
                    color: ${props => props.theme.darkTextColor};
                    font-weight: 600;
                }
            }
        }

        li.col {
            flex-direction: column;
            gap: 10px;
        }
    }



    .button-row {
        display: flex;
        gap: 20px;
        margin-top: 20px;

        button {
            text-transform: uppercase;
            :first-child {
                border: 1px solid #c00;
                background-color: ${props => props.theme.containerBackground};
                color: #c00;
                font-weight: 400;
            }

            /* :last-child {
                background-color: #c00;
                font-weight: 400;
            } */
        }
    }
`;


export const SideBarContainer = styled.div`
    width: 100%;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};
        padding: 10px 20px;
        height: 55px;

        h5 {
            color: ${props => props.theme.textColor};
            font-size: 14px;
            font-weight: 600;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.darkTextColor};
            border: none;
            width: 100px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.whiteTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.whiteTextColor};
                font-size: 15px;
            }
        }
    }


    @media screen and (min-width: 880px) {
        width: 40%;
    }
`;