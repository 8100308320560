import { Link, useParams } from 'react-router-dom'
import { Container, HeadRow, SitesWrapper, Wrapper } from './styles'
import { FaGem, FaDatabase } from 'react-icons/fa'
import { IoSettingsOutline } from 'react-icons/io5'
import { MdStore } from 'react-icons/md'
import { TbWorldWww } from 'react-icons/tb'
import { IoIosApps } from 'react-icons/io'
import { useState } from 'react'
import PebblesTab from './pebbles_tab/PebblesTab'
import VolumesTab from './volumes_tab/VolumesTab'
import AppsTab from './apps_tab/AppsTab'
import DomainsTab from './domains_tab/DomainsTab'
import SettingsTab from './settings_tab/SettingsTab'
import { Project } from '../../../../../slices/projectSlice'
import Message from '../../../../components/message/Message'
import AxiosCall from '../../../../../utils/axios'

const ProjectScreen: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState("pebbles")

    return (
        <Wrapper>
            <HeadRow>
                <li>
                    <Link className={selectedTab == "pebbles" ? "active" : ""} to={"#"} onClick={() => {setSelectedTab("pebbles")}}><FaGem />Pebbles</Link>
                </li>
                <li>
                    <Link className={selectedTab == "volumes" ? "active" : ""} to="#" onClick={() => {setSelectedTab("volumes")}}><FaDatabase />Volumes</Link>
                </li>
                <li>
                    <Link className={selectedTab == "apps" ? "active" : ""} to="#" onClick={() => {setSelectedTab("apps")}}><IoIosApps />Apps</Link>
                </li>
                <li>
                    <Link className={selectedTab == "domains" ? "active" : ""} to="#" onClick={() => {setSelectedTab("domains")}}><TbWorldWww />Domains</Link>
                </li>
                <li>
                    <Link className={selectedTab == "settings" ? "active" : ""} to="#" onClick={() => {setSelectedTab("settings")}}><IoSettingsOutline />Settings</Link>
                </li>
            </HeadRow>
            <Container>
                {selectedTab == "pebbles" ? <PebblesTab />
                : selectedTab == "volumes"? <VolumesTab />
                : selectedTab == "apps"? <AppsTab />
                : selectedTab == "domains"? <DomainsTab />
                : <SettingsTab /> }
            </Container>
        </Wrapper>
    )
}

export default ProjectScreen