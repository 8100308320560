import { AiFillInfoCircle, AiOutlineDeploymentUnit } from 'react-icons/ai'
import Button from '../../../../../components/button/Button'
import { AddSiteModal, Container, DomainInfoModal, EmptyContent, SiteVolume, SitesWrapper, Wrapper } from './styles'
import { MdAddCircleOutline } from 'react-icons/md'
import { useEffect, useRef, useState } from 'react'
import PopupModal from '../../../../../components/popupModal/PopupModal'
import Message from '../../../../../components/message/Message'
import AxiosCall from '../../../../../../utils/axios'
import Loader from '../../../../../components/Loader/Loader'
import dayjs from 'dayjs'
import { FiLink } from 'react-icons/fi'
import { AppSliceInterface } from '../../../../../../slices/appSlice'
import { useAppSelector } from '../../../../../../hooks/hooks'
import { HiDatabase } from 'react-icons/hi'

const VolumesTab: React.FC = () => {
    const appSlice: AppSliceInterface  = useAppSelector(state => state.apps.state);
    const [addVolumeModal, setAddVolumeModal] = useState(false)
    const [volumeInfoModal, setVolumeInfoModal] = useState(false)
    const [isFetchingDomains, setIsFetchingDomains] = useState(true)
    const [selectedVolume, setSelectedVolume] = useState<any>(null)
    const [volumeList, setVolumeList] = useState<any[]>([])
    const [isAddingVolume, setIsAddingVolume] = useState(false)
    const [isDeletingVolume, setIsDeletingVolume] = useState(false)

    const storagePathRef = useRef<HTMLInputElement>(null)
    const appSlugRef = useRef<HTMLSelectElement>(null)

    const fetchVolumes = async () => {
        try {
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/volumes/fetch"
            });

            setIsFetchingDomains(false)

            if (res.status == 1) {
                setVolumeList(res.data ?? [])
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingDomains(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching domains")
        }
    }

    const addVolume = async () => {
        try {

            setIsAddingVolume(true)

            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/volume/add",
                data: {
                    path: storagePathRef?.current?.value,
                    app_slug: appSlugRef?.current?.value,
                }
            });

            if (res.status == 1) {
                setIsAddingVolume(false)
                setAddVolumeModal(false)
                fetchVolumes()
                Message.success("Volume created successfully")
            } else {
                setIsAddingVolume(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsAddingVolume(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    const deleteVolume = async () => {
        try {
            setIsDeletingVolume(true)

            const res: any = await AxiosCall({
                method: "DELETE",
                path: "/v1/volume/delete/",
                data: {
                    volume_path: selectedVolume?.path,
                    app_slug: selectedVolume?.app_slug,
                }
            });

            if (res.status == 1) {
                setIsDeletingVolume(false)
                setVolumeInfoModal(false)
                setSelectedVolume(null)
                const _volumeList = [...volumeList]
                const filteredDomain = _volumeList.filter((item: any) => item.domain_name == selectedVolume?.domain_name)
                const volumeIndex = _volumeList.indexOf(filteredDomain[0])
                _volumeList.splice(volumeIndex, 1)
                setVolumeList([..._volumeList!])

                Message.success("Volume deleted successfully")
            } else {
                setIsDeletingVolume(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            console.log(err);
            
            setIsDeletingVolume(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    useEffect(() => {
        fetchVolumes()
    }, [])

    return (
        <Wrapper>
            <Container>
                <div className="head">
                    <h5>Recent Volumes</h5>

                    <button className="add-site" onClick={() => setAddVolumeModal(true)}>
                        <MdAddCircleOutline />
                        <span>add volume</span>
                    </button>
                </div>
                <SitesWrapper>
                    <div className="site-list">
                        {isFetchingDomains ? <Loader styleTwo center topPadding="40px" /> : !volumeList.length ? <EmptyContent>
                        <div className="text-content">
                            <HiDatabase />
                            <span>You've not added any volumes</span>
                            <p>Volumes are persistent storage spaces withnin your app</p>
                        </div>
                        <button onClick={() => setAddVolumeModal(true)}>create volume</button>
                    </EmptyContent> : volumeList.map((item: any, idx) => {
                            return <SiteVolume status={item.status} key={"apps-"+idx} onClick={() => {
                                setSelectedVolume(item)
                                setVolumeInfoModal(true)
                            }}>
                            <div className="img-wrapper">
                                <FiLink />
                            </div>
                            <div className="col">
                                <h4>{item?.name}</h4>
                                <span>~ {item?.path}</span>
                            </div>
                            <div className="meta">
                                <span>{item?.status}</span>
                                <b>{dayjs(item?.date_created).format("MMM DD,  YYYY")}</b>
                            </div>
                        </SiteVolume>
                        })}
                    </div>
                </SitesWrapper>
            </Container>

            <PopupModal showPopup={addVolumeModal} closeModal={() => setAddVolumeModal(false)}>
                <AddSiteModal>
                    <h4>Create New Volume</h4>

                    <input ref={storagePathRef} type="text" placeholder="storage path (e.g. /root/storage)" />
                    <select ref={appSlugRef} name="application-type" id="">
                        <option value="none"> -- select app -- </option>
                        {appSlice.apps.map((item, idx) => {
                            return  <option key={idx} value={item.slug}>{item.name} - {item.stack}</option>
                        })}
                    </select>
                    <Button onClick={() => addVolume()} isLoading={isAddingVolume}>CREATE</Button>
                </AddSiteModal>
            </PopupModal>

            <PopupModal closeModal={() => setVolumeInfoModal(false)}  showPopup={volumeInfoModal}>
                <DomainInfoModal>
                    <h4>Volume Infomation</h4>

                    <ul className="info">
                        <li>
                            <span>App: </span>
                            <span>{selectedVolume?.name} ({selectedVolume?.stack})</span>
                        </li>
                        <li>
                            <span>Storage Path: </span>
                            <span>~{selectedVolume?.path}</span>
                        </li>
                        <li>
                            <span>Status: </span>
                            <span>{selectedVolume?.status}</span>
                        </li>
                    </ul>

                    <div className="button-row">
                        <button onClick={() => {setVolumeInfoModal(false)}}>close</button>
                        <Button onClick={() => deleteVolume()} isLoading={isDeletingVolume}>Delete</Button>
                    </div>
                </DomainInfoModal>
            </PopupModal>
        </Wrapper>
    )
}

export default VolumesTab