import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`;
export const Container = styled.div`
    /* padding: 0px 6%;
    @media screen and (min-width: 880px) {
        padding: 0px 4%;
    } */
    width: 100%;
`;
export const MainRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (min-width: 1080px) {
        flex-direction: row;
    }
`;

export const SitesInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    flex: 1;
    border-radius: 4px;
    margin: 20px 0px;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        h5 {
            color: ${props => props.theme.textColor};
            font-size: 14px;
            font-weight: 600;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.darkTextColor};
            border: none;
            width: 120px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.whiteTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.whiteTextColor};
                font-size: 15px;
            }
        }
    }
`;

export const SitesWrapper = styled.div`
    margin: 0px 0px;
    padding: 10px 20px;
    flex: 1;
`;

export const EmptyContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
    gap: 40px;
    /* background-color: #ceceec66; */
    border-radius: 8px;

    button {
        background-color: ${props => props.theme.primaryButtonColor};
        color: ${props => props.theme.primaryButtonTextColor};
        border: none;
        border-radius: 4px;
        height: 45px;
        width: 150px;
        text-transform: capitalize;
        cursor: pointer;
    }

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 8px;

        svg {
            width: 30px;
            height: 30px;
            color: ${props => props.theme.darkTextColor};
        }

        span {
            color: ${props => props.theme.darkTextColor};
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }

        p {
            color: ${props => props.theme.darkTextColor};
            font-size: 16px;
            font-weight: 400;
            text-align: center;
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;

export const ProjectCard = styled(Link)<{status: string}>`
    display: flex;
    align-items: center;
    padding: 10px 0px;
    gap: 20px;
    cursor: pointer;

    .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        width: 50px;
        height: 50px;
        background-color: ${props => props.theme.lightPrimaryColor};

        svg {
            width: 30px;
            height: 30px;
            color: ${props => props.theme.textPrimaryColor};
        }
    }
    .col {
        flex: 1;
        h4 {
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 4px;
            color: ${props => props.theme.darkTextColor};
            /* text-transform: capitalize; */
        }
        span {
            columns: ${props => props.theme.textColor};
            font-size: 12px;
            font-weight: 400;
            color: ${props => props.theme.darkTextColor};
        }
    }

    .meta {
        color: ${props => props.theme.textColor};
    }
`;


export const AddSiteModal = styled.div`
    
`;

export const DomainInfoModal = styled.div`
    ul.info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        li {
            display: flex;
            justify-content: space-between;

            span {
                color: ${props => props.theme.textColor};
                font-size: 14px;
                font-weight: 400;

                :first-child {
                    color: ${props => props.theme.darkTextColor};
                    font-weight: 600;
                }
            }
        }
    }

    .installation {
        margin-top: 20px;

        .guide {
            display: flex;
            flex-direction: column;
            gap: 6px;
            border: 1px dashed #ceceec;
            padding: 10px;
            border-radius: 6px;

            b {
                font-size: 14px;
                font-weight: 600;
                color: #222
            }

            span {
                font-size: 14px;
                font-weight: 400;
                color: ${props => props.theme.textColor};
            }
        }

        .row {
            background-color: #ceceec66;
            padding: 10px 20px;
            border-radius: 6px;
            margin: 10px 0px;

            ul {
                display: flex;
                justify-content: space-between;
                gap: 20px;

                li {
                    font-size: 14px;
                    font-weight: 400;
                    color: ${props => props.theme.darkTextColor};
                }

                :first-child {
                    margin-bottom: 10px;
                    li {
                        font-size: 14px;
                        font-weight: 600;
                        color: ${props => props.theme.darkTextColor};
                    }
                }
            }
        }
    }

    .button-row {
        display: flex;
        gap: 20px;
        margin-top: 10px;

        button {
            text-transform: uppercase;
            :first-child {
                border: 1px solid ${props => props.theme.primaryColor};
                background-color: ${props => props.theme.containerBackground};
                color: ${props => props.theme.textPrimaryColor};
            }
        }
    }
`;