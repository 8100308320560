import React, { useEffect, useRef, useState } from 'react';
import { DropdownSelect } from './styles';
import { IoIosArrowDown } from 'react-icons/io';


const Dropdown: React.FC<{options: any[], placeHolder?: string, onChange?: (value: any) => void, type?: string}> = ({options, placeHolder, onChange}) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(placeHolder ?? "Select")

    const [_leftOffset, setLeftOffset] = useState(0)
    const [_topOffset, setTopOffset] = useState(0)

    const dropdownRef = useRef<HTMLDivElement>(null)

    const  openDropdown = (e: any) => {
        e.preventDefault()
        const _leftOffset = window.pageXOffset + dropdownRef.current?.getBoundingClientRect()!.left!;
        const _topOffset = window.pageYOffset + dropdownRef.current?.getBoundingClientRect()!.top! + dropdownRef.current?.clientHeight!;
        
        setTopOffset(_topOffset)
        setLeftOffset(_leftOffset);
        setShowDropdown(!showDropdown)

        // window.onscroll = () => {
        //     setShowDropdown(false)
        // }
    }


    useEffect(() => {
        setSelectedOption(placeHolder!);
    }, [placeHolder])

    return (
        <DropdownSelect ref={dropdownRef}  selected={showDropdown} onClick={(e) => openDropdown(e)} className="dropdown">
            <span className="selected">{selectedOption}</span>
            <IoIosArrowDown />

            <div className="options-wrapper">
                {options.map((item, idx) => {
                    return  <span key={"card-options-"+idx} className={`options ${selectedOption == item ? " active-option" : ""}`} onClick={() => {
                        setSelectedOption(item);
                        if (!onChange) return
                        onChange!(item)
                    }}>{item}</span>
                })}
            </div>
        </DropdownSelect>
    )
}
 
export default Dropdown;