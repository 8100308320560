import { AddAppModal, AvailableApps, Container, EmptyContent, InstalledApps, MarketApp, SitesWrapper, TabItem, TabWrapper, Wrapper } from './styles'
import { useEffect, useRef, useState } from 'react'
import Loader from '../../../../../components/Loader/Loader'
import { AppSliceInterface } from '../../../../../../slices/appSlice'
import { useAppSelector } from '../../../../../../hooks/hooks'
import { FiDownload } from 'react-icons/fi'
import PopupModal from '../../../../../components/popupModal/PopupModal'
import Message from '../../../../../components/message/Message'
import AxiosCall from '../../../../../../utils/axios'
import { MdStore } from 'react-icons/md'

const AppsTab: React.FC = () => {
    const appSlice: AppSliceInterface  = useAppSelector(state => state.apps.state);
    const [isInstallingApp, setIsInstallingApp] = useState(false)
    const [showInstallModal, setShowInstallModal] = useState(false)
    const [isFetchingApps, setIsFetchingApps] = useState(false)
    const [marketApps, setMarketApps] = useState<any[]>([])
    const [isFetchingInstalledApps, setIsFetchingInstalledApps] = useState(false)
    const [installedApps, setInstalledApps] = useState<any[]>([])
    const [selectedApp, setSelectedApp] = useState<any>(null)

    const [selectedTab, setSelectedTab] = useState("available")
   
    const appSlugRef = useRef<HTMLSelectElement>(null)
    const dbNameRef = useRef<HTMLInputElement>(null)
    const dbUserRef = useRef<HTMLInputElement>(null)
    const dbPassRef = useRef<HTMLInputElement>(null)

    const fetchMarketApps = async () => {
        try {
            setIsFetchingApps(true)
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/market/apps/fetch",
            });
            setIsFetchingApps(false)
            if (res.status == 1) {
                setMarketApps(res.data)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingApps(false)
            Message.error(err?.response.data.message)
        }
    }

    const fetchInstalledApps = async () => {
        try {
            setIsFetchingInstalledApps(true)
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/market/installed/apps/fetch",
            });
            setIsFetchingInstalledApps(false)
            if (res.status == 1) {
                setMarketApps(res.data)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingInstalledApps(false)
            Message.error(err?.response.data.message)
        }
    }

    const installApp = async () => {
        try {
            setIsInstallingApp(true)
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/market/app/install",
                data: {
                    market_id: selectedApp?.id,
                    app_slug: appSlugRef.current?.value,
                    db_name: dbNameRef.current?.value,
                    db_user: dbUserRef.current?.value,
                    db_pass: dbPassRef.current?.value
                }
            });
            setIsInstallingApp(false)
            if (res.status == 1) {
                setMarketApps(res.data)
                Message.success("App installed successfully")
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsInstallingApp(false)
            Message.error(err?.response.data.message)
        }
    }

    useEffect(() => {
        if (selectedTab == "available") {
            fetchMarketApps()
        }
    }, [selectedTab])


    useEffect(() => {
        if (selectedTab == "installed") {
            fetchInstalledApps()
        }
    }, [selectedTab])

    return (
        <Wrapper>
            <Container>
                <TabWrapper>
                    <TabItem isActive={selectedTab == "available"} onClick={() => setSelectedTab("available")}>Available Apps</TabItem>
                    <TabItem isActive={selectedTab == "installed"} onClick={() => setSelectedTab("installed")}>Installed Apps</TabItem>
                </TabWrapper>

                <SitesWrapper>
                    {selectedTab == "available" ? <AvailableApps>
                        {isFetchingApps ? <Loader styleTwo center topPadding="40px" /> : marketApps.map((item: any, idx) => {
                        return <MarketApp status={item.status} key={"apps-"+idx}>
                            <div className="head">
                                <div className="img-wrapper">
                                    <img src={"/assets/img/"+item?.name+"-img.png"} alt="" />
                                </div>
                                <div className="title">
                                    <h3>{item?.name}</h3>
                                    <span>Database</span>
                                </div>
                            </div>
                            <p>{item?.description}</p>
                            <div className="meta">
                                <button disabled={item.status == "unavailable"} onClick={() => {
                                    if (item.name == "mysql") {
                                        setSelectedApp(item);
                                        setShowInstallModal(true)
                                    }
                                }}>
                                    <span>{item.status == "unavailable" ? "coming soon" : "Install"}</span>
                                    <FiDownload />
                                </button>
                            </div>
                        </MarketApp>
                        })}
                    </AvailableApps> : <InstalledApps>
                        {isFetchingInstalledApps ? <Loader styleTwo center topPadding="40px" /> : !installedApps.length ? <EmptyContent>
                        <div className="text-content">
                            <MdStore />
                            <span>You've not installed any app</span>
                            <p>An app is a software that extends the capabilities of your pebble</p>
                        </div>
                        <button onClick={() => setSelectedTab("available")}>
                            <span>Install App</span>
                            <FiDownload />
                        </button>
                    </EmptyContent> : installedApps.map((item: any, idx) => {
                        return <MarketApp status={item.status} key={"apps-"+idx}>
                            <div className="head">
                                <div className="img-wrapper">
                                    <img src={"/assets/img/"+item?.name+"-img.png"} alt="" />
                                </div>
                                <div className="title">
                                    <h3>{item?.name}</h3>
                                    <span>Database</span>
                                </div>
                            </div>
                            <p>{item?.description}</p>
                            <div className="meta">
                                <button onClick={() => {
                                    if (item.name == "mysql") {
                                        setSelectedApp(item);
                                        setShowInstallModal(true)
                                    }
                                }}>
                                    <span>Install</span>
                                    <FiDownload />
                                </button>
                            </div>
                        </MarketApp>
                        })}
                    </InstalledApps>}
                </SitesWrapper>
            </Container>

            <PopupModal showPopup={showInstallModal} closeModal={() => setShowInstallModal(false)}>
                <AddAppModal>
                    <div className="head">
                        <div className="img-wrapper">
                            <img src="/assets/img/mysql-img.png" alt="" />
                        </div>
                        <div className="title">
                            <h3>MySql</h3>
                            <span>Database</span>
                        </div>
                    </div>

                    <label htmlFor="">Database Name</label>
                    <input ref={dbNameRef} type="text" placeholder='Enter database Name' />

                    <label htmlFor="">User name</label>
                    <input ref={dbUserRef} type="text" placeholder='Enter user name' />

                    <label htmlFor="">Password</label>
                    <input ref={dbPassRef} type="text" placeholder='Database Name' />

                    <label htmlFor="">Host</label>
                    <input type="text" disabled value='127.0.0.1' />

                    <label htmlFor="">Port</label>
                    <input type="text" disabled value="3306" />

                    <select ref={appSlugRef} name="application-type" id="">
                        <option value="none"> -- select pebble -- </option>
                        {appSlice.apps.map((item, idx) => {
                            return  <option key={idx} value={item.slug}>{item.name} - {item.stack}</option>
                        })}
                    </select>

                    <button onClick={installApp}>{isInstallingApp ? <Loader /> : "Install"}</button>
                </AddAppModal>
            </PopupModal>
        </Wrapper>
    )
}

export default AppsTab