import React, { useEffect, useState } from 'react'
import { Content, EmailVerify, MainLayoutWrapper, SwitchTeamWrapper } from './styles'
import DashboardHeader from '../dashboardHeader/DashboardHeader';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import Sidebar from '../sidebar/Sidebar';
import { fetchProfileAction } from '../../../actions/profileAction';
import { getApps } from '../../../actions/appActions';
import { useLocation } from 'react-router-dom';
import { fetchProjects, fetchTeams } from '../../../actions/indexActions';
import Loader from '../Loader/Loader';
import { setProfile } from '../../../slices/profileSlice';
import VerifyEmailModal from '../../screens/auth/verify_email/VerifyEmailModal';

const MainLayout: React.FC<{title: String, children: React.ReactNode}> = ({title, children}) => {
    // const { height, width } = useWindowDimensions()
    const { pathname } = useLocation();
    const teamSlice = useAppSelector(state => state.teams.state)
    const userProfile = useAppSelector(state => state.profile.state);
    const appSlice = useAppSelector(state => state.apps.state);
    const dispatch = useAppDispatch()
    const [renderLayout, setRenderLayout] = useState(false)
    

    useEffect(() => {
        fetchProjects(dispatch)
        fetchTeams(dispatch)
    }, [])

    const fetchProfile = async () => {
        if (userProfile.fetchedProfile) {
            return;
        }
        fetchProfileAction(dispatch);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])
   

    useEffect(() => {
        fetchProfile()

        if (!appSlice.fetchedSites) {
            getApps(dispatch)
        }
    }, [])

    useEffect(() => {
        if (!teamSlice.defaultTeam?.id) return
        setRenderLayout(true)
    }, [!teamSlice.defaultTeam?.id])

    return (
        <>
            <MainLayoutWrapper>
                <Sidebar />
                <Content>
                    <DashboardHeader title={title} />

                    {userProfile.verified_email == 0 ? <EmailVerify>
                        <p>Some features have been disabled. Kindly verify your email.</p>
                        <button onClick={() => dispatch(setProfile({showVerifyModal: true}))}>verify</button>
                    </EmailVerify> : <></>}

                    {renderLayout ? children : <></>}
                </Content>
            </MainLayoutWrapper>

            <VerifyEmailModal />

            {teamSlice.isSwitchingTeam && <SwitchTeamWrapper>
                <Loader styleTwo />
                <div className="content">
                    <img src="/assets/img/logo-alt.png" alt="site logo"/>
                    <span>Switching Teams</span>
                </div>
            </SwitchTeamWrapper>}
        </>
    )
}

export default MainLayout