import { BottomSection, Container, Nav, SocialItem, TopSection, Wrapper } from './styles'
import { FaLinkedinIn } from 'react-icons/fa'
import { AiOutlineInstagram, AiOutlineTwitter, AiOutlineYoutube } from 'react-icons/ai'

const Footer: React.FC = () => (
    <Wrapper>
        <Container>
            <TopSection>
                <Nav>
                    <ul>
                        <h5>Company</h5>
                        <li><a href="#">About</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul>
                    <ul>
                        <h5>Resources</h5>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Get started</a></li>
                    </ul>
                    <ul>
                        <h5>Legal</h5>
                        <li><a href="#">Terms of service</a></li>
                        <li><a href="#">Privacy</a></li>
                        <li><a href="#">Disclaimer</a></li>
                    </ul>
                </Nav>
            </TopSection>
            <BottomSection>
                <div className="credits">
                    <img src="/assets/img/logo-alt.png" alt="" />
                    <span>Coralscale &copy; 2023</span>
                </div>
                <div className="social">
                    <SocialItem target="_blank" href="#">
                        <AiOutlineYoutube />
                    </SocialItem>
                    <SocialItem target="_blank" href="#">
                        <FaLinkedinIn />
                    </SocialItem>
                    <SocialItem target="_blank" href="#">
                        <AiOutlineInstagram />
                    </SocialItem>
                    <SocialItem target="_blank" href="#">
                        <AiOutlineTwitter />
                    </SocialItem>
                </div>
            </BottomSection>
        </Container>
    </Wrapper>
)

export default Footer