import Header from '../../components/header/Header'
import { Container, HeroContent, HeroWrapper, MainSection, SubscriptionCard, SubscriptionWrapper, Wrapper } from './styles'
import Footer from '../../components/footer/Footer'
import { app_subscriptions } from '../../../data'
import { useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { updateCreatAppState } from '../../../slices/createAppSlice'
import { IoMdCheckmark } from 'react-icons/io'
import { FiArrowRight } from 'react-icons/fi'
import { useNavigate } from 'react-router'


const PricingScreen: React.FC = () => {
    const creatApp = useAppSelector(state => state.createApp.state);
    const dispatch = useAppDispatch()
    const planButtonRefs = useRef<HTMLButtonElement[]>([])
    const planContinueTextRefs = useRef<HTMLSpanElement[]>([])
    const planArrowTextRefs = useRef<any[]>([])
    const navigate = useNavigate()

    return (
        <Wrapper>
            <Header />
            <HeroWrapper>
                <HeroContent>
                    <h6>Pricing</h6>
                    <h2>Flexible plans for you</h2>

                    <p>Different plans have different resources allocated to them. Kindly select the plan that suits your business requirements</p>
                </HeroContent>
            </HeroWrapper>
            <Container>
                <MainSection>
                    <SubscriptionWrapper>
                        {app_subscriptions.map((item, idx: number) => {
                            return <SubscriptionCard isSelected={creatApp.selectedPlan?.planId == item.planId} key={idx} onClick={(e) => {
                                if (e.target != planButtonRefs.current[idx] && e.target != planContinueTextRefs.current[idx] && e.target != planArrowTextRefs.current[idx]) {
                                    dispatch(updateCreatAppState({...creatApp, selectedPlan: item, selectedPlanId: item.planId}))
                                }
                                }} onMouseEnter={(e) => {
                                    if (e.target != planButtonRefs.current[idx] && e.target != planContinueTextRefs.current[idx] && e.target != planArrowTextRefs.current[idx]) {
                                        dispatch(updateCreatAppState({...creatApp, selectedPlan: item, selectedPlanId: item.planId}))
                                    }
                                }}>
                                <h3>{item.name}</h3>
                                <div className="billing">
                                    <span className="price">${item.price}</span>
                                    <span className="interval"> / month</span>
                                </div>
                                <p className="notice">{item.description}</p>

                                <button ref={(ref) => planButtonRefs.current.push(ref!)} onClick={() => {
                                    if (creatApp.selectedPlan?.planId != item.planId) return;

                                    navigate("/signup")
                                }}>
                                    <span ref={(ref) => planContinueTextRefs.current.push(ref!)}>Continue</span>
                                </button>

                                <ul>
                                    {item.perks.map((perks, _idx) => {
                                        return <li key={"perk" + _idx}>
                                            <span className="check-box"><IoMdCheckmark /></span>
                                            <span className="title">{perks}</span>
                                        </li>
                                    })}
                                </ul>
                            </SubscriptionCard>
                        })}
                    </SubscriptionWrapper>
                </MainSection>
            </Container>
            <Footer />
        </Wrapper>
    )
}

export default PricingScreen