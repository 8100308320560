import { useState, useEffect } from "react";
import { usePaystackPayment } from "react-paystack";
import { useAppSelector } from "../../../../../hooks/hooks";
import usePaymentModel from "../usePaymentModel";
import useWalletPaymentModel from "../useWalletPaymentModel";


const PaystackModal: React.FC<{amount: number, reference: string, transType: string}> = ({amount = 0, reference = "", transType}) => {
    const walletPaymentModel = useWalletPaymentModel()
    const paymentModel = usePaymentModel()
    const [isLoading, setIsLoading] = useState(false)
    const userProfile = useAppSelector(state => state.profile.state);
    const config = {
        reference: reference,
        email: userProfile.email,
        amount: amount * 100,
        publicKey: process.env.REACT_APP_PAYSTACK_PK_KEY!,
    }
    const initializePayment = usePaystackPayment(config);

    const startPayment = () => {
        if (transType == "pebble_payment") {
            paymentModel.handlePaystackPaymet(initializePayment)
        }

        if (transType == "wallet_topup") {
            walletPaymentModel.handlePaystackPaymet(initializePayment)
        }
    }

    useEffect(() => {
        console.log('====================================');
        console.log(config);
        console.log('====================================');
        startPayment()
        setTimeout(() => {
            setIsLoading(true)
        }, 1000);
    }, [isLoading])

    return <button style={{display: "none"}}></button>
}

export default PaystackModal