const formatNumber = (num: number) => {
    return { 
        toPrice: () => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
        pad: (size: number) => {
            if(num === 0) return '00';
            let ret = num.toString();
            while(ret.length < size) ret = '0' + ret;
            return ret;
        },
        toShortForm: () => {
            if (num < 1000) {
                return num
            } else if (num >= 1000) {
                const deno = 1000
                const _formated = (num / deno).toFixed(1)

                return _formated + "K"
            } else if (num >= 1000000) {
                const deno = 1000000
                const _formated = (num / deno).toFixed(1)

                return _formated + "M"

            } else {
                const deno = 1000000000
                const _formated = (num / deno).toFixed(1)

                return _formated + "B"
            }
        },
        toSize: () => {
            if (num < 1000) {
                return num + "MB"
            } else if (num >= 1000) {
                const deno = 1000
                const _formated = (num / deno).toFixed(1)

                return _formated + "GB"
            } else if (num >= 1000000) {
                const deno = 1000000
                const _formated = (num / deno).toFixed(1)

                return _formated + "TB"

            } else {
                const deno = 1000000000
                const _formated = (num / deno).toFixed(1)

                return _formated + "PB"
            }
        },
    }
}

export default formatNumber;