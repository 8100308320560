import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`;

export const Container = styled.div`
    width: 100%;
`;

export const BalanceWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;

    @media screen and (min-width: 1080px) {
        flex-direction: row;
        /* gap: 4%; */
    }
`;

export const BalanceContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    /* border: 1px solid ${props => props.theme.lightBorderColor}; */
    padding: 0px 0px;
    border-radius: 4px;

    .head {
        display: flex;
        align-items: center;
        padding: 20px 20px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        span {
            font-size: 14px;
            font-weight: 600;
            color: ${props => props.theme.darkTextColor};
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px;

        span.text {
            color: #090;
            font-size: 16px;
            font-weight: 500;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            padding: 10px 10px;
            border-radius: 4px;
            border: none;
            font-size: 13px;
            cursor: pointer;
            background-color: ${props => props.theme.primaryButtonColor};
            /* background-color: ${props => props.theme.darkTextColor}; */

            span {
                font-size: 13px;
                font-weight: 500;
                color: ${props => props.theme.primaryButtonTextColor} !important;
            }

            svg {
                font-size: 15px;
                color: ${props => props.theme.primaryButtonTextColor};
            }
        }
    }

    :last-child {
            .content {
                span {
                    :first-child {
                        font-size: 15px;
                        font-weight: 400;
                        color: ${props => props.theme.darkTextColor};
                    }

                    :last-child {
                        font-size: 16px;
                        font-weight: 500;
                        color: ${props => props.theme.darkTextColor};
                    }
                }
            }
        }

    @media screen and (min-width: 1080px) {
        width: calc(50% - 10px);
    }
`;


export const BillingWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    flex-direction: column;

    @media screen and (min-width: 1080px) {
        flex-direction: row;
        /* gap: 4%; */
    }
`;

export const BillingContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    /* border: 1px solid ${props => props.theme.lightBorderColor}; */
    padding: 0px 0px;
    border-radius: 4px;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        span {
            font-size: 14px;
            font-weight: 600;
            color: ${props => props.theme.darkTextColor};
        }

        button {
            font-size: 14px;
            padding: 6px 10px;
            border-radius: 3px;
            background-color: transparent;
            color: ${props => props.theme.textPrimaryColor};
            border: none;
        }
    }

    .billing-content {
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
        
        ul {
            :first-child {
                display: flex;
                margin-bottom: 10px;
                li {
                    flex: 1;
                    font-size: 14px;
                    font-weight: 400;
                    color: ${props => props.theme.lightTextColor};
                }
            }

            :last-child {
                display: flex;
                flex-direction: column;
                gap: 10px;

                li {
                    flex: 1;
                    font-size: 14px;
                    font-weight: 400;
                    color: ${props => props.theme.lightTextColor};
                }
            }
        }
    }

    .payment-content {
        display: flex;
        flex-direction: column;

        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px 20px;

            li {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 12px 20px;
                border-bottom: 1px solid ${props => props.theme.lightBorderColor};
                div.main {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    gap: 10px;

                    .icon-box {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            font-size: 16px;
                            color: ${props => props.theme.darkTextColor};
                        }
                    }

                    h4 {
                        white-space: nowrap;
                        font-size: 12px;
                        font-weight: 400;
                        color: ${props => props.theme.darkTextColor};
                        @media screen and (min-width: 480px) {
                            font-size: 14px;
                        }
                    }

                    span {
                        font-size: 12px;
                        font-weight: 400;
                        color: ${props => props.theme.lightTextColor};
                        white-space: nowrap;
                        @media screen and (min-width: 480px) {
                            font-size: 13px;
                        }
                    }

                    b {
                        font-size: 12px;
                        font-weight: 300;
                        padding: 4px 4px;
                        border-radius: 4px;
                        border: 0.5px solid #222;
                    }
                }
                div.actions {
                    position: relative;
                    button {
                        font-size: 18px;
                        padding: 0px;
                        border: none;
                        background-color: transparent;
                        cursor: pointer;
                    }

                    .actions-container {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        right: 100%;
                        background-color: ${props => props.theme.darkTextColor};
                        border: 1px solid ${props => props.theme.primaryColor};
                        border-radius: 2px;
                        padding: 0px 0px;

                        span {
                            display: block;
                            white-space: nowrap;
                            padding: 10px 15px;
                            color: ${props => props.theme.whiteTextColor};
                            font-size: 13px;
                            cursor: pointer;
                            border-bottom: 1px solid #5d5b5b;

                            :last-child {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1080px) {
        width: calc(50% - 10px);
    }
`;


export const MainRow = styled.div`
    display: flex;
    flex-direction: column;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        /* border-bottom: 1px solid ${props => props.theme.lightBorderColor}; */

        h5 {
            color: ${props => props.theme.darkTextColor};
            font-size: 15px;
            font-weight: 600;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.darkTextColor};
            border: none;
            width: 90px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.whiteTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.whiteTextColor};
                font-size: 15px;
            }
        }
    }
`;

export const SitesWrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 20px;

    @media screen and (min-width: 880px) {
        
    }
`;



export const EmptyContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
    gap: 40px;
    /* background-color: #ceceec66; */
    border-radius: 8px;

    button {
        background-color: ${props => props.theme.primaryButtonColor};
        color: ${props => props.theme.primaryButtonTextColor};
        border: none;
        border-radius: 4px;
        height: 45px;
        width: 150px;
        text-transform: capitalize;
        cursor: pointer;
    }

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 8px;

        svg {
            width: 30px;
            height: 30px;
            color: ${props => props.theme.darkTextColor};
        }

        span {
            color: ${props => props.theme.darkTextColor};
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }

        p {
            color: ${props => props.theme.darkTextColor};
            font-size: 16px;
            font-weight: 400;
            text-align: center;
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;


export const EmptyCardContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
    gap: 30px;
    /* background-color: #ceceec66; */
    border-radius: 8px;

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* gap: 15px; */
        border-radius: 8px;

        svg {
            font-size: 30px;
            color: ${props => props.theme.darkTextColor};
            margin-bottom: 15px;
        }

        span {
            color: ${props => props.theme.darkTextColor};
            font-size: 15px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 8px;
        }

        p {
            color: ${props => props.theme.darkTextColor};
            font-size: 15px;
            font-weight: 400;
            text-align: center;
        }
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background-color: ${props => props.theme.primaryButtonColor};
        border: none;
        color: ${props => props.theme.primaryButtonTextColor};
        border-radius: 4px;
        height: 38px;
        width: 100px;
        text-transform: capitalize;
        cursor: pointer;
        margin-top: 15px;

        svg {
            font-size: 20px !important;
            color: ${props => props.theme.textPrimaryColor};
            margin-bottom: 0px !important;
            color: #fff !important;
        }

        span {
            font-size: 12px !important;
            margin-bottom: 0px !important;
            color: #fff !important;
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;

export const PaymentModal = styled.div`
    font-size: 14px;
    white-space: nowrap;
    overflow: auto;

    h4 {
        text-align: left;
        color: ${props => props.theme.darkTextColor};
        font-size: 15px;
    }

    .payment-gateways {
        ul {
            display: flex;
            flex-direction: column;
            gap: 20px;

            li {
                display: flex;
                align-items: center;
                cursor: pointer;

                .select-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    border: 2px solid #cecece;

                    ::before {
                        content: "";
                        display: block;
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        background-color: ${props => props.theme.containerBackground};
                    }
                }

                .icon-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    margin-right: 15px;
                    border-radius: 8px;
                    border: 1px solid #ccc;
                    background-color: ${props => props.theme.lightPrimaryColor};

                    svg {
                        font-size: 18px;
                        color: ${props => props.theme.textPrimaryColor};
                    }
                }

                .img-box {
                    width: 26px;
                    height: 26px;
                    margin-right: 12px;
                    border-radius: 8px;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                    }
                }

                span {
                    font-size: 15px;
                    font-weight: 500;
                    flex: 1;
                }
            }

            li.selected {
                .select-box {
                    border: 2px solid ${props => props.theme.lightPrimaryColor};

                    ::before {
                        background-color: ${props => props.theme.primaryButtonColor};
                    }
                }
            }

            li.stripe {
                opacity: 0.5;
                span {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    ::after {
                        content: "coming soon";
                        padding: 4px 4px;
                        line-height: unset !important;
                        color: ${props => props.theme.darkTextColor};
                        font-size: 12px;
                        border-radius: 4px;
                        background-color: rgba(77, 64, 64, 0.25);

                    }
                }
            }
        }
    }

    .payment-cards {
        display: flex;
        flex-direction: column;

        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;

            li {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 12px 20px;
                border: 1px solid ${props => props.theme.lightBorderColor};
                div.main {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    gap: 10px;

                    .icon-box {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            font-size: 16px;
                            color: ${props => props.theme.darkTextColor};
                        }
                    }

                    h4 {
                        white-space: nowrap;
                        font-size: 12px;
                        font-weight: 400;
                        color: ${props => props.theme.darkTextColor};
                        margin-bottom: 0px;
                        @media screen and (min-width: 480px) {
                            font-size: 14px;
                        }
                    }
                }
                
                .select-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 2px solid #cecece;

                    ::before {
                        content: "";
                        display: block;
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        background-color: ${props => props.theme.containerBackground};
                    }
                }
            }
            li.selected {
                .select-box {
                    border: 2px solid ${props => props.theme.lightPrimaryColor};

                    ::before {
                        background-color: ${props => props.theme.primaryButtonColor};
                    }
                }
            }
        }
    }

    button {
        margin-top: 20px;

        :disabled {
            background-color: ${props => props.theme.lightPrimaryColor};
            cursor: not-allowed;
        }
    }
`;



export const TableWrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
    border-radius: 4px;
    overflow: hidden;
    border: solid 1px rgba(0,0,0,.05);
    box-shadow: 0 0 1px rgba(0,0,0,.05);
`;

export const TableHead = styled.ul`
    display: none;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid ${props => props.theme.lightBorderColor};
    color: ${props => props.theme.darkTextColor};
    font-size: 14px;
    font-weight: 600;
    li {
        flex: 1;
    }

    @media screen and (min-width: 880px) {
        display: flex;
        justify-content: space-around;
    }
`;

export const TableBody = styled.div<{isEmpty?: boolean}>`
    display: ${props => props.isEmpty ? "flex" : "block"};
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

export const TableItems = styled.div<{status: String, type: String, isActive?: boolean}>`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 20px 13px 20px;
    border-top: 1px solid #eee;
    background-color: ${props => props.isActive ? props.theme.primaryColor : "transparent"};
    cursor: pointer;
    :first-child {
        border: none;
    }
    :hover {
        background: #161A1C;

        li {
            color: #fff !important;
        }
    }
    :nth-child(2n) {
        /* background-color: #fafafa; */
    }
    @media screen and (min-width: 880px) {
        border: none;
    }
    li {
        margin-bottom: 7px;
        font-size: 12px;
        font-weight: 400;
        width: 50%;
        color: ${props => props.theme.darkTextColor};

        @media screen and (min-width: 880px) {
            flex: 1;
            font-size: 14px;
            flex-wrap: nowrap;
            width: unset;
        }

        /* :first-child {
            max-width: 100px;
        } */


        :nth-child(5) {
            display: flex;
            color: ${props => props.theme.darkTextColor};
        }

        :nth-child(6) {
            display: flex;
            align-items: center;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                gap: 4px;
                height: 30px;
                font-size: 13px;
                border-radius: 3px;
                border-radius: 4px;
                background: ${props => props.status == "success" ? "rgba(8, 245, 0, 0.14)" : props.status == "pending" ? "rgba(255, 208, 118, 0.25)" : "#CE0D9829"};
                color: ${props => props.status == "success" ? "rgb(30, 208, 26)" : props.status == "pending" ? "#EDB525" : "#CE0D98"} !important;
                
                ::before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: ${props => props.status == "success" ? "#11ce0d": props.status == "pending" ? "#EDB525" : "#CE0D98"};
                }
            }
        }

    }
    @media screen and (min-width: 880px) {
        flex-wrap: nowrap;
        padding: 20px;
        li {
            margin-bottom: 0px;
            padding: 10px 0px;
        }
    }
`;

export const AddDebitCardModal = styled.div`
    h4 {
        margin-bottom: 20px;
    }
    p {
        font-size: 15px;
        font-weight: 400;
        color: ${props => props.theme.darkTextColor};
        margin-bottom: 20px;
        text-align: center;
    }
`;


export const VerifyingPayment = styled.div`
    h4 {
        margin-bottom: 20px;
        font-size: 14px;
    }
`;