export const getInitials = (value: string) : string => {
    const word_arr = value.trim().split(" ")

    let first_word = "_"
    let second_word = "_"

    if (word_arr.length > 1) {
        first_word = word_arr[0][0]

        if (word_arr[1].trim().length) {
            second_word = word_arr[1][0]
        }
    } else {
        first_word = word_arr[0][0]

        if (word_arr[0].length > 1) {
            second_word = word_arr[0][1]
        }
    }

    return `${first_word}${second_word}`
}

export const isValidEmail = (value: string) : boolean => {
    const isValid = value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)

     if (isValid) {
        return true
     } else {
        return false
     }
}