import PopupModal from '../../../../components/popupModal/PopupModal'
import usePaymentModel from '../usePaymentModel'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks'
import { updateCreatAppState } from '../../../../../slices/createAppSlice'
import useWalletPaymentModel from '../useWalletPaymentModel'
import { updateWalletState } from '../../../../../slices/walletSlice'
import { useState } from 'react'
import Loader from '../../../../components/Loader/Loader'
import { HOME_URL } from '../../../../../config/const'



const stripePromise = loadStripe('pk_test_LzwRIqoj9nVFltpQFWjaIF6K');


const StripeCheckoutForm: React.FC<{transType: string}> = ({transType}) => {
    const stripe = useStripe();
    const elements = useElements();
    const paymentMdel = usePaymentModel()
    const walletPaymentModel = useWalletPaymentModel()
    const createApp = useAppSelector(state => state.createApp.state);
    const walletState = useAppSelector(state => state.wallet.state);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true)
        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
            return_url: transType == "wallet_topup" ? HOME_URL + "/dashboard/billing?stripe_pay_id=" + walletState.paymentReference : HOME_URL + "/dashboard?stripe_pay_id=" + createApp.paymentReference + "&plan_id=" + createApp.selectedPlanId,

            },
            redirect: "if_required",
        });

        setIsLoading(false)
        if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
            if (transType == "wallet_topup") {
                walletPaymentModel.verifyTransaction({})
            } else {
                paymentMdel.updatePaymentStatus(true);
                dispatch(updateCreatAppState({...createApp, showStripeModal: false, addSiteModal: true, showPaymentMethodModal: false}))
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button type="submit" disabled={!stripe}>{isLoading ? <Loader /> :  "Pay"}</button>
        </form>
    )
}

const StripeModal: React.FC<{transType: string, clientSecret: string}> = ({transType, clientSecret}) => {
    const createApp = useAppSelector(state => state.createApp.state);
    const walletState = useAppSelector(state => state.wallet.state);
    const dispatch = useAppDispatch()

    const options = {clientSecret: clientSecret};

    return (
        <PopupModal showPopup={transType == "wallet_topup" ? walletState.showStripeModal : createApp.showStripeModal} closeModal={() => {
            if (transType == "wallet_topup") {
                dispatch(updateWalletState({...walletState, showStripeModal: false}))
            } else {
                dispatch(updateCreatAppState({...createApp, showStripeModal: false}))
            }
        }}>
            <Elements stripe={stripePromise} options={options}>
                <StripeCheckoutForm transType={transType} />
            </Elements>
        </PopupModal>
    )
}

export default StripeModal