import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState: {state: TeamSliceInterface} = {
  state: {
    teams: [],
    isLoading: true,
    fetchedTeams: false,
    isSwitchingTeam: false,
    defaultTeam: {
      id: "",
      name: "",
      role: ""
    }
  }
};

export const setTeamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams: (state, action: PayloadAction<TeamSliceInterface>) => {
      state.state = {...state.state, ...action.payload};
    },
    setIsSwitchingTeam: (state, action: PayloadAction<boolean>) => {
      state.state.isSwitchingTeam =  action.payload;
    },
  },
});

export const { setTeams, setIsSwitchingTeam } = setTeamSlice.actions;

export interface TeamSliceInterface {
  teams?: Team[]
  fetchedTeams?: boolean,
  isSwitchingTeam?: boolean,
  isLoading?: boolean,
  defaultTeam?: {
    id?: string,
    name?: string,
    role?: string
  }
}

export interface Team {
  id?:           string;
  name?:         string;
  user_id?:      string;
  email?:        string;
  members?:      string;
  meta?:         string;
  date_created?: Date;
  date_updated?: Date;
}

export default setTeamSlice.reducer;
