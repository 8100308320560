import styled from 'styled-components'

export const MainLayoutWrapper = styled.div`
    /* display: flex; */
    background-color: ${props => props.theme.backgroudColor};
    /* align-items: flex-start; */
    min-height: 100vh;
`;

export const Content = styled.div`
    flex: 1;
    margin-top: 70px;
    position: relative;
    z-index: 99;
    padding: 0px 6%;
    /* background-color: #f9f6f7; */

    @media screen and (min-width: 880px) {
        margin-left: 240px;
        padding: 0px 4%;
    }
`;

export const SwitchTeamWrapper = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    gap: 15px;
    top: 0px;
    left: 0px;
    height: 100vh;
    z-index: 999999999999999;
    background-color: ${props => props.theme.backgroudColor};

    .content {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            width: 30px;
        }

        span {
            font-size: 16px;
            font-weight: 400;
            color: ${props => props.theme.darkTextColor};
        }
    }
`;


export const EmailVerify = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${props => props.theme.warningColor};
    background-color: ${props => props.theme.warningBackground};
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 5px 10px;
    gap: 10px;

    p {
        color: ${props => props.theme.warningColor};
        font-size: 13px;
        letter-spacing: 0.5px;
        font-weight: 400;
        word-spacing: 1px;
    }

    button {
        background-color: rgb(179, 140, 27);
        padding: 8px 20px;
        border-radius: 4px;
        font-size: 12px;
        color: #fff;
        border: none;
        text-transform: capitalize;
        cursor: pointer;
    }
`;
