import { useDispatch } from 'react-redux';
import { AuthSettingsWrapper, Container, Wrapper } from './styles'
import { useAppSelector } from '../../../../../hooks/hooks';
import { useEffect, useState } from 'react';
import Switch from '../../../../components/switch/Switch';
import { setAppTheme } from '../../../../../slices/themeSlice';

const PreferencesTab: React.FC = () => {
    const [darkMode, setDarkMode] = useState(false)
    const appTheme = useAppSelector(state => state.theme.state);
    const dispatch = useDispatch();

    const updateTheme = async () => {
        if (darkMode) {
            setDarkMode(false)
            localStorage.setItem("app_theme", "light")
            dispatch(setAppTheme("light"))
        } else {
            setDarkMode(true)
            localStorage.setItem("app_theme", "dark")
            dispatch(setAppTheme("dark"))
        }
    }

    useEffect(() => {
        const app_theme = localStorage.getItem("app_theme")
        if (app_theme == "dark") {
            dispatch(setAppTheme("dark"))
            setDarkMode(true)
        } else {
            dispatch(setAppTheme("light"))
            setDarkMode(false)
        }
    }, [])

    return (
        <Wrapper>
            <Container>
                <AuthSettingsWrapper>
                    <div className="content">
                        <h2>Theme</h2>

                        <div className="col">
                            <label>Dark Mode</label>
                            
                            <Switch active={appTheme.mode == "dark"} onClick={() => {updateTheme()}} />
                        </div>
                    </div>
                </AuthSettingsWrapper>
            </Container>
        </Wrapper>
    )
}

export default PreferencesTab