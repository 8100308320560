import styled from 'styled-components'

export const Wrapper = styled.div`
`;
export const Sidebar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.authSideBackground};
    width: 100%;
    width: 100%;
    height: 100vh;
    position: relative;

    .content {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        .text-content {
            display: flex;
            align-items: center;
            gap: 20px;

            h4 {
                font-size: 20px;
                font-weight: 500;
                color: #fff;
            }

            img {
                height: 30px;
            }
        }

        .error {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            h4 {
                font-size: 16px;
                font-weight: 500;
                color: #fff;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                padding: 8px 15px;
                border-radius: 4px;
                background-color: ${props => props.theme.containerBackground};
                color: ${props => props.theme.darkTextColor};
                border: none;
                cursor: pointer;
            }
        }
    }

    ::before {
        content: "";
        display: block;
        background: url("/assets/img/coral-one.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 400px;
        height: 280px;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
    ::after {
        content: "";
        display: block;
        background: url("/assets/img/coral-two.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 150px;
        height: 280px;
        position: absolute;
        top: 0px;
        right: 0px;
        transform: rotateZ(180deg);
    }

    @media screen and (min-width: 880px) {  
        /* max-width: 640px; */
    }
`;

export const Container = styled.div`
    display: flex;
`;
export const MainSection = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #0002;
    flex: 1;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    position: absolute;

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar, ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
        display: none;
    }

    h1 {
        font-size: 30px;
        font-weight: 500;
        color: ${props => props.theme.textPrimaryColor};
        margin: 30px 0px 30px 0px;
        /* text-align: center; */
    }
    .has-acct-tow {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0px 25px 0px;
    
        span {
            font-size: 13px;
            margin-right: 4px;
            font-weight: 400;
            color: #5C5C5C;
        }
         a {
            font-size: 13px;
            color: #5C5C5C;
            font-weight: 600;
            text-decoration: underline;
         }
    }

    @media screen and (min-width: 880px) {
        background-color: ${props => props.theme.containerBackground};
        position: unset;
    }
`;

export const Form = styled.form`
    width: 100%;
    max-width: 480px;
    padding: 0px 20px;
    border-radius: 8px;
    background-color: ${props => props.theme.containerBackground};
`;

export const InputSec = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    label {
        margin-bottom: 6px;
        font-size: 13px;
        font-weight: 600;
        color: #1C1D1F;
    }

    input {
        width: 100%;
        height: 44px;
        padding: 0px 15px;
        font-size: 13px;
        color: #1C1D1F;
        border: 0.5px solid #898B8D;
        border-radius: 4px;
        background-color: ${props => props.theme.containerBackground};
        ::placeholder {
            color: #1C1D1F;
        }
    }
`;
export const PasswordInput = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    label {
        margin-bottom: 6px;
        font-size: 13px;
        font-weight: 600;
        color: #1C1D1F;
    }
    .input-row {
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        padding: 0px 15px;
        border: 0.5px solid #898B8D;
        border-radius: 4px;
        background-color: ${props => props.theme.containerBackground};

        input {
            flex: 1;
            font-size: 13px;
            color: #1C1D1F;
            border: none;
            border-radius: 0px;
            background-color: ${props => props.theme.containerBackground};
            ::placeholder {
                color: #1C1D1F;
            }
        }

        svg {
            fill: #898B8D;
            font-size: 18px;
            cursor: pointer;
        }
    }
`;

export const Button = styled.button`
    width: 100%;
    height: 45px;
    border-radius: 4px;
    color: ${props => props.theme.primaryButtonTextColor};
    font-size: 13px;
    font-weight: 600;
    background-color: ${props => props.theme.primaryButtonColor};
    margin-top: 10px;
    border: none;
    cursor: pointer;
`;

export const FormMeta = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0px 0px 15px 0px;
    a {
        font-size: 13px;
        color: #5C5C5C;
        text-decoration: underline;
    }
`;