import { HeadRow, Wrapper } from './styles'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import { VscSettings } from 'react-icons/vsc';
import ProfileTab from './profile_tab/ProfileTab';
import TeamsTab from './teams_tab/TeamsTab';
import PreferencesTab from './preferences_tab/PreferencesTab';
import { FiMail } from 'react-icons/fi';
import InvitesTab from './invites_tab/InvitesTab';
import useRouter from '../../../../hooks/useRouter';

const SettingsScreen: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState("profile")
    const router = useRouter()

    useEffect(() => {
        console.log("router current_tab: ", router.query.tab);
        if (router.isReady) {

            if (router.query.tab) {
                setSelectedTab(router.query.tab)
            }
        }
    }, [router.isReady])

    useEffect(() => {
        console.log("selectedTab current_tab: ", router.query.tab);
        if (!selectedTab) return
        router.push({ query: { ...router.query, tab: selectedTab } });
    }, [selectedTab])

    return (
        <Wrapper>
            <HeadRow>
                <li>
                    <Link className={selectedTab == "profile" ? "active" : ""} to={"#"} onClick={() => {setSelectedTab("profile")}}><FaUserAlt />Profile</Link>
                </li>
                <li>
                    <Link className={selectedTab == "teams" ? "active" : ""} to="#" onClick={() => {setSelectedTab("teams")}}><HiUserGroup />Teams</Link>
                </li>
                <li>
                    <Link className={selectedTab == "preferences" ? "active" : ""} to="#" onClick={() => {setSelectedTab("preferences")}}><VscSettings />Preferences</Link>
                </li>
                <li>
                    <Link className={selectedTab == "invites" ? "active" : ""} to="#" onClick={() => {setSelectedTab("invites")}}><FiMail />Invites</Link>
                </li>
            </HeadRow>
            
            {selectedTab == "profile" ? <ProfileTab />
            : selectedTab == "teams"? <TeamsTab />
            : selectedTab == "invites"? <InvitesTab />
            : selectedTab == "preferences" ? <PreferencesTab /> : <></> }
        </Wrapper>
    )
}

export default SettingsScreen