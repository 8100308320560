import { useDispatch } from 'react-redux';
import Button from '../../../../components/button/Button'
import { AuthSettingsWrapper, Container, SitesWrapper } from './styles'
import { useAppSelector } from '../../../../../hooks/hooks';
import Message from '../../../../components/message/Message';
import AxiosCall from '../../../../../utils/axios';
import { useRef, useState } from 'react';
import { setProfile } from '../../../../../slices/profileSlice';
import ComplexDropdown from '../../../../components/complexDropdown/ComplexDropdown';
import { countryLIst } from '../../../../../data/countryList';
import Switch from '../../../../components/switch/Switch';
import { Wrapper } from '../styles';

const ProfileTab: React.FC = () => {
    const userProfile = useAppSelector(state => state.profile.state);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const firstNameRef = useRef<HTMLInputElement>(null)
    const lastNameRef = useRef<HTMLInputElement>(null)
    const phoneRef = useRef<HTMLInputElement>(null)

    const updateProfile = async () => {
        try {
            setIsLoading(true)
            
            const res: any = await AxiosCall({
                method: "PATCH",
                path: "/v1/profile/update",
                data: {
                    first_name: firstNameRef.current?.value,
                    last_name: lastNameRef.current?.value,
                    phone: phoneRef.current?.value,
                }
            });

            setIsLoading(false)
            if (res.status == 1) {
                dispatch(setProfile({
                    firstName: res.data.firstname,
                    lastName: res.data.lastname,
                    phone: res.data.phone
                }))
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    return (
        <Wrapper>
            <Container>
                <SitesWrapper>
                    <div className="content">
                        <h2>Profile Information</h2>

                        <div className="col">
                            <label>First name</label>
                            <div className="row">
                                <input ref={firstNameRef} type="text" placeholder='First name' defaultValue={userProfile.firstName}/>
                                <input ref={lastNameRef} type="text" placeholder='Last name' defaultValue={userProfile.lastName}/>
                            </div>
                        </div>
                        <div className="col">
                            <label>Email</label>
                            <input type="text" disabled placeholder='Enter Email' value={userProfile.email}/>
                        </div>
                        <div className="col">
                            <label>Phone</label>
                            <div className="row">
                                <ComplexDropdown options={countryLIst} optionValue={"dial_code"} showSearch={true}  />
                                <input ref={phoneRef} type="text" placeholder='Enter phone number' defaultValue={userProfile.phone}/>
                            </div>
                        </div>
                        <Button width="100px" onClick={updateProfile} isLoading={isLoading}>Save Changes</Button>
                    </div>
                </SitesWrapper>

                <AuthSettingsWrapper>
                    <div className="content">
                        <h2>Authentication</h2>

                        <div className="col">
                            <label>Password</label>
                            <button>change password</button>
                        </div>


                        <div className="col">
                            <label>Two-factor Auth</label>
                            
                            <Switch active={false} onClick={() => {}} />
                        </div>
                    </div>
                </AuthSettingsWrapper> 
            </Container>
        </Wrapper>
    )
}

export default ProfileTab