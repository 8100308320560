import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState: {state: ThemeSliceInterface} = {
  state: {
    mode: "light"
  }
};

export const setThemeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setAppTheme: (state, action: PayloadAction<string | "light" | "dark">) => {
      state.state.mode = action.payload;
    },
  },
});

export const { setAppTheme } = setThemeSlice.actions;

export interface ThemeSliceInterface {
  mode: string
}

export default setThemeSlice.reducer;
