import { useState } from "react";
import { Wrapper } from "./styles";

interface LoaderInterface {
    topColor?: string
    sideColor?: string
    styleTwo?: boolean
    topPadding?: string
    bottomPadding?: string
    center?: boolean
}
const Loader: React.FC<LoaderInterface> = ({topColor, sideColor, styleTwo = false, center = false, topPadding = "0px", bottomPadding = "0px"}) => {

    return (
        <Wrapper className="app-loader" center={center} topPadding={topPadding} bottomPadding={bottomPadding} topColor={styleTwo ? "#ab52c3" : topColor} sideColor={styleTwo ? "rgb(229, 177, 243)" : sideColor} />
    );
}
 
export default Loader;