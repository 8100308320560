import React, { useRef, useState } from 'react';
import { DropdownSelect } from './styles';
import { IoIosArrowDown } from 'react-icons/io';
import Loader from '../Loader/Loader';



const ComplexDropdown: React.FC<{options: any[], optionValue: any, showSearch?: boolean, placeHolder?: string, onChange?: (value: any) => void, isLoading?: boolean}> = ({options, placeHolder, optionValue, showSearch = false, onChange, isLoading = false}) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(placeHolder ?? "Select")

    const [_leftOffset, setLeftOffset] = useState(0)
    const [_topOffset, setTopOffset] = useState(0)
    const [searchItem, setSearchItem] = useState("")

    const dropdownRef = useRef<HTMLDivElement>(null)
    const searchRef = useRef<HTMLInputElement>(null)

    const  openDropdown = (e: any) => {
        e.preventDefault()
        if (isLoading) return;
        const _leftOffset = window.pageXOffset + dropdownRef.current?.getBoundingClientRect()!.left!;
        const _topOffset = window.pageYOffset + dropdownRef.current?.getBoundingClientRect()!.top! + dropdownRef.current?.clientHeight!;
        
        if (e.target != searchRef.current) {
            setShowDropdown(!showDropdown)
        }

        setTopOffset(_topOffset)
        setLeftOffset(_leftOffset);
    }

    return (
        <DropdownSelect ref={dropdownRef}  selected={showDropdown} onClick={(e) => openDropdown(e)} className="dropdown">
            <span className="placeholder">{selectedOption}</span>
            {isLoading ? <Loader styleTwo /> : <IoIosArrowDown />}

            <div className="options-wrapper">
                {showSearch && <div className="search-wrapper">
                    <input ref={searchRef} type="text" placeholder="search" onChange={(e) => setSearchItem(e.target.value)} />
                </div>}
                {options.filter((item) => item[optionValue].toLocaleLowerCase().includes(searchItem.toLocaleLowerCase())).map((item: any, idx) => {
                    return  <span key={"card-options-"+idx} className={`options ${selectedOption == item[optionValue] ? " active-option" : ""}`} onClick={() => {
                        setSelectedOption(item[optionValue]);
                        if (onChange) onChange(item);
                    }}>{item[optionValue]}</span>
                })}
            </div>
        </DropdownSelect>
    )
}
 
export default ComplexDropdown;