import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`;
export const Container = styled.div`
    width: 100%;
    margin-top: 20px;
`;


export const HeadRow = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar, ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
        display: none;
    }

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.darkTextColor};
        padding: 14px 20px;
        position: relative;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 0.5px solid ${props => props.theme.lightBorderColor};

        svg {
            font-size: 18px;
            color: ${props => props.theme.lightTextColor};
        }
    }

    :first-child {
        a {
            svg {
                font-size: 15px;
            }
        }
    }

    :nth-child(2) {
        a {
            svg {
                font-size: 15px;
            }
        }
    }

    a.active {
        color: ${props => props.theme.textPrimaryColor};
        border-bottom: 2px solid ${props => props.theme.primaryColor};

        svg {
            color: ${props => props.theme.textPrimaryColor};
        }
    }

    ::before {
        content: "";
        position: absolute;
        width: 100%;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};
        bottom: 0px;
    }

    @media screen and (min-width: 1080px) {

    }
`;

export const SitesWrapper = styled.div`
    flex: 1;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid rgb(238, 238, 238);
    margin: 20px 0px;
    border-radius: 4px;

    .head {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgb(238, 238, 238);
        padding: 20px 20px;

        h5 {
            color: ${props => props.theme.textColor};
            font-size: 14px;
            font-weight: 600;
            text-align: center;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.darkTextColor};
            border: none;
            width: 90px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.whiteTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.whiteTextColor};
                font-size: 15px;
            }
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;

export const InputSec = styled.div`

`;