import React, { useRef, useState } from 'react'
import { VerifyCode, VerifyEmail } from './styles'
import PopupModal from '../../../components/popupModal/PopupModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import Loader from '../../../components/Loader/Loader';
import { setProfile } from '../../../../slices/profileSlice';
import AxiosCall from '../../../../utils/axios';
import Message from '../../../components/message/Message';


const VerifyEmailModal: React.FC = () => {
    const userProfile = useAppSelector(state => state.profile.state);
    const [isLoadingOtpVerify, setIsLoadingOtpVerify] = useState(false)
    const [showVerifyOtp, setShowVerifyOtp] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()



    const inputRef1 = useRef<HTMLInputElement>(null)
    const inputRef2 = useRef<HTMLInputElement>(null)
    const inputRef3 = useRef<HTMLInputElement>(null)
    const inputRef4 = useRef<HTMLInputElement>(null)


    const clearInput = () => {
        inputRef1!.current!.value = "";
        inputRef2!.current!.value = "";
        inputRef3!.current!.value = "";
        inputRef4!.current!.value = "";
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
    }

    const emailRef = useRef<HTMLInputElement>(null)

    const sendEmailToken = async (e: any) => {
        e.preventDefault();

        try {
            if (isLoading) {
                return
            }

            setIsLoading(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/email/otp/send",
                data: {
                    email: emailRef?.current?.value,
                }
            });

            if (res.status == 1) {
                setIsLoading(false)
                setShowVerifyOtp(true)
                dispatch(setProfile({showVerifyModal: false}))
            } else {
                setIsLoading(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            console.log(err);
            
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }


    const verifyEmailOtp = async () => {

        try {
            if (isLoading) {
                return
            }

            setIsLoadingOtpVerify(true)

            const _otp = inputRef1!.current!.value + inputRef2!.current!.value + inputRef3!.current!.value + inputRef4!.current!.value;

            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/email/otp/verify",
                data: {
                    email: emailRef?.current?.value,
                    otp: _otp
                }
            });

            if (res.status == 1) {
                setIsLoadingOtpVerify(false)
                setShowVerifyOtp(false)
                dispatch(setProfile({verified_email: 1, showVerifyModal: false, email: emailRef.current?.value}))
            } else {
                setIsLoadingOtpVerify(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoadingOtpVerify(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    return (
        <>
            <PopupModal showPopup={showVerifyOtp} maxWidth='380px' closeModal={() => setShowVerifyOtp(false)}>
                <VerifyCode>
                    <h4>Enter code</h4>

                    {isLoadingOtpVerify ? <Loader styleTwo center /> : <div className="input-row">
                        <input ref={inputRef1} type="text" placeholder="*" onChange={(e) => {
                            handleInputChange(e);
                            if (e.target.value.length == 1) {
                                inputRef2.current?.focus()
                            } else {
                                
                            }
                        }} autoFocus />
                        <input ref={inputRef2} type="text" placeholder="*" onChange={(e) => {
                            handleInputChange(e);
                            if (e.target.value.length == 1) {
                                inputRef3.current?.focus()
                            } else {
                                inputRef1.current?.focus()
                            }
                        }} />
                        <input ref={inputRef3} type="text" placeholder="*" onChange={(e) => {
                            handleInputChange(e);
                            if (e.target.value.length == 1) {
                                inputRef4.current?.focus()
                            } else {
                                inputRef2.current?.focus()
                            }
                        }} />
                        <input ref={inputRef4} type="text" placeholder="*" onChange={(e) => {
                            handleInputChange(e);
                            if (e.target.value.length == 1) {
                                inputRef4.current?.blur()
                                verifyEmailOtp()
                            } else {
                                inputRef3.current?.focus()
                            }
                        }} />
                    </div>}
                    {/* <button>Verify</button> */}
                </VerifyCode>
            </PopupModal>

            <PopupModal showPopup={userProfile.showVerifyModal!} closeModal={() => dispatch(setProfile({showVerifyModal: false}))} >
                <VerifyEmail>
                    <h4>Veryify Email</h4>
                    <input ref={emailRef} disabled={userProfile.email!.length > 0} type="email" placeholder="Enter Email" defaultValue={userProfile.email} />
                    <button onClick={sendEmailToken}>{isLoading ? <Loader /> : "Send OTP"}</button>
                </VerifyEmail>
            </PopupModal>
        </>
    )
}

export default VerifyEmailModal