import Button from '../../../../../components/button/Button'
import { AuthSettingsWrapper, Container, InviteMemberModal, Members, MembersList, SitesWrapper, Wrapper } from './styles'
import Message from '../../../../../components/message/Message';
import AxiosCall from '../../../../../../utils/axios';
import { useEffect, useRef, useState } from 'react';
import ComplexDropdown from '../../../../../components/complexDropdown/ComplexDropdown';
import { IoArrowBackOutline } from 'react-icons/io5';
import { FaUserAlt } from 'react-icons/fa';
import { MdAddCircleOutline } from 'react-icons/md';
import PopupModal from '../../../../../components/popupModal/PopupModal';
import { MemberInfoModal } from '../../../projects/project/settings_tab/styles';
import Loader from '../../../../../components/Loader/Loader';
import { IoMdClose } from 'react-icons/io';
import Dropdown from '../../../../../components/dropdown/Dropdown';
import { Project } from '../../../../../../slices/projectSlice';
import { isValidEmail } from '../../../../../../utils';

const TeamInfo: React.FC<{goBack: () => void, selectedTeam: any}> = ({goBack, selectedTeam = null}) => {
    const [showInviteMemberModal, setShowInviteMemberModal] = useState(false)
    const [showUpdateMemberModal, setShowUpdateMemberModal] = useState(false)
    const [isSearchingMember, setIsSearchingMember] = useState(false)
    const [searchedMember, setSearchedMember] = useState<any>(null)
    const [searchList, setSearchList] = useState<any[]>([])
    const [isFetchingmembers, setIsfetchingMembers] = useState(true)
    const [searchEmail, setSearchEmail] = useState("")
    const [selectedProject, setSelectedProject] = useState<any>(null)
    const [isFetchingProjects, setIsFetchingProjects] = useState(false)
    const [projects, setProjects] = useState<Project[]>([])
    const [memberRole, setMemberRole] = useState("")
    
    const [members, setMembers] = useState<any[]>([])

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isInviteingMember, setIsInviteingMember] = useState<boolean>(false)

    const teamNameRef = useRef<HTMLInputElement>(null)
    const teamEmailRef = useRef<HTMLInputElement>(null)

    const permissionLevels = [
        "Administrator",
        "Member",
    ]

    const updateTeamProfile = async () => {
        try {
            setIsLoading(true)
            
            const res: any = await AxiosCall({
                method: "PATCH",
                path: "/v1/team/update/"+ selectedTeam?.id,
                data: {
                    name: teamNameRef.current?.value,
                    email: teamEmailRef.current?.value,
                }
            });

            setIsLoading(false)
            if (res.status == 1) {
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while saving team information")
        }
    }

    const inviteMember = async () => {
        try {
            setIsInviteingMember(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/team/invite/send/"+ selectedTeam?.id,
                data: {
                    invite_email: searchedMember?.email,
                    project_id: selectedProject?.id,
                    role: memberRole.toUpperCase()
                }
            });

            setIsInviteingMember(false)
            if (res.status == 1) {
                setShowInviteMemberModal(false)
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsInviteingMember(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while inviting user")
        }
    }

    const fetchMembers = async () => {
        try {
            setIsfetchingMembers(true)
            
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/team/members/fetch/"+ selectedTeam?.id,
            });

            setIsfetchingMembers(false)
            if (res.status == 1) {
                setMembers(res.data)
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsfetchingMembers(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching members")
        }
    }

    const resolveUser = async () => {
        try {
            if (searchEmail.length < 3) {
                setSearchList([]);
                return
            }
            setIsSearchingMember(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/users/search",
                data: {
                    email: searchEmail
                }
            });

            setIsSearchingMember(false)
            if (res.status == 1) {
                if (res.data.length) {
                    setSearchList([
                        ...res.data
                    ])
                } else {

                    setSearchList([
                        {
                            firsname: "",
                            lastname: "",
                            email: searchEmail
                        }
                    ])
                }
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsSearchingMember(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching members")
        }
    }


    const fetchProjects = async () => {
        try {
            setProjects([])
            setIsFetchingProjects(true)
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/team/projects/fetch/" + selectedTeam?.id,
            });
            setIsFetchingProjects(false)
            if (res.status == 1) {
                setProjects(res.data)
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsFetchingProjects(false)
            Message.error(err?.response.data.message)
        }
    }

    useEffect(() => {
        teamNameRef.current!.value = selectedTeam?.name
        teamEmailRef.current!.value = selectedTeam?.email

        fetchMembers()
        fetchProjects()
    }, [])

    useEffect(() => {
        resolveUser()
    }, [searchEmail])

    return (
        <Wrapper>
            <Container>
                <SitesWrapper>
                    <div className="back" onClick={() => goBack()}>
                        <IoArrowBackOutline />
                        <span>back</span>
                    </div>
                    <div className="content">
                        <h2>Team Information</h2>

                        <div className="col">
                            <label>Team name</label>
                            <input ref={teamNameRef} type="text" placeholder="Team name" />
                        </div>
                        <div className="col">
                            <label>Team Contact Email</label>
                            <input ref={teamEmailRef} type="text" placeholder='Enter Email' />
                        </div>
                        <Button width="100px" onClick={updateTeamProfile} isLoading={isLoading}>Save Changes</Button>
                    </div>
                </SitesWrapper>

                <AuthSettingsWrapper>
                    <div className="head">
                        <h5>Members</h5>

                        <button className="add-site" onClick={() => setShowInviteMemberModal(true)}>
                            <MdAddCircleOutline />
                            <span>Invite</span>
                        </button>
                    </div>
                    <MembersList>
                        {isFetchingmembers ? <Loader styleTwo /> : members.map((item, idx) => {
                            return <Members key={idx}>
                                <div className="status-indicator">
                                    <FaUserAlt />
                                </div>
                                <div className="info">
                                    <h3>{item?.first_name} {item?.last_name}</h3>
                                    <span>{item?.role}</span>
                                </div>
                                <button onClick={() => {}}>delete</button>
                            </Members>
                        })}
                    </MembersList>
                </AuthSettingsWrapper>
                    

                <PopupModal showPopup={showInviteMemberModal} closeModal={() => setShowInviteMemberModal(false)}>
                    <InviteMemberModal>
                        <div className="head">
                            {/* <div className="img-wrapper">
                                <RiUserAddFill />
                            </div> */}
                            <h4>Invite Member</h4>
                        </div>

                        {!searchedMember && <div className="search-member">
                            <input type="text" placeholder="Enter email" onChange={(e) => setSearchEmail(e.target.value)} />
                            {isSearchingMember && <Loader />}
                        </div>}

                        {searchedMember && <div className="selected-member">
                            <div className="info">
                                {(searchedMember?.firstname || searchedMember?.lastname) ? <>
                                    <h3>{searchedMember?.firstname} {searchedMember?.lastname}</h3>
                                    <span>{searchedMember?.email}</span></> :
                                <h3>{searchedMember?.email}</h3>}
                                
                            </div>

                            <IoMdClose onClick={() => setSearchedMember(null)} />
                        </div>}

                        {searchList.length ? <ul className="search-list">
                            {searchList.map((item, idx) => {
                                return <li key={idx} onClick={() => {setSearchList([]); setSearchedMember(item)}}>
                                    <div className="info">
                                        <h3>{item.firstname} {item.lastname}</h3>
                                        <span>{item.email}</span>
                                    </div>

                                    <b>invite</b>
                                </li>
                            })}
                        </ul> : <></>}

                        {searchedMember && <ComplexDropdown isLoading={isFetchingProjects} placeHolder="-- selected project --" options={projects} optionValue={"name"} showSearch={true} onChange={(item) => {
                            setSelectedProject(item)
                        }}  />}

                        {(selectedProject && searchedMember) && <Dropdown placeHolder="-- select role --" options={permissionLevels} onChange={(item) => setMemberRole(item)} />}

                        <Button isDisabled={selectedProject == null || searchedMember == null || !memberRole} onClick={() => {inviteMember()}} isLoading={isInviteingMember}>Invite</Button>
                    </InviteMemberModal>
                </PopupModal>

                <PopupModal closeModal={() => setShowUpdateMemberModal(false)}  showPopup={showUpdateMemberModal}>
                    <MemberInfoModal>
                        <h4>Member Infomation</h4>

                        <ul className="info">
                            <li>
                                <span>Name: </span>
                                <span>John Soleman</span>
                            </li>
                            <li>
                                <span>Email Address: </span>
                                <span>Suleman@gmail.com</span>
                            </li>
                        </ul>

                        <div className="button-row">
                            <button onClick={() => {setShowUpdateMemberModal(false)}}>Delete</button>
                            <Button onClick={() => {}} isLoading={false}>Update</Button>
                        </div>
                    </MemberInfoModal>
                </PopupModal>
            </Container>
        </Wrapper>
    )
}

export default TeamInfo