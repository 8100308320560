import { Container, ContentWrapper, Form, InputSec, MainRow, SitesWrapper, Wrapper } from './styles'
import { useEffect, useRef, useState } from 'react'
import AxiosCall from '../../../../../utils/axios'
import Message from '../../../../components/message/Message'
import Dropdown from '../../../../components/dropdown/Dropdown'
import Button from '../../../../components/button/Button'
import { useNavigate } from 'react-router'
import { useAppDispatch } from '../../../../../hooks/hooks'
import { fetchProjects } from '../../../../../actions/indexActions'

const NewProjectsScreen: React.FC = () => {
    const [isCreatingProject, setIsCreatingProject] = useState(false)
    const [reason, setReason] = useState("")
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const projectNameRef = useRef<HTMLInputElement>(null)
    const projectdescriptionRef = useRef<HTMLInputElement>(null)

    const createProject = async () => {
        try {
            setIsCreatingProject(true)
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/project/add/1",
                data: {
                    name: projectNameRef.current?.value,
                    description: projectdescriptionRef.current?.value,
                    purpose: reason
                }
            });
            setIsCreatingProject(false)
            if (res.status == 1) {
                navigate("/dashboard/projects")
                fetchProjects(dispatch)
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsCreatingProject(false)
            Message.error(err?.response.data.message)
        }
    }

    return (
        <Wrapper>
            <Container>
                <MainRow>
                    <SitesWrapper>
                        <div className="head">
                            <h5>New Project</h5>
                        </div>

                        <ContentWrapper>
                            <Form>
                                <div className="img-wraper">
                                    <img src="/assets/img/avatar-alt.png" alt="avatar" />
                                </div>
                                <InputSec>
                                    <label htmlFor="">Project name</label>
                                    <input ref={projectNameRef} type="text" placeholder="Enter name" />
                                </InputSec>

                                <InputSec>
                                    <label htmlFor="">Project Description</label>
                                    <input ref={projectdescriptionRef} type="text" placeholder="Enter description" />
                                </InputSec>

                                <InputSec>
                                    <label htmlFor="">Purpose</label>
                                    <Dropdown placeHolder="Select purpose" options={[
                                        "Just trying out coralscale",
                                        "Educational purposes",
                                        "Website or blog",
                                        "Web Application",
                                        "Ecommerce"
                                    ]} onChange={(value) => setReason(value)} />
                                </InputSec>

                                <Button isLoading={isCreatingProject} onClick={createProject}>Create Project</Button>
                            </Form>
                        </ContentWrapper>
                    </SitesWrapper>
                </MainRow>
            </Container>
        </Wrapper>
    )
}

export default NewProjectsScreen