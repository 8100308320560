import { setProfile } from "../slices/profileSlice";
import { setTeams } from "../slices/teamSlice";
import AxiosCall from "../utils/axios";
import Message from "../views/components/message/Message";


export const fetchProfileAction = async (dispatch: Function) => {
    try {
        const res = await AxiosCall({
            method: "GET",
            path: "/v1/profile"
        });

        // const saved_team_settings = localStorage.getItem("default_team")

        // const default_team = {
        //     id: "",
        //     name: ""
        // }
        // if (saved_team_settings) {
        //     const _team = JSON.parse(saved_team_settings)
        //     default_team.id = _team?.id ?? ""
        //     default_team.name = _team?.name ?? ""
        // }

        dispatch(setTeams({defaultTeam: {
            id: res.data?.default_team?.id,
            name: res.data?.default_team?.name,
            role: res.data?.default_team?.role
        }}))

        dispatch(setProfile({
            email: res.data.email,
            firstName: res.data.firstname,
            lastName: res.data.lastname,
            phone: res.data.phone,
            fetchedProfile: true,
            balance: res.data.balance,
            verified_email: res.data.verified_email,
            verified_phone: res.data.verified_phone,
        }))
        
    } catch (err: any) {
        Message.error(err?.response.data.message)
    }
}