import Button from '../../../../components/button/Button'
import { AuthSettingsWrapper, Container, Invite, InviteInfoModal, InviteList, Wrapper } from './styles'
import { useAppDispatch } from '../../../../../hooks/hooks';
import Message from '../../../../components/message/Message';
import AxiosCall from '../../../../../utils/axios';
import { useEffect, useState } from 'react';
import PopupModal from '../../../../components/popupModal/PopupModal';
import Loader from '../../../../components/Loader/Loader';
import { BsFillSendFill } from 'react-icons/bs';
import { fetchTeams } from '../../../../../actions/indexActions';

const InvitesTab: React.FC = () => {
    const [showInviteInfoModal, setShowInviteInfoModal] = useState(false)
    const [isFetchingInvites, setIsfetchingInvites] = useState(true)
    const [isAcceptingInvite, setIsAcceptingInvite] = useState(false)
    const [isDecliningInvite, setIsDecliningInvite] = useState(false)
    const [selectedInvite, setSelectedInvite] = useState<any>(null)

    const dispatch = useAppDispatch()
    
    const [invites, setInvites] = useState<any[]>([])

    const fetchInvites = async () => {
        try {
            setIsfetchingInvites(true)
            
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/invites/fetch"
            });

            setIsfetchingInvites(false)
            if (res.status == 1) {
               const _inviteList = []

               for (let index = 0; index < res.data.length; index++) {
                const element = res.data[index];
                const _inviteMeta = JSON.parse(element?.meta ?? "[]")
                _inviteList.push({
                    invite_id: element?.id,
                    ..._inviteMeta
                })
               }
                setInvites(_inviteList)
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsfetchingInvites(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching invites")
        }
    }


    const acceptInvite = async () => {
        try {
            setIsAcceptingInvite(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/invite/accept/" + selectedInvite?.invite_id
            });

            setIsAcceptingInvite(false)
            if (res.status == 1) {
                setShowInviteInfoModal(false)
                fetchInvites()
                fetchTeams(dispatch)
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsAcceptingInvite(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while accepting invite")
        }
    }

    const declineInvite = async () => {
        try {
            setIsDecliningInvite(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/invite/decline/" + selectedInvite?.invite_id
            });

            setIsDecliningInvite(false)
            if (res.status == 1) {
                setShowInviteInfoModal(false)
                fetchInvites()
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsDecliningInvite(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while accepting invite")
        }
    }


    useEffect(() => {

        fetchInvites()
    }, [])


    return (
        <Wrapper>
            <Container>

                <AuthSettingsWrapper>
                    <div className="head">
                        <h5>Invites</h5>
                    </div>
                    <InviteList>
                        {isFetchingInvites ? <Loader styleTwo /> : <>{invites.length ? invites.map((item, idx) => {
                            return <Invite key={idx} onClick={() => {
                                setSelectedInvite(item)
                                setShowInviteInfoModal(true)
                            }}>
                                    <div className="status-indicator">
                                        <BsFillSendFill />
                                    </div>
                                    <div className="info">
                                        <h3>{item?.from?.firstname} {item?.from?.lastname}</h3>
                                        <span>- {item?.project_name}</span>
                                    </div>
                                    <button>view</button>
                                </Invite>
                            }) : 
                                <div className="empty-list">
                                    <p>No invites at the moment :)</p>
                                </div>
                            }</>
                        }
                    </InviteList>
                </AuthSettingsWrapper>

                <PopupModal closeModal={() => setShowInviteInfoModal(false)}  showPopup={showInviteInfoModal}>
                    <InviteInfoModal>
                        <div className="icon-box">
                            <BsFillSendFill />
                        </div>
                        <h4>Invite Infomation</h4>

                        <ul className="info">
                            <li>
                                <span>From: </span>
                                <span>{selectedInvite?.from?.firstname} {selectedInvite?.from?.lastname}</span>
                            </li>
                            <li>
                                <span>Team: </span>
                                <span>{selectedInvite?.team_name}</span>
                            </li>
                            <li>
                                <span>Project: </span>
                                <span>{selectedInvite?.project_name}</span>
                            </li>
                        </ul>

                        <div className="button-row">
                            <button onClick={() => {declineInvite()}}>{isDecliningInvite ? <Loader styleTwo /> : "Decline"}</button>
                            <Button onClick={() => {acceptInvite()}} isLoading={isAcceptingInvite}>Accept</Button>
                        </div>
                    </InviteInfoModal>
                </PopupModal>
            </Container>
        </Wrapper>
    )
}

export default InvitesTab