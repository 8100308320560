import styled from 'styled-components'

export const VerifyCode = styled.div`

    .input-row {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        input {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: ${props => props.theme.containerBackground};
            font-weight: 600;
            font-size: 14px;
            color: #5F6980;
            width: 100%;
            max-width: 45px;
            aspect-ratio: 1/1;
            border-radius: 4px;
            /* border: none; */

            ::placeholder {
                font-size: 20px;
            }

            :not(:placeholder-shown) {
                background-color: #fff;
                background-color: ${props => props.theme.backgroudColor};
                color: ${props => props.theme.textColor};
            }
        }
    }
    
`;

export const VerifyEmail = styled.div`
    h4 {
        font-size: 16px;
    }

    input {
        :disabled {
            background-color: ${props => props.theme.lightBorderColor};
            border: 1px solid ${props => props.theme.lightBorderColor};
            cursor: not-allowed;
        }
    }
`;