import { useDispatch } from 'react-redux';
import { Container, SitesWrapper, EmptyContent, NewTeamModal, SitesInfoWrapper, TeamsCard, Wrapper } from './styles'
import { useAppSelector } from '../../../../../hooks/hooks';
import Message from '../../../../components/message/Message';
import AxiosCall from '../../../../../utils/axios';
import { useEffect, useRef, useState } from 'react';
import { setProfile } from '../../../../../slices/profileSlice';
import Loader from '../../../../components/Loader/Loader';
import { HiUserGroup } from 'react-icons/hi';
import PopupModal from '../../../../components/popupModal/PopupModal';
import { IoMdClose } from 'react-icons/io';
import { MdAddCircleOutline } from 'react-icons/md';
import TeamInfo from './team_info/TeamInfo';
import Button from '../../../../components/button/Button';
import { fetchTeams } from '../../../../../actions/indexActions';
import { Project } from '../../../../../slices/projectSlice';

const TeamsTab: React.FC = () => {
    const teamSlice = useAppSelector(state => state.teams.state);
    const dispatch = useDispatch();
    const [selectedTeam, setSelectedTeam] = useState<any>(null)
    const [showNewTeamModal, setShowNewTeamModal] = useState(false)
    const [isCreatingTeam, setIsCreatingTeam] = useState(false)

    const teamNameRef = useRef<HTMLInputElement>(null)
    const teamEmailRef = useRef<HTMLInputElement>(null)

    const createTeam = async () => {
        try {
            setIsCreatingTeam(true)
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/team/add",
                data: {
                    name: teamNameRef.current?.value,
                    email: teamEmailRef.current?.value,
                }
            });
            setIsCreatingTeam(false)
            if (res.status == 1) {
                fetchTeams(dispatch)
                setShowNewTeamModal(false)
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsCreatingTeam(false)
            Message.error(err?.response.data.message)
        }
    }



    useEffect(() => {
        fetchTeams(dispatch)
    }, [])

    return (
        !selectedTeam ? 
        <Wrapper>
            <Container>
                <SitesInfoWrapper>
                    <div className="head">
                        <h5>All Teams</h5>

                        <button className="add-site" onClick={() => setShowNewTeamModal(true)}>
                            <MdAddCircleOutline />
                            <span>New Team</span>
                        </button>
                    </div>
                    <SitesWrapper>
                        <div className="site-list">
                            {teamSlice.isLoading ? <Loader styleTwo center topPadding="20px" bottomPadding="20px" /> : !teamSlice.teams!.length ? <EmptyContent>
                            <div className="text-content">
                                <HiUserGroup />
                                <span>You've not created any team</span>
                                <p>Team is a way to group and manage multiple projects under your account.</p>
                            </div>
                            <button onClick={() => setShowNewTeamModal(true)}>Create Team</button>
                        </EmptyContent> : teamSlice.teams!.map((item: any, idx) => {
                                return <TeamsCard status={"active"} key={"apps-"+idx} onClick={() => setSelectedTeam(item)}>
                                <div className="img-wrapper">
                                    <HiUserGroup />
                                </div>
                                <div className="col">
                                    <h4>{item?.name}</h4>
                                    <span>{JSON.parse(item?.members ?? "[]").length} members</span>
                                </div>
                                <div className="meta">
                                    <span>active</span>
                                </div>
                            </TeamsCard>
                            })}
                        </div>
                    </SitesWrapper>
                </SitesInfoWrapper>
                
                <PopupModal showPopup={showNewTeamModal} closeModal={() => setShowNewTeamModal(false)}>
                    <NewTeamModal>
                        <div className="head">
                            <h4>Create Team</h4>
                            <IoMdClose onClick={() => setShowNewTeamModal(false)} />
                        </div>
                        <input ref={teamNameRef} type="text" placeholder="Team Name" />
                        <input ref={teamEmailRef} type="text" placeholder="Team Contact Email" />
                        <Button isLoading={isCreatingTeam} onClick={createTeam}>Create Team</Button>
                    </NewTeamModal>
                </PopupModal>
            </Container>
        </Wrapper>

        : <TeamInfo goBack={() => setSelectedTeam(null)} selectedTeam={selectedTeam} />
    )
}

export default TeamsTab