import styled from 'styled-components'

export const Wrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
    border-top: 1px solid ${props => props.theme.lightBorderColor};
`;
export const Container = styled.div`
    max-width: 1280px;
    margin: 0px auto;
    padding: 60px 20px 20px 20px;
`;
export const TopSection = styled.div`
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 20px;

    @media screen and (min-width: 720px) {
        flex-direction: row;
        align-items: flex-start;
    }
`;
export const Logo = styled.div`
    width: 150px;
    margin-right: 20%;
    margin-bottom: 40px;
`;
export const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 200px;
    width: 100%;
    flex: 1;

    ul {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        h5 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;
            color: ${props => props.theme.darkTextColor};
        }

        li {
            display: block;
            margin-bottom: 10px;
            a {
                display: block;
                color: ${props => props.theme.textColor};
                font-size: 13px;
                font-weight: 400;
            }
        }

        @media screen and (min-width: 480px) {
            width: 50%;
        }

        @media screen and (min-width: 960px) {
            width: unset;
        }
    }

    @media screen and (min-width: 480px) {
        flex-direction: row;
    }
    @media screen and (min-width: 720px) {
        width: unset;
        justify-content: space-between;
    }
`;
export const BottomSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0px 20px 0px;

    .credits {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
        gap: 10px;

        img {
            height: 25px;
        }

        span {
            color: ${props => props.theme.textColor};
            font-size: 15px;
            font-weight: 400;
        }
        @media screen and (min-width: 720px) {
            margin-bottom: 0px;
        }
    }

    .social {
        display: flex;
        gap: 10px;
    }

    @media screen and (min-width: 480px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

export const SocialItem = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #898B8D;
    font-size: 19px;
    border: 1px solid #898B8D;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
`