import Button from '../../../../../components/button/Button'
import { InviteMemberModal, Container, MemberInfoModal, Form, InputSec, MainRow, Members, MembersList, SideBarContainer, SitesInfoWrapper, SitesWrapper, Wrapper } from './styles'
import { MdAddCircleOutline } from 'react-icons/md'
import { useEffect, useRef, useState } from 'react'
import PopupModal from '../../../../../components/popupModal/PopupModal'
import Message from '../../../../../components/message/Message'
import AxiosCall from '../../../../../../utils/axios'
import { AppSliceInterface } from '../../../../../../slices/appSlice'
import { useAppSelector } from '../../../../../../hooks/hooks'
import Dropdown from '../../../../../components/dropdown/Dropdown'
import { FaSave, FaUserAlt } from 'react-icons/fa'
import Loader from '../../../../../components/Loader/Loader'
import { IoMdClose } from 'react-icons/io'
import { RiUserAddFill } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import ComplexDropdown from '../../../../../components/complexDropdown/ComplexDropdown'
import { Project } from '../../../../../../slices/projectSlice'

const SettingsTab: React.FC = () => {
    const { projectId } = useParams()
    const projectsSlice = useAppSelector(state => state.projects.state)
    const [showInviteMemberModal, setShowInviteMemberModal] = useState(false)
    const [showUpdateMemberModal, setShowUpdateMemberModal] = useState(false)
    const [isSearchingMember, setIsSearchingMember] = useState(false)
    const [searchedMember, setSearchedMember] = useState<any>(null)
    const [searchList, setSearchList] = useState<any[]>([])
    const [isFetchingmembers, setIsfetchingMembers] = useState(true)
    const [searchEmail, setSearchEmail] = useState("")
    const [selectedProject, setSelectedProject] = useState<any>(null)
    const [memberRole, setMemberRole] = useState("")
    const [reason, setReason] = useState("")
    
    const [members, setMembers] = useState<any[]>([])

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isInviteingMember, setIsInviteingMember] = useState<boolean>(false)

    const projectNameRef = useRef<HTMLInputElement>(null)
    const descriptionRef = useRef<HTMLInputElement>(null)

    const permissionLevels = [
        "Administrator",
        "Member",
    ]

    const [isFetchingProject, setISFetchingProject] = useState(false)
    const [project, setProject] = useState<Project | null>(null)

    const fetchProject = async () => {
        try {
            if (!projectId) return
            setISFetchingProject(true)
            
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/project/fetch/"+ projectId,
            });

            setISFetchingProject(false)
            if (res.status == 1) {
                setProject(res.data)

                projectNameRef.current!.value = res.data.name
                descriptionRef.current!.value = res.data.description
                setReason(res.data.purpose)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setISFetchingProject(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching project")
        }
    }

    const updateProjectProfile = async () => {
        try {
            setIsLoading(true)
            
            const res: any = await AxiosCall({
                method: "PATCH",
                path: "/v1/project/update/"+ projectId,
                data: {
                    name: projectNameRef.current?.value,
                    description: descriptionRef.current?.value,
                    purpose: reason

                }
            });

            setIsLoading(false)
            if (res.status == 1) {
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while saving team information")
        }
    }

    const inviteMember = async () => {
        try {
            setIsInviteingMember(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/team/invite/send/"+ project!.team_id!,
                data: {
                    invite_email: searchedMember?.email,
                    project_id: projectId,
                    role: memberRole.toUpperCase()
                }
            });

            setIsInviteingMember(false)
            if (res.status == 1) {
                setShowInviteMemberModal(false)
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsInviteingMember(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while inviting user")
        }
    }

    const fetchMembers = async () => {
        try {
            if (!projectId) return
            setIsfetchingMembers(true)
            
            const res: any = await AxiosCall({
                method: "GET",
                path: "/v1/project/members/fetch/"+ projectId,
            });

            setIsfetchingMembers(false)
            if (res.status == 1) {
                setMembers(res.data)
                Message.success(res.message)
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsfetchingMembers(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching members")
        }
    }

    const resolveUser = async () => {
        try {
            if (!searchEmail) {
                setSearchList([]);
                return
            }
            setIsSearchingMember(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/users/search",
                data: {
                    email: searchEmail
                }
            });

            setIsSearchingMember(false)
            if (res.status == 1) {
                setSearchList([
                    ...res.data,
                    {
                        firsname: "",
                        lastname: "",
                        email: searchEmail
                    }
                ])
            } else {
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsSearchingMember(false)
            Message.error(err?.response?.data?.message ?? "An error occurred while fetching members")
        }
    }

    useEffect(() => {
        fetchMembers()
        fetchProject()
    }, [projectId])

    useEffect(() => {
        resolveUser()
    }, [searchEmail])

    return (
        <Wrapper>
            <MainRow>
                <Container>
                    <SitesInfoWrapper>
                        <div className="head">
                            <h5>Project Information</h5>

                            <button className="add-site" onClick={() => updateProjectProfile()}>
                                <FaSave />
                                <span>{isLoading ? <Loader /> : "Update"}</span>
                            </button>
                        </div>
                        <SitesWrapper>
                            <Form>
                                <InputSec>
                                    <label htmlFor="">Project name</label>
                                    <input ref={projectNameRef} type="text" placeholder="Enter name" />
                                </InputSec>

                                <InputSec>
                                    <label htmlFor="">Project Description</label>
                                    <input ref={descriptionRef} type="text" placeholder="Enter description" />
                                </InputSec>

                                <InputSec>
                                    <label htmlFor="">Purpose</label>
                                    <Dropdown placeHolder={reason ? reason : "Select purpose"} options={[
                                        "Just trying out coralscale",
                                        "Educational purposes",
                                        "Website or blog",
                                        "Web Application",
                                        "Ecommerce"
                                    ]} onChange={(item) => { setReason(item) }} />
                                </InputSec>
                            </Form>
                        </SitesWrapper>
                    </SitesInfoWrapper>

                    <SitesInfoWrapper>
                        <div className="head">
                            <h5>Members</h5>

                            <button className="add-site" onClick={() => setShowInviteMemberModal(true)}>
                                <MdAddCircleOutline />
                                <span>Invite</span>
                            </button>
                        </div>
                        <SitesWrapper>
                            <MembersList>
                                {isFetchingmembers ? <Loader styleTwo /> : members.map((item, idx) => {
                                    return <Members key={idx}>
                                        <div className="status-indicator">
                                            <FaUserAlt />
                                        </div>
                                        <div className="info">
                                            <h3>{item?.first_name} {item?.last_name}</h3>
                                            <span>{item?.role}</span>
                                        </div>
                                        <button onClick={() => {}}>update</button>
                                    </Members>
                                })}
                            </MembersList>
                        </SitesWrapper>
                    </SitesInfoWrapper>
                </Container>

                <SideBarContainer>
                    <div className="head">
                        <h5>Recent Activity</h5>
                    </div>
                    
                </SideBarContainer>
            </MainRow>

            <PopupModal showPopup={showInviteMemberModal} closeModal={() => setShowInviteMemberModal(false)}>
                <InviteMemberModal>
                    <div className="head">
                        {/* <div className="img-wrapper">
                            <RiUserAddFill />
                        </div> */}
                        <h4>Invite Member</h4>
                    </div>

                    {!searchedMember && <div className="search-member">
                        <input type="text" placeholder="Enter email" onChange={(e) => setSearchEmail(e.target.value)} />
                        {isSearchingMember && <Loader />}
                    </div>}

                    {searchedMember && <div className="selected-member">
                        <div className="info">
                            {(searchedMember?.firstname || searchedMember?.lastname) ? <>
                                <h3>{searchedMember?.firstname} {searchedMember?.lastname}</h3>
                                <span>{searchedMember?.email}</span></> :
                            <h3>{searchedMember?.email}</h3>}
                            
                        </div>

                        <IoMdClose onClick={() => setSearchedMember(null)} />
                    </div>}

                    {searchList.length ? <ul className="search-list">
                        {searchList.map((item, idx) => {
                            return <li key={idx} onClick={() => {setSearchList([]); setSearchedMember(item)}}>
                                <div className="info">
                                    <h3>{item.firstname} {item.lastname}</h3>
                                    <span>{item.email}</span>
                                </div>

                                <b>invite</b>
                            </li>
                        })}
                    </ul> : <></>}


                    {searchedMember && <Dropdown placeHolder="-- select role --" options={permissionLevels} onChange={(item) => setMemberRole(item)} />}

                    <Button isDisabled={searchedMember == null || !memberRole} onClick={() => {inviteMember()}} isLoading={isInviteingMember}>Invite</Button>
                </InviteMemberModal>
            </PopupModal>

            <PopupModal closeModal={() => setShowUpdateMemberModal(false)}  showPopup={showUpdateMemberModal}>
                <MemberInfoModal>
                    <h4>Member Infomation</h4>

                    <ul className="info">
                        <li>
                            <span>Name: </span>
                            <span>John Soleman</span>
                        </li>
                        <li>
                            <span>Email Address: </span>
                            <span>Suleman@gmail.com</span>
                        </li>
                        <Dropdown placeHolder="-- Select role --" options={permissionLevels} />
                    </ul>

                    <div className="button-row">
                        <button onClick={() => {setShowUpdateMemberModal(false)}}>Delete</button>
                        <Button onClick={() => inviteMember()} isLoading={isInviteingMember}>Update</Button>
                    </div>
                </MemberInfoModal>
            </PopupModal>
        </Wrapper>
    )
}

export default SettingsTab