import React, {useEffect, useRef, useState} from 'react'
import { Content, Head, HeadContent, MenuItems, SidebarContent, SidebarWrapper } from './styles'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { setNavigation } from '../../../slices/navigationSlice'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdDashboard, MdPayments, MdStore } from 'react-icons/md';
import { TbWorldWww } from 'react-icons/tb';
import { ProfileSliceInterface } from '../../../slices/profileSlice';
import { IoSettingsOutline } from 'react-icons/io5';
import { FaDatabase, FaGem, FaServer } from 'react-icons/fa';
import { RiArchiveDrawerFill } from 'react-icons/ri';
import { IoIosApps } from 'react-icons/io';


const Sidebar: React.FC = () => {
    const toggleState = useAppSelector(state => state.navigation.state);
    const teamSlice  = useAppSelector(state => state.teams.state);
    const dispatch = useAppDispatch();
    const [isActiveSubMenu, setIsActiveSubMenu] = useState(false)

    const subMenuRef = useRef(null)

    const openSubmenu = () => {

    }

    const navigation = useNavigate();
    const location = useLocation();

    const sidebarRef = useRef(null)

    const closeMenu = (e: { target: any }) => {
        if (e.target === sidebarRef.current) {
            dispatch(setNavigation({dashboard: false}));
        }
    }

    const logout = () => {
        dispatch(setNavigation({dashboard: false}));
        localStorage.removeItem("authToken");
        navigation("/signin")
    }

    const openSubMenu = () => {
        if (location.pathname.includes("/dashboard/transactions/giftcard") || location.pathname.includes("/dashboard/transactions/crypto")) {
            setIsActiveSubMenu(true)
        }
    }

    useEffect(() => {
        openSubMenu()
    }, [])

    return (
        <SidebarWrapper ref={sidebarRef} onClick={e => closeMenu(e)} navigationState={toggleState.dashboard}>
            <SidebarContent navigationState={toggleState.dashboard}>
                <Head>
                    <HeadContent onClick={() => dispatch(setNavigation({dashboard: false}))} to="/dashboard">
                        <img src="/assets/img/logo.png" />
                        <h4>CoralScale</h4>
                    </HeadContent>
                </Head>
                <Content>
                    <ul>
                        <MenuItems isActive={location.pathname === "/dashboard" ? true : false}>
                            <Link onClick={() => dispatch(setNavigation({dashboard: false}))} to="/dashboard">
                                <MdDashboard />
                                <span>Overview</span>
                             </Link>
                        </MenuItems>
                        <MenuItems isActive={location.pathname === "/dashboard/projects" ? true : false}>
                            <Link onClick={() => dispatch(setNavigation({dashboard: false}))} to="/dashboard/projects">
                                <RiArchiveDrawerFill />
                                <span>Projects</span>
                            </Link>
                        </MenuItems>
                        <MenuItems isActive={location.pathname === "/dashboard/pebbles" ? true : false}>
                            <Link onClick={() => dispatch(setNavigation({dashboard: false}))} to="/dashboard/pebbles">
                                <FaGem />
                                <span>Pebbles</span>
                            </Link>
                        </MenuItems>
                        <MenuItems isActive={location.pathname === "/dashboard/apps" ? true : false}>
                            <Link onClick={() => dispatch(setNavigation({dashboard: false}))} to="/dashboard/apps">
                                <IoIosApps />
                                <span>Apps</span>
                            </Link>
                        </MenuItems>
                        <MenuItems isActive={location.pathname === "/dashboard/domains" ? true : false}>
                            <Link onClick={() => dispatch(setNavigation({dashboard: false}))} to="/dashboard/domains">
                                <TbWorldWww />
                                <span>Domains</span>
                            </Link>
                        </MenuItems>
                        <MenuItems isActive={location.pathname === "/dashboard/volumes" ? true : false}>
                            <Link onClick={() => dispatch(setNavigation({dashboard: false}))} to="/dashboard/volumes">
                                <FaDatabase />
                                <span>Volumes</span>
                            </Link>
                        </MenuItems>
                        {teamSlice.defaultTeam?.role?.toLowerCase() == "owner" ? <>
                        <MenuItems isActive={location.pathname === "/dashboard/billing" ? true : false}>
                            <Link onClick={() => dispatch(setNavigation({dashboard: false}))} to="/dashboard/billing">
                                <MdPayments />
                                <span>Billing</span>
                            </Link>
                        </MenuItems>
                        <MenuItems isActive={location.pathname === "/dashboard/settings" ? true : false}>
                            <Link onClick={() => dispatch(setNavigation({dashboard: false}))} to="/dashboard/settings">   
                                <IoSettingsOutline />
                                <span>Settings</span>
                            </Link>
                        </MenuItems>
                        </> : <></>}
                    </ul>
                </Content>
            </SidebarContent>
        </SidebarWrapper>
    )
}

export default Sidebar
