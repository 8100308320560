import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`;
export const Container = styled.div`
    /* padding: 0px 6%;
    @media screen and (min-width: 880px) {
        padding: 0px 4%;
    } */
    width: 100%;
`;
export const MainRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (min-width: 1080px) {
        flex-direction: row;
    }
`;

export const SitesInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    margin: 20px 0px;

    
`;

export const SitesWrapper = styled.div`
    margin: 0px 0px;
    /* padding: 20px 20px; */
    flex: 1;
    border-radius: 4px;
`;

export const TabWrapper = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
`;
export const TabItem = styled.span<{isActive?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0px 15px;
    border: 0.5px solid ${props => props.isActive ? props.theme.primaryColor : "#222" };
    /* border-radius: 4px; */
    font-size: 12px;
    font-weight: 400;
    background-color: ${props => props.isActive ? props.theme.primaryColor : "transparent"};
    color: ${props => props.isActive ? "#fff" : "#222"};
    cursor: pointer;
`;

export const AvailableApps = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;

    @media screen and (min-width: 480px) {
        flex-direction: row;
    }
`;

export const InstalledApps = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;

    @media screen and (min-width: 480px) {
        flex-direction: row;
    }
`;

export const EmptyContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px 20px;
    gap: 40px;
    width: 100%;
    background-color: ${props => props.theme.containerBackground};
    border-radius: 8px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        background-color: ${props => props.theme.primaryButtonColor};
        color: ${props => props.theme.primaryButtonTextColor};
        border: none;
        border-radius: 4px;
        height: 45px;
        width: 150px;
        text-transform: capitalize;
        cursor: pointer;
    }

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 8px;

        svg {
            width: 40px;
            height: 40px;
            color: ${props => props.theme.darkTextColor};
        }

        span {
            color: ${props => props.theme.darkTextColor};
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }

        p {
            color: ${props => props.theme.darkTextColor};
            font-size: 16px;
            font-weight: 400;
            text-align: center;
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;


export const MarketApp = styled.div<{status: string}>`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    border: 0.5px solid #ccc;
    padding: 20px 20px;

    @media screen and (min-width: 480px) {
        width: calc(50% - 10px);
        max-width: 320px;
    }

    .head {
        display: flex;
        gap: 20px;

        .img-wrapper {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;

            img {
                object-fit: cover;
            }
        }

        .title {
            display: flex;
            flex-direction: column;
            gap: 2px;

            h3 {
                font-size: 15px;
                font-weight: 600;
                color: ${props => props.theme.darkTextColor};
                text-transform: capitalize;
            }

            span {
                font-size: 13px;
                font-weight: 400;
                color: ${props => props.theme.lightTextColor};
            }
        }
    }

    p {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: rgb(118, 118, 118);
        margin-top: 10px;
    }

    .meta {
        margin-top: auto;
        padding-top: 20px;
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            height: 38px;
            margin-left: auto;
            border-radius: 4px;
            padding: 0px 12px;
            border: none;
            color: ${props => props.theme.primaryButtonTextColor};
            font-size: 13px;
            font-weight: 400;
            background-color: ${props => props.theme.primaryButtonColor};
            cursor: pointer;

            svg {
                font-size: 14px;
            }

            :disabled {
                background-color: rgb(255, 246, 196);
                color: rgb(171, 162, 16);
                border: 1px solid rgb(248, 235, 20);
                cursor: default;

                svg {
                    display: none;
                }
            }
        }
    }
`;

export const AddAppModal = styled.div`
    .head {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;

        .img-wrapper {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            /* padding: 3px; */
            /* border: 1px solid #ccc; */

            img {
                object-fit: cover;
            }
        }

        .title {
            display: flex;
            flex-direction: column;
            gap: 2px;

            h3 {
                font-size: 15px;
                font-weight: 600;
                color: ${props => props.theme.darkTextColor};
            }

            span {
                font-size: 13px;
                font-weight: 400;
                color: ${props => props.theme.lightTextColor};
            }
        }
    }

    input {
        :disabled {
            background-color: ${props => props.theme.lightBorderColor};
            border: 1px solid ${props => props.theme.lightBorderColor};
            color: ${props => props.theme.darkTextColor};
        }
    }
`;