import { Button, Container, Form, FormMeta, InputSec, MainSection, PasswordInput, Wrapper, Sidebar } from './styles'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineGithub} from 'react-icons/ai'
import { useRef, useState } from 'react'
import Message from '../../../components/message/Message'
import AxiosCall from '../../../../utils/axios'
import Loader from '../../../components/Loader/Loader'
import useQuery from '../../../../hooks/useQuery'
import { BsCheckLg } from 'react-icons/bs'
import { setTeams } from '../../../../slices/teamSlice'
import { useAppDispatch } from '../../../../hooks/hooks'
import { setProfile } from '../../../../slices/profileSlice'


const SignupScreen: React.FC = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const firstName = useRef<HTMLInputElement>(null);
    const lastName = useRef<HTMLInputElement>(null);
    const email = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);

    let navigate = useNavigate();
    const query = useQuery();

    const CLIENT_ID = "22e850a6ac69e54e5b76";

    const githubSignUp = (e: any) => {
        e.preventDefault()

        window.location.assign("https://github.com/login/oauth/authorize?client_id=" + CLIENT_ID)
    }

    const signup = async (e: any) => {
        e.preventDefault();

        try {
            if (!acceptedTerms) {
                return Message.error("You need to accept our terms and conditions");
            }
            if (!password?.current?.value.length) {
                return Message.error("Passwords can't be empty");
            }

            setIsLoading(true)
            
            const res: any = await AxiosCall({
                method: "POST",
                path: "/v1/signup",
                data: {
                    email: email?.current?.value,
                    password: password?.current?.value,
                    firstname: firstName?.current?.value,
                    lastname: lastName?.current?.value,
                }
            });

            if (res.status == 1) {
                setIsLoading(false)
                Message.success("Account registered successfully")
                localStorage.setItem("authToken", res.data.token)

                localStorage.setItem("default_team", JSON.stringify({
                    id: res.data?.default_team?.id,
                    name: res.data?.default_team?.name,
                    role: res.data?.default_team?.role
                }))

                dispatch(setTeams({defaultTeam: {
                    id: res.data?.default_team?.id,
                    name: res.data?.default_team?.name,
                    role: res.data?.default_team?.role
                }}))

                dispatch(setProfile({
                    email: res.data.email,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    balance: res.data.balance,
                    fetchedProfile: true,
                }))

                let redirectPath = query.get('redirect')

                if (redirectPath) {
                    const decodedURl = decodeURIComponent(redirectPath)
                    return navigate(decodedURl);
                }

                return navigate("/dashboard");
            } else {
                setIsLoading(false)
                Message.error(res.message)
            }
        } catch (err: any) {
            setIsLoading(false)
            Message.error(err?.response?.data?.message ?? "An error occurred")
        }
    }

    return (
        <Wrapper>
            <Container>
                <Sidebar></Sidebar>
                <MainSection>
                    <Form>
                        {/* <h1>Create your account</h1> */}
                        <div className="github-auth" onClick={githubSignUp}>
                            <div className="github-box">
                                <AiOutlineGithub />
                            </div>
                            <span>Sign up with github</span>
                        </div>
                        <div className="email-auth">
                            <span>Or sign up with your email</span>
                        </div>
                        <InputSec>
                            <label htmlFor="first-name">First Name</label>
                            <input ref={firstName} id="first-name" type="text" placeholder="Enter your first name" />
                        </InputSec>
                        <InputSec>
                            <label htmlFor="last-name">Last Name</label>
                            <input ref={lastName}  id="last-name" type="text" placeholder="Enter your last name" />
                        </InputSec>
                        <InputSec>
                            <label htmlFor="user-email">Email</label>
                            <input ref={email} id="user-email" type="text" placeholder="example@email.com" />
                        </InputSec>
                        <PasswordInput>
                            <label htmlFor="user-pass">Password</label>
                            <div className="input-row">
                                <input ref={password} id="user-pass" type={showPassword ? "text" : "password"} placeholder="Enter password" />
                                {showPassword ? <AiOutlineEyeInvisible onClick={() => setShowPassword(!showPassword)} /> : <AiOutlineEye onClick={() => setShowPassword(!showPassword)} />}
                            </div>
                        </PasswordInput>

                        <FormMeta active={acceptedTerms}>
                            <div className="terms-check-box" onClick={() => setAcceptedTerms(!acceptedTerms)}>
                                <BsCheckLg />
                            </div>
                            <span onClick={() => setAcceptedTerms(!acceptedTerms)}>By signing up, you agree to our </span>
                            <a href="#">Terms of Use </a>
                            <span>and </span>
                            <a href="#">Privacy Policy.</a>
                        </FormMeta>
                        <Button onClick={signup}>{isLoading ? <Loader /> : "Sign up"}</Button>

                        <div className="has-acct-tow">
                            <span>Already have an account? </span>
                            <Link to="/signin">Log in</Link>
                        </div>
                    </Form>
                </MainSection>
            </Container>
        </Wrapper>
    )
}

export default SignupScreen