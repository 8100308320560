import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const SidebarWrapper = styled.div<{navigationState: boolean}>`
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 99999999;

    ::before {
        content: "";
        position: fixed;
        left: 0px;
        top: 0px;
        display: ${props => props.navigationState ? "block" : "none"};
        height: 100vh;
        width: 100%;
        background-color: ${props => props.navigationState ? "#0006" : "transparent"};
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        @media screen and (min-width: 800px) {
            background-color: transparent;
            width: 240px;
        }
    }
    @media screen and (min-width: 800px) {
        display: flex;
        left: 0px;
        width: 240px;
        z-index: 999999999;
    }
`;

export const SidebarContent = styled.div<{navigationState: boolean}>`
    display:  flex;
    flex-direction: column;
    position: fixed;
    left: ${props => props.navigationState ? "0px" : "-240px"};
    top: 0px;
    width: 240px;
    height: 100vh;
    transition: all 0.2s ease-in-out;
    background: ${props => props.theme.sidebarBackground};

    @media screen and (min-width: 880px) {
        display: flex;
        left: 0px;
        z-index: 999999999;
        transition: none;
    }
`;

export const Head = styled.div`
    padding: 20px 30px;

`;

export const HeadContent = styled(Link)`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 0px;
    /* height: 28px; */
    border-radius: 6px;

    img {
        width: 30px;
    }

    h4  {
        color: ${props => props.theme.sidebarHeadTextColor};
        font-size: 18px;
        font-family: 'Manrope', sans-serif;
    }
`;
export const Title = styled.h5`
   margin-bottom: 15px;
   font-size: 12px;
   color: #ccc;
   padding: 0px 10px;
   text-transform: uppercase;
`;
export const Home = styled.li<{isActive?: boolean}>`
    margin-bottom: 16px;
    a {
        display: flex;
        /* align-items: flex-end; */
        color: ${props => props.isActive ? props.theme.secondaryColor : "#fff" } !important;
        padding: 8px 10px;
        svg {
            margin-right: 10px;
            font-size: 18px;
        }
        span {
            font-size: 13px;
            font-weight: 400;
            color: ${props => props.isActive ? props.theme.secondaryColor : "#fafafa" };
        }
        background-color: ${props => props.isActive ? "#fff" : "transparent" };
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
`;
export const Content = styled.div`
    padding: 0px 0px 0px 0px;
    overflow-y: auto;

    /* ::-webkit-scrollbar {
        width: 4px;
        margin-right: 10px;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 10px;
        padding: 0px 20px;
    } */

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar, ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
        display: none;
    }

    ul {
        margin-bottom: 40px;
    }
`;


export const MenuItems = styled.li<{isActive?: boolean, isActiveSubMenu?: boolean}>`
    cursor: pointer;
    a {
        display: flex;
        align-items: center;
        color: ${props => props.theme.whiteTextColor};
        padding: 20px 30px;
        /* padding-bottom: ${props => props.isActiveSubMenu ? "10px" : "30px"}; */
        svg {
            /* width: 30px; */
            margin-right: 20px;
            font-size: 20px;
            color: ${props => props.theme.sidebarText};
        }
        span {
            font-size: 15px;
            font-weight: 300;
            color: ${props => props.theme.sidebarText};
        }
        background-color: ${props => props.isActive ? props.theme.sidebarActiveMenuBackground : "transparent" };
        border-left: 4px solid ${props => props.isActive ? "#fff" : "transparent"};
    }
`;


export const SubMenu = styled.ul<{isActive?: boolean}>`
    display: ${props => props.isActive ? "block" : "none"};
    padding: 0px 0px 0px 30px;
    cursor: pointer;
`;


export const SubMenuItems = styled.li<{isActive?: boolean}>`
    a {
        display: flex;
        align-items: center;
        color: ${props => props.isActive ? props.theme.primaryColor : "#fff" };
        padding: 20px 30px;
        img {
            width: 30px;
            margin-right: 20px;
            font-size: 20px;
            filter:  ${props => props.isActive ? "invert(0%) sepia(75%) saturate(918%) hue-rotate(357deg) brightness(103%) contrast(100%)" : "none" };

            :nth-of-type(2) {
                margin-right: 0px;
                margin-left: 20px;
            }
        }
        span {
            font-size: 15px;
            font-weight: 300;
            color: ${props => props.isActive ? props.theme.primaryColor : "#98A5B1" };
        }
        background-color: ${props => props.isActive ? "#151E2A" : "transparent" };
        border-left: 4px solid ${props => props.isActive ? props.theme.primaryColor : "transparent"};
    }
`;