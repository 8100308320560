import { BalanceContent, BalanceWrapper, BillingContent, BillingWrapper, Container, EmptyContent, MainRow, SitesWrapper, Wrapper, TableBody, TableHead, TableItems, TableWrapper, EmptyCardContent, AddDebitCardModal, VerifyingPayment } from './styles'
import Loader from '../../../components/Loader/Loader'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import PopupModal from '../../../components/popupModal/PopupModal'
import { IoMdMore, IoMdWallet } from 'react-icons/io'
import { FaFileInvoice, FaRegCreditCard } from 'react-icons/fa'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { updateWalletState } from '../../../../slices/walletSlice'
import useWalletPaymentModel from '../payments/useWalletPaymentModel'
import { FiPlus } from 'react-icons/fi'
import formatNumber from '../../../../utils/numberFormatter'
import WalletPaymentModal from './WalletPaymentModal'
import useQuery from '../../../../hooks/useQuery'

const BillingScreen: React.FC = () => {
    const debitCardState = useAppSelector(state => state.debitCard.state);
    const userProfile = useAppSelector(state => state.profile.state);
    const walletState = useAppSelector(state => state.wallet.state);
    const [selectedCardOption, setSelectedCardOption] = useState<any>()

    const walletPaymentModel = useWalletPaymentModel()
    const dispatch = useAppDispatch()
    const query = useQuery()

    useEffect(() => {
        dispatch(updateWalletState({...walletState, showPaystackModal: false, showStripeModal: false}))
        walletPaymentModel.fetchTransactions()
        walletPaymentModel.fetchCards()
    }, [])

    const checkForStripeTransaction = () => {
       const payment_id = query.get('stripe_pay_id')
       if (payment_id) {
            walletPaymentModel.verifyTransaction({paymentMethod: "stripe", paymentReference: payment_id})
            window.history.replaceState( {} , 'coralscale', '/dashboard/billing' );
       }
    }

    useEffect(() => {
        checkForStripeTransaction()
    }, [])

    return (
        <Wrapper>
            <Container>
                <BalanceWrapper>
                    <BalanceContent>
                        <div className="head">
                            <span>Wallet Balance</span>
                        </div>
                        <div className="content">
                            <span className="text">${formatNumber(parseFloat(userProfile?.balance ?? "0")).toPrice()}</span>
                            <button onClick={() => dispatch(updateWalletState({...walletState, showPaystackModal: false, showPaymentMethodModal: true}))}>
                                <span>Top Up</span>
                                <IoMdWallet />
                            </button>
                        </div>
                    </BalanceContent>

                    <BalanceContent>
                        <div className="head">
                            <span>Accrued Charges</span>
                        </div>

                        <div className="content">
                            <span>Unpaid invoice</span>
                            <span>$0</span>
                        </div>
                    </BalanceContent>
                </BalanceWrapper>

                <BillingWrapper>
                    <BillingContent>
                        <div className="head">
                            <span>Billing Contact</span>
                            <button>Edit</button>
                        </div>
                        <div className="billing-content">
                            <ul>
                                <li>{userProfile.firstName} {userProfile.lastName}</li>
                                <li>{userProfile.email}</li>
                            </ul>
                            <ul>
                                <li>_ _ _ _ _</li>
                                <li>_ _ _ _ _</li>
                                <li>_ _ _ _ _</li>
                            </ul>
                        </div>
                    </BillingContent>

                    <BillingContent>
                        <div className="head">
                            <span>Payment Methods</span>
                            <button onClick={() => walletPaymentModel.setShowAddCardModal(true)}>Add Card</button>
                        </div>

                        <div className="payment-content">
                            {debitCardState.isLoading ? <Loader topPadding="100px" bottomPadding="100px" center styleTwo />  : <ul>
                                {!debitCardState.cards.length ? <EmptyCardContent>
                                <div className="text-content">
                                    <FaRegCreditCard />
                                    <span>You have no saved cards yet</span>
                                    <p>Debit cards you save would appear here</p>
                                    <button onClick={() => walletPaymentModel.setShowAddCardModal(true)}>
                                        <FiPlus />
                                        <span>add card</span>
                                    </button>
                                </div>
                            </EmptyCardContent> : debitCardState.cards.map((item, idx) => {
                                    return <li key={idx}>
                                        <div className="main">
                                            <div className="icon-box">
                                                <FaRegCreditCard />
                                            </div>
                                            <h4>{item.type} ****{item.card_number}</h4>
                                            <span>Expires {item.card_exp}</span>
                                            {item.is_default == "true" && <b>default</b>}
                                        </div>
                                        <div className="actions">
                                            <button onClick={() => {
                                                if (selectedCardOption == item) {
                                                    setSelectedCardOption("")
                                                } else {
                                                    setSelectedCardOption(item)
                                                }
                                            }}><IoMdMore /></button>

                                            {selectedCardOption == item && <div className="actions-container">
                                                <span>Make Payment</span>
                                                <span>Set Default</span>
                                                <span>Delete</span>
                                            </div>}
                                        </div>
                                    </li>
                                })}
                            </ul>}
                        </div>
                    </BillingContent>
                </BillingWrapper>
                <MainRow>
                    <div className="head">
                        <h5>Billing History</h5>
                    </div>
                    <SitesWrapper>
                        {walletPaymentModel.isFetchingTransactions ? <Loader topPadding="100px" bottomPadding="100px" styleTwo center /> : !walletPaymentModel.transactions.length ? <EmptyContent>
                                <div className="text-content">
                                    <FaFileInvoice />
                                    <span>You have no invoice yet</span>
                                    <p>Invoice and payment history would show here.</p>
                                </div>
                            </EmptyContent> : <TableWrapper>
                            <TableHead>
                                <li>Reference</li>
                                <li>Gateway</li>
                                <li>Description</li>
                                <li>Date and Time</li>
                                <li>Amount</li>
                                <li>Status</li>
                            </TableHead>
                            <TableBody isEmpty={false}>
                                {walletPaymentModel.transactions.map((item: any, index: number) => {
                                    return <TableItems isActive={false} key={"transactions-" + index} status={item.status} type={item?.type}>
                                        <li>{item?.reference}</li>
                                        <li>{item?.gateway}</li>
                                        <li>{item?.details}</li>
                                        <li>{dayjs(item?.date_created).format("MMM DD,  YYYY hh:mm a")}</li>
                                        <li>{item.type == "credit" ? "+" : "-"}${formatNumber(item.amount).toPrice()}</li>
                                        <li><span>{item?.status}</span></li>
                                    </TableItems>
                                })}
                            </TableBody>
                        </TableWrapper>}
                    </SitesWrapper>
                </MainRow>
            </Container>

            {walletState.showPaymentMethodModal && <WalletPaymentModal />}

            <PopupModal showPopup={walletPaymentModel.showAddCardModal} closeModal={() => walletPaymentModel.setShowAddCardModal(false) }>
                <AddDebitCardModal>
                    <h4>Add Debit Card</h4>
                    <p>You would be charged $0.09 for this transaction do you wish to procced?</p>
                    <button onClick={() => {walletPaymentModel.initAddCardPayment()}}>{walletPaymentModel.isAddingCard ? <Loader /> : "Continue"}</button>
                </AddDebitCardModal>
            </PopupModal>

            <PopupModal showPopup={walletState.isVerifyingTransaction}>
                <VerifyingPayment>
                    <h4>Verifying transaction</h4>
                    <Loader  styleTwo center />
                </VerifyingPayment>
            </PopupModal>
        </Wrapper>
    )
}

export default BillingScreen