import { setProjects } from "../slices/projectSlice";
import { setTeams, Team } from "../slices/teamSlice";
import AxiosCall from "../utils/axios";
import Message from "../views/components/message/Message";

export const fetchProjects = async (dispatch: any) => {
    try {
        const saved_team_settings = localStorage.getItem("default_team")

        const default_team = {
            id: "",
            name: ""
        }
        if (saved_team_settings) {
            const _team = JSON.parse(saved_team_settings)
            default_team.id = _team?.id ?? ""
            default_team.name = _team?.name ?? ""
        }
        const res: any = await AxiosCall({
            method: "GET",
            path: "/v1/team/projects/fetch/" + default_team.id
        });

        dispatch(setProjects({
            isLoading: false,
        }))

        if (res.status == 1) {
            setProjects(res.data ?? [])
            dispatch(setProjects({
                projects: res.data,
                fetchedProject: true,
                isLoading: false,
            }))
        } else {
            Message.error(res.message)
        }
    } catch (err: any) {
        dispatch(setProjects({
            isLoading: false,
        }))
        Message.error(err?.response?.data?.message ?? "An error occurred while fetching projects")
    }
}

export const fetchTeams = async (dispatch: any) => {
    try {

        dispatch(setTeams({
            isLoading: true,
        }))
        const res: any = await AxiosCall({
            method: "GET",
            path: "/v1/teams/fetch"
        });

        dispatch(setTeams({
            isLoading: false,
        }))

        if (res.status == 1) {
            setProjects(res.data ?? [])
            dispatch(setTeams({
                teams: res.data,
                fetchedTeams: true,
                isLoading: false,
            }))
        } else {
            Message.error(res.message)
        }
    } catch (err: any) {
        dispatch(setTeams({
            isLoading: false,
        }))
        Message.error(err?.response?.data?.message ?? "An error occurred while fetching teams")
    }
}


export const updateDefaultTeam = async (team: Team) => {
    try {
        const res: any = await AxiosCall({
            method: "POST",
            path: "/v1/team/default/update",
            data: {
                team_id: team.id
            }
        });

        if (res.status == 1) {
            localStorage.setItem("default_team", JSON.stringify(team))
        } else {
            Message.error(res.message)
        }
    } catch (err: any) {

        Message.error(err?.response?.data?.message ?? "An error occurred while switching teams")
    }
}