import { PaymentMethodModal } from './styles'
import { useEffect, useState } from 'react'
import PopupModal from '../../../components/popupModal/PopupModal'
import { FaWallet } from 'react-icons/fa'
import usePaymentModel from '../payments/usePaymentModel'
import Loader from '../../../components/Loader/Loader'
import PaystackModal from '../payments/paystack_modal/PaystackModal'
import StripeModal from '../payments/stripe_modal/StripeModal'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { updateCreatAppState } from '../../../../slices/createAppSlice'

const PaymentMethod: React.FC = () => {
    const creatApp = useAppSelector(state => state.createApp.state);
    const paymentModel = usePaymentModel()
    const [serverCost, setServerCost] = useState(0)
    const dispatch = useAppDispatch()

    const handlePayment = async () => {
        if (creatApp.selectedPaymentMethod == "wallet") {
            dispatch(updateCreatAppState({...creatApp, showPaymentMethodModal: false, addSiteModal: true}))
            return
        }
        await paymentModel.getPaymentReference({paymentMethod: creatApp.selectedPaymentMethod, planId: creatApp.selectedPlanId});
    }

    useEffect(() => {
        if (paymentModel.initData != null) {
            if (creatApp.selectedPaymentMethod == "paystack") {
                setServerCost(paymentModel.initData.amount)
                dispatch(updateCreatAppState({...creatApp, showPaystackModal: true, paymentReference: paymentModel?.initData?.reference}))
            } else if (creatApp.selectedPaymentMethod == "stripe") {
                dispatch(updateCreatAppState({...creatApp, showStripeModal: true, stripeClientSecret: paymentModel?.initData?.client_secret, paymentReference: paymentModel?.initData?.payment_id}))
            }
        }
    }, [paymentModel.initData])


    return (
        <>
        <PopupModal showPopup={creatApp.showPaymentMethodModal} closeModal={() => {dispatch(updateCreatAppState({...creatApp, showPaymentMethodModal: false}));}}>
            <PaymentMethodModal>
                <h4>Payment method</h4>
                <ul>
                    <li className={creatApp.selectedPaymentMethod == "paystack" ? "selected" : ""} onClick={() => { dispatch(updateCreatAppState({...creatApp, selectedPaymentMethod: "paystack"})); }}>
                        <div className="img-box">
                            <img src="/assets/img/paystack-icon.jpg" alt="" />
                        </div>
                        <span>Paystack</span>
                        <div className="select-box"></div>
                    </li>
                    <li className={creatApp.selectedPaymentMethod == "wallet" ? "selected" : ""} onClick={() => {dispatch(updateCreatAppState({...creatApp, selectedPaymentMethod: "wallet"})); }}>
                        <div className="icon-box">
                            <FaWallet />
                        </div>
                        <span>Wallet</span>
                        <div className="select-box"></div>
                    </li>
                    <li className={creatApp.selectedPaymentMethod == "stripe" ? "selected" : "stripe"}>
                        <div className="img-box">
                            <img src="/assets/img/stripe-icon.png" alt="" />
                        </div>
                        <span>Stripe</span>
                        <div className="select-box"></div>
                    </li>
                </ul>

                {creatApp.selectedPaymentMethod != "none" && <button onClick={handlePayment}>{paymentModel.isInitTransaction ? <Loader /> : "Continue"}</button>}
                
            </PaymentMethodModal>
            {creatApp.showPaystackModal && <PaystackModal transType="pebble_payment" amount={serverCost} reference={creatApp.paymentReference} />}
        </PopupModal>
            {creatApp.showStripeModal && <StripeModal transType="pebble_payment" clientSecret={creatApp.stripeClientSecret} />}
        </>
    )
}

export default PaymentMethod