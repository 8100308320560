import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
`;
export const Container = styled.div`
    width: 100%;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};
        padding: 10px 20px;

        h5 {
            color: ${props => props.theme.textColor};
            font-size: 14px;
            font-weight: 600;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.darkTextColor};
            border: none;
            width: 110px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.whiteTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.whiteTextColor};
                font-size: 15px;
            }
        }
    }
`;
export const StatsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    flex-direction: column;

    @media screen and (min-width: 880px) {
        flex-direction: row;
        /* gap: 4%; */
    }
`;

export const MainRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (min-width: 1080px) {
        flex-direction: row;
    }
`;

export const SitesWrapper = styled.div`
    flex: 1;
    padding: 0px 20px;
    border-radius: 4px;

    @media screen and (min-width: 880px) {
        
    }
`;

export const SideBar = styled.div`
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    margin: 20px 0px;
    padding: 20px 20px;
    border-radius: 4px;
    min-height: 400px;
    width: 100%;
    
    @media screen and (min-width: 1080px) {
        width: 30%;
        min-width: 320px;
    }
`;

export const SiteItem = styled(Link)`
    display: flex;
    align-items: center;
    padding: 15px 0px;
    gap: 20px;
    .img-wrapper {
        width: 70px;
        height: 60px;
        padding: 10px;
        /* background-color: #eee; */

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .col {
        flex: 1;
        h4 {
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 4px;
            color: ${props => props.theme.darkTextColor};
            text-transform: capitalize;
        }
        span {
            columns: ${props => props.theme.textColor};
            font-size: 12px;
            font-weight: 400;
            color: ${props => props.theme.darkTextColor};
        }
    }

    .meta {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap:  10px;
        span {
            display: flex;
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 5px;
            background-color: rgb(194, 253, 194);
            color: #090;
            /* border: 0.5px solid #090; */

            :last-child {
                font-size: 10px;
                background-color: transparent;
                color: ${props => props.theme.darkTextColor};
            }
        }
    }
`;

export const AddSiteModal = styled.div`
    
`;

export const DeploySiteModal = styled.div`
    
`;

export const DeploymentModal = styled.div`
    h5 {
        text-align: center;
    }

    .repo-list {
        margin-top: 20px;
        border: 1px solid #ccc;
        border-radius: 6px;
        height: 100%;
        max-height: 480px;
        overflow-y: scroll;
        
        li {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #ccc;
            color: ${props => props.theme.lightTextColor};
            font-size: 13px;
            font-weight: 600;
            cursor: pointer;

            :hover {
                background-color: #ccc;
            }

            img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 10px;
            }


            svg {
                :first-of-type {
                    font-size: 20px;
                    margin-right: 10px;
                }
                :last-of-type {
                    font-size: 15px;
                }
            }


            .icon-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 10px;
                background-color: ${props => props.theme.primaryButtonColor};


                svg {
                    font-size: 20px;
                    margin-right: 0px;
                    color: ${props => props.theme.primaryButtonTextColor};
                }
            }

            span {
                flex: 1;
            }

            :last-of-type {
                border-bottom: none;
            }
        }
    }

    .branch-list {
        margin-top: 20px;
        border: 1px solid #ccc;
        border-radius: 6px;
        height: 100%;
        max-height: 480px;
        overflow-y: scroll;
        
        li {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #ccc;
            color: ${props => props.theme.lightTextColor};
            font-size: 13px;
            font-weight: 600;
            cursor: pointer;

            :hover {
                background-color: #ccc;
            }

            svg {
                :first-of-type {
                    font-size: 20px;
                    margin-right: 10px;
                }
                :last-of-type {
                    font-size: 15px;
                }
            }

            span {
                flex: 1;
            }

            :last-of-type {
                border-bottom: none;
            }
        }

        .selcted-repo {
            span {
                color: ${props => props.theme.darkTextColor};
            }
            :hover {
                background-color: transparent;
                cursor: default;
            }
        }

        .selected-branch {
            background-color: #ccc;
        }
    }

    .deploy-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${props => props.theme.primaryButtonTextColor};
        margin-left: auto;
        margin-top: 20px;
        width: 100px;
        height: 45px;
        background-color: ${props => props.theme.primaryButtonColor};
        border-radius: 4px;
        cursor: pointer;
    }
`;


export const EmptyContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
    gap: 40px;
    /* background-color: #ceceec66; */
    border-radius: 8px;

    button {
        background-color: ${props => props.theme.primaryButtonColor};
        color: ${props => props.theme.primaryButtonTextColor};
        border: none;
        border-radius: 4px;
        height: 45px;
        width: 150px;
        text-transform: capitalize;
        cursor: pointer;
    }

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 8px;

        svg {
            width: 30px;
            height: 30px;
            color: ${props => props.theme.darkTextColor};
        }

        span {
            color: ${props => props.theme.darkTextColor};
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }

        p {
            color: ${props => props.theme.darkTextColor};
            font-size: 16px;
            font-weight: 400;
            text-align: center;
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;