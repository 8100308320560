import styled from 'styled-components'

export const Wrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
`;
export const Sidebar = styled.div`
    background-color: ${props => props.theme.authSideBackground};
    width: 40%;
    width: 100%;
    height: 100vh;
    position: relative;

    ::before {
        content: "";
        display: block;
        background: url("/assets/img/coral-one.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 400px;
        height: 280px;
        position: absolute;
        bottom: 0px;
    }
    ::after {
        content: "";
        display: block;
        background: url("/assets/img/coral-two.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 150px;
        height: 280px;
        position: absolute;
        top: 0px;
    }

    @media screen and (min-width: 880px) {  
        max-width: 640px;
    }
`;

export const Container = styled.div`
    display: flex;
`;
export const MainSection = styled.main`
    display: block;
    padding: 20px;
    background-color: #0002;
    flex: 1;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    position: absolute;

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar, ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
        display: none;
    }

    h1 {
        font-size: 30px;
        font-weight: 500;
        color: ${props => props.theme.textPrimaryColor};
        margin: 30px 0px 30px 0px;
        /* text-align: center; */
    }
    .has-acct-tow {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0px 25px 0px;
    
        span {
            font-size: 13px;
            margin-right: 4px;
            font-weight: 400;
            color: ${props => props.theme.lightTextColor};
        }
         a {
            font-size: 13px;
            color: ${props => props.theme.lightTextColor};
            font-weight: 600;
            text-decoration: underline;
         }
    }

    @media screen and (min-width: 880px) {
        background-color: ${props => props.theme.containerBackground};
        position: unset;
    }
`;

export const Form = styled.form`
    width: 100%;
    max-width: 480px;
    padding: 20px 20px;
    border-radius: 8px;
    background-color: ${props => props.theme.containerBackground};
    margin: 20vh auto 0px auto;

    .github-auth {
        display: flex;
        align-items: center;
        padding: 4px;
        border-radius: 4px;
        border: 1px solid ${props => props.theme.lightBorderColor};
        margin-bottom: 30px;
        width: 100%;
        cursor: pointer;
        margin-top: 20px;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 4px;
            background-color: ${props => props.theme.darkTextColor};
            color: ${props => props.theme.whiteTextColor};
            margin-right: 10px;
            font-size: 20px;
        }
        span {
            font-size: 13px;
            font-weight: 400;
            color: ${props => props.theme.textColor};
        }
    }

    .email-auth {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        color: ${props => props.theme.lightTextColor};
        font-size: 13px;

        ::before {
            content: "";
            display: block;
            width: 30px;
            height: 1.2px;
            background-color: ${props => props.theme.lightTextColor};
            margin-right: 10px;
        }
        ::after {
            content: "";
            display: block;
            width: 30px;
            height: 1.2px;
            background-color: ${props => props.theme.lightTextColor};
            margin-left: 10px;
        }
    }
`;

export const InputSec = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    label {
        margin-bottom: 6px;
        font-size: 13px;
        font-weight: 600;
        color: ${props => props.theme.textColor};
    }

    input {
        width: 100%;
        height: 44px;
        padding: 0px 15px;
        font-size: 13px;
        color: ${props => props.theme.textColor};
        border: 0.5px solid #898B8D;
        border-radius: 4px;
        background-color: ${props => props.theme.containerBackground};
        ::placeholder {
            color: ${props => props.theme.textColor};
        }
    }
`;
export const PasswordInput = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    label {
        margin-bottom: 6px;
        font-size: 13px;
        font-weight: 600;
        color: ${props => props.theme.textColor};
    }
    .input-row {
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        padding: 0px 15px;
        border: 0.5px solid #898B8D;
        border-radius: 4px;
        background-color: ${props => props.theme.containerBackground};

        input {
            flex: 1;
            font-size: 13px;
            color: ${props => props.theme.textColor};
            border: none;
            border-radius: 0px;
            background-color: ${props => props.theme.containerBackground};
            ::placeholder {
                color: ${props => props.theme.textColor};
            }
        }

        svg {
            fill: #898B8D;
            font-size: 18px;
            cursor: pointer;
        }
    }
`;

export const Button = styled.button`
    width: 100%;
    height: 45px;
    border-radius: 4px;
    color: ${props => props.theme.primaryButtonTextColor};
    font-size: 13px;
    font-weight: 600;
    background-color: ${props => props.theme.primaryButtonColor};
    margin-top: 10px;
    border: none;
    cursor: pointer;
`;

export const FormMeta = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0px 0px 15px 0px;
    a {
        font-size: 13px;
        color: ${props => props.theme.lightTextColor};
        text-decoration: underline;
    }
`;