import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    /* max-width: 880px; */
    margin: 0px auto;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
`;

export const HeadRow = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        color: ${props => props.theme.darkTextColor};
        padding: 14px 20px;
        position: relative;
        font-size: 14px;
        font-weight: 400;

        svg {
            font-size: 18px;
            color: ${props => props.theme.lightTextColor};
        }
    }

    :first-child {
        a {
            svg {
                font-size: 15px;
            }
        }
    }

    :nth-child(2) {
        a {
            svg {
                font-size: 15px;
            }
        }
    }

    a.active {
        color: ${props => props.theme.textPrimaryColor};
        border-bottom: 2px solid ${props => props.theme.primaryColor};

        svg {
            color: ${props => props.theme.textPrimaryColor};
        }
    }

    ::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #ccc;
        bottom: 0px;
    }

    @media screen and (min-width: 1080px) {

    }
`;
export const MainRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media screen and (min-width: 1080px) {
        flex-direction: row;
    }
`;


export const SitesInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    flex: 1;
    border-radius: 4px;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid ${props => props.theme.lightBorderColor};

        h5 {
            color: ${props => props.theme.textColor};
            font-size: 14px;
            font-weight: 600;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background-color: ${props => props.theme.darkTextColor};
            border: none;
            width: 120px;
            height: 35px;
            border-radius: 4px;
            cursor: pointer;

            span {
                color: ${props => props.theme.whiteTextColor};
                font-size: 12px;
                font-weight: 600;
                text-transform: capitalize;
            }

            svg {
                color: ${props => props.theme.whiteTextColor};
                font-size: 15px;
            }
        }
    }
`;


export const SitesWrapper = styled.div`
    margin: 0px 0px;
    padding: 10px 20px;
    flex: 1;
`;


export const TeamsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.containerBackground};
    border: 1px solid ${props => props.theme.lightBorderColor};
    padding: 30px 30px;
    flex: 1;
    border-radius: 4px;

    h2 {
        color: rgb(145, 145, 145);
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25px;
    }

    .col {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        width: 100%;

        @media screen and (min-width: 720px) {
            flex-direction: row;
        }

        label {
            font-size: 13px;
            font-weight: 600;
            width: 100%;
            max-width: 140px;
            color: ${props => props.theme.textColor};

            @media screen and (min-width: 880px) {
                max-width: 240px;
            }
        }

    }


    button {
        width: 140px;
        border-radius: 4px;
        color: ${props => props.theme.textColor};
        font-size: 13px;
        font-weight: 500;
        border: 1px solid rgba(0, 0, 0, 0.05);
        height: 38px;
        background-color: rgb(250, 250, 250);
        cursor: pointer;
    }

    @media screen and (min-width: 880px) {
        
    }
`;


export const TableWrapper = styled.div`
    background-color: ${props => props.theme.containerBackground};
    background-color: ${props => props.theme.containerBackground};
    border-radius: 4px;
    overflow: hidden;
    border: solid 1px rgba(0,0,0,.05);
    box-shadow: 0 0 1px rgba(0,0,0,.05);
`;

export const TableHead = styled.ul`
    display: flex;
    justify-content: space-around;
    padding: 20px;
    border-bottom: 1px solid ${props => props.theme.lightBorderColor};
    color: ${props => props.theme.darkTextColor};
    font-size: 14px;
    font-weight: 600;
    li {
        flex: 1;
    }

    @media screen and (min-width: 880px) {
        display: flex;
        justify-content: space-around;
    }
`;

export const TableBody = styled.div<{isEmpty?: boolean}>`
    display: ${props => props.isEmpty ? "flex" : "block"};
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

export const TableItems = styled.div<{status: String, type: String, isActive?: boolean}>`
    display: flex;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 20px 20px 13px 20px;
    border-top: 1px solid #eee;
    background-color: ${props => props.isActive ? props.theme.primaryColor : "transparent"};
    cursor: pointer;
    :first-child {
        border: none;
    }
    :hover {
        background: #161A1C;

        li {
            color: #fff !important;
        }
    }
    :nth-child(2n) {
        /* background-color: #fafafa; */
    }
    @media screen and (min-width: 880px) {
        border: none;
    }
    li {
        font-size: 12px;
        padding: 10px 0px;
        font-weight: 400;
        width: 50%;
        color: ${props => props.theme.darkTextColor};
        flex: 1;
        font-size: 14px;
        flex-wrap: nowrap;
        width: unset;


        /* :first-child {
            max-width: 100px;
        } */


        :nth-child(4) {
            display: flex;
            color: #282828;
        }

        :nth-child(3) {
            display: flex;
            align-items: center;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                gap: 4px;
                height: 30px;
                font-size: 13px;
                border-radius: 3px;
                border-radius: 4px;
                background: ${props => props.status == "success" ? "rgba(8, 245, 0, 0.14)" : props.status == "pending" ? "rgba(255, 208, 118, 0.25)" : "#CE0D9829"};
                color: ${props => props.status == "success" ? "rgb(30, 208, 26)" : props.status == "pending" ? "#EDB525" : "#CE0D98"} !important;
                
                ::before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: ${props => props.status == "success" ? "#11ce0d": props.status == "pending" ? "#EDB525" : "#CE0D98"};
                }
            }
        }

    }
    @media screen and (min-width: 880px) {
        flex-wrap: nowrap;
        padding: 20px;
        li {
            margin-bottom: 0px;
            padding: 10px 0px;
        }
    }
`;

export const EmptyContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
    gap: 40px;
    /* background-color: #ceceec66; */
    border-radius: 8px;

    button {
        background-color: ${props => props.theme.primaryButtonColor};
        color: ${props => props.theme.primaryButtonTextColor};
        border: none;
        border-radius: 4px;
        height: 45px;
        width: 150px;
        text-transform: capitalize;
        cursor: pointer;
    }

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;

        svg {
            width: 30px;
            height: 30px;
            color: ${props => props.theme.darkTextColor};
            margin-bottom: 20px;
        }

        span {
            color: ${props => props.theme.darkTextColor};
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 4px;
        }

        p {
            color: ${props => props.theme.darkTextColor};
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;


export const EmptyCardContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
    gap: 30px;
    /* background-color: #ceceec66; */
    border-radius: 8px;

    .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* gap: 15px; */
        border-radius: 8px;

        svg {
            font-size: 30px;
            color: ${props => props.theme.darkTextColor};
            margin-bottom: 15px;
        }

        span {
            color: ${props => props.theme.darkTextColor};
            font-size: 15px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 8px;
        }

        p {
            color: ${props => props.theme.darkTextColor};
            font-size: 15px;
            font-weight: 400;
            text-align: center;
        }
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background-color: ${props => props.theme.primaryButtonColor};
        border: none;
        color: ${props => props.theme.primaryButtonTextColor};
        border-radius: 4px;
        height: 38px;
        width: 100px;
        text-transform: capitalize;
        cursor: pointer;
        margin-top: 15px;

        svg {
            font-size: 20px !important;
            color: ${props => props.theme.textPrimaryColor};
            margin-bottom: 0px !important;
            color: #fff !important;
        }

        span {
            font-size: 12px !important;
            margin-bottom: 0px !important;
            color: #fff !important;
        }
    }

    @media screen and (min-width: 880px) {
        
    }
`;


export const NewTeamModal = styled.div`
    .head {
        display: flex;
        justify-content: center;
        position: relative;

        h4 {
            font-size: 16px;
            color: ${props => props.theme.darkTextColor};
        }

        svg {
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 16px;
            cursor: pointer;
        }
    }
`;





export const TeamsCard = styled.div<{status: string}>`
    display: flex;
    align-items: center;
    padding: 10px 0px;
    gap: 20px;
    cursor: pointer;

    .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        width: 40px;
        height: 40px;
        background-color: #e1e1e1;
        border: 1px solid #ccc;

        svg {
            width: 23px;
            height: 23px;
            color: ${props => props.theme.darkTextColor};
        }
    }

    .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        width: 40px;
        height: 40px;
        background-color: rgb(206, 206, 236);
        border: 1px solid #ccc;

        svg {
            width: 23px;
            height: 23px;
            color: rgb(34, 34, 34);
        }
    }

    .col {
        flex: 1;
        h4 {
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 4px;
            color: ${props => props.theme.darkTextColor};
            /* text-transform: capitalize; */
        }
        span {
            columns: ${props => props.theme.textColor};
            font-size: 12px;
            font-weight: 400;
            color: ${props => props.theme.lightTextColor};
        }
    }

    .meta {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap:  10px;
        align-self: flex-start;
        span {
            display: flex;
            padding: 5px 10px;
            font-size: 10px;
            border-radius: 26px;
            background-color: ${props => props.status == "active" ? "rgb(194, 253, 194)" : props.status == "failed" ? "rgb(245, 187, 187)" : props.status == "queued" ? props.theme.lightPrimaryColor : "rgb(255, 228, 182)"};
            color: ${props => props.status == "active" ? "#090" : props.status == "failed" ? "#900" : props.status == "queued" ? props.theme.lightPrimaryColor : "#ff6633"};
            border: 0.5px solid ${props => props.status == "active" ? "#090" : props.status == "failed" ? "#900" : props.status == "queued" ? props.theme.lightPrimaryColor : "#ff6633"};
            
        }
    }
`;
