import { setApps } from "../slices/appSlice";
import AxiosCall from "../utils/axios";
import Message from "../views/components/message/Message";


export const getApps = async (dispatch: Function) => {
    try {
        const saved_team_settings = localStorage.getItem("default_team")

        const default_team = {
            id: "",
            name: ""
        }
        if (saved_team_settings) {
            const _team = JSON.parse(saved_team_settings)
            default_team.id = _team?.id ?? ""
            default_team.name = _team?.name ?? ""
        }
        const res: any = await AxiosCall({
            method: "GET",
            path: "/v1/team/apps/fetch?team_id=" + default_team.id
        });

        if (res.status == 1) {
            dispatch(setApps({
                apps: res.data.appList,
                fetchedSites: true,
                isLoading: false,
            }))
        } else {
            dispatch(setApps({
                fetchedSites: true,
                isLoading: false,
            }))
            Message.error(res.message)
        }
    } catch (err: any) {
        dispatch(setApps({
            fetchedSites: true,
            isLoading: false,
        }))
        Message.error(err?.response?.data?.message ?? "An error occurred while fetching apps")
    }
}