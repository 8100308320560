import {createSlice} from '@reduxjs/toolkit';

const initialState: {state: DebitCardSliceInterface} = {
  state: {
    cards: [],
    isLoading: true,
    fetchedSites: false,
  }
};

export const setDebitCardSlice = createSlice({
  name: 'debit_cards',
  initialState,
  reducers: {
    setDebitCards: (state, action) => {
      state.state = {...state.state, ...action.payload};
    },
  },
});

export const { setDebitCards } = setDebitCardSlice.actions;

export interface DebitCardSliceInterface {
  cards: DebitCard[]
  fetchedSites: boolean,
  isLoading: boolean,
}

export interface DebitCard {
  id?:           number;
  user_id?:      number;
  auth_token?:   string;
  card_name?:    string;
  card_number?:  string;
  card_exp?:     string;
  type?:         string;
  retrys?:       number;
  is_default?:   string;
  gateway?:      string;
  status?:       string;
  meta?:         string;
  date_created?: Date;
  date_updated?: Date;
}


export default setDebitCardSlice.reducer;
